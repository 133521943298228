export const colors = {
  primary: '#344568',
  link: 'rgb(0, 122, 255)',
  secondary: '#77c5d9',
  success: '#00C781',
  warning: '#FFAA15',
  danger: 'red', //TODO - update this
  anchorblue: '#2089dc',
  error: '#FF4040',
  disabled: '#ccc',
  grey0: '#393e42',
  grey1: '#43484d',
  grey2: '#5e6977',
  grey3: '#86939e',
  grey4: '#e3e3e3',
  grey5: '#f2f2f2',
  light: '#ffffff',
  dark: '#000000',
  white: '#ffffff',
  black: '#000000',
};
