import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  forwardRef,
} from 'react';
import {TextInput, TextInputProps} from 'react-native';

function noop() {
  // useless
}

const NUMERIC_PATTERN = /[^\d.-]+/g;

interface TextInputNumericProps extends TextInputProps {
  format: Intl.NumberFormatOptions;
  TextInputComponent?: typeof TextInput;
  onChangeValue?: (value: number) => void;
}

const percentFormat = (text = '', formatter: Intl.NumberFormat) => {
  text = text.replace(/(-)(\.)/, '$10$2');
  const negativeMatches = text.match(/^-0{0,1}[.]{0,1}0*$/);
  if (negativeMatches) {
    return `${negativeMatches[0]}%`;
  }
  //Leading decimals, with 0000s strips
  const decimalMatches = text.match(/^0{0,1}([.]{1}0*)$/);
  if (decimalMatches) {
    return `0${decimalMatches[1]}%`;
  }
  const numericValue = +text / 100;
  const [suffix] = text.match(/\.$/) ?? [''];
  const formatted = formatter.format(numericValue);
  if (suffix) {
    return formatted.replace('%', `${suffix}%`);
  }
  return formatted;
};

//Make overrideable?
const styleModifiers = {
  percent: {
    out: value => {
      return value && +(value / 100).toPrecision(6);
    },
  },
};

export const TextInputPercent = forwardRef(
  (
    {
      value: originalValue,
      onChangeText = noop,
      onChangeValue = noop,
      ...props
    }: TextInputNumericProps,
    ref,
  ) => {
    const input = useRef<TextInput>(ref as any);
    const previousText = useRef('');
    const [isInFocus, setIsInFocus] = useState<boolean>(false);
    const [value, setValue] = useState('');
    const adjustSelection = useCallback((position: number) => {
      setTimeout(() => {
        input.current.setNativeProps({
          selection: {
            start: position,
            end: position,
          },
        });
      }, 0);
    }, []);

    const format = useCallback(
      value => {
        const formatter = Intl.NumberFormat('en-US', props.format);
        return percentFormat(value, formatter);
      },
      [props.format],
    );

    const onFormattedTextChange = useCallback(
      (num: string, text: string) => {
        if (num == null || num === '') {
          return setValue('');
        }

        const formatted = format(num);
        if (formatted.startsWith('NaN')) {
          return;
        }
        setValue(formatted);
      },
      [setValue, format],
    );

    const onFocus = useCallback(() => {
      setIsInFocus(true);
      if (value) {
        adjustSelection(value.length - 1);
      }
    }, [value]);

    const onBlur = useCallback(() => setIsInFocus(false), []);
    const onSelectionChange = useCallback(
      ({nativeEvent: {selection}}) => {
        //If there's text, and it isn't a range selection
        if (value?.length && selection.start === selection.end) {
          let adjustment = value.length - 1;
          if (previousText.current === value) {
            adjustment = Math.min(adjustment, selection.start);
          }
          adjustSelection(adjustment);
        }
        previousText.current = value;
      },
      [value],
    );

    useEffect(() => {
      let numericValue = value?.replace(/[^\d.-]+/g, '');
      if (numericValue !== '-' && numericValue !== '.') {
        numericValue =
          styleModifiers.percent.out(numericValue)?.toString() ?? '';
      }

      if (isInFocus) {
        onChangeText(numericValue);
        if (!isNaN(+numericValue)) {
          onChangeValue(+numericValue);
        }
      }
    }, [value, props.format, isInFocus]);

    //Watch for incoming changes
    useEffect(() => {
      let stripped = originalValue;
      if (!stripped) return;
      if (typeof originalValue === 'string') {
        stripped = originalValue?.replace(/[^\d.-]+/g, '');
      }

      if (!isInFocus) {
        onFormattedTextChange(
          (+stripped * 100)?.toString(),
          originalValue?.toString(),
        );
      }
    }, [originalValue]);

    const onKeyboardTextInput = useCallback(text => {
      const numeric = text.replace(NUMERIC_PATTERN, '');
      onFormattedTextChange(numeric, text);
    }, []);

    const TextInputComponent = props.TextInputComponent ?? TextInput;

    return (
      <TextInputComponent
        ref={input}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onSelectionChange={onSelectionChange}
        onChangeText={onKeyboardTextInput}
        placeholder={'%'}
        {...props}
      />
    );
  },
);
