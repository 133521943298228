import {useCallback} from 'react';
import {Image} from 'react-native-image-crop-picker';
import {useDispatch} from 'react-redux';
import {stageLocalPhotos} from './slice';

export const useStageLocalPhotos = () => {
  const dispatch = useDispatch();
  return useCallback(
    (photos: Image[]) => {
      dispatch(stageLocalPhotos(photos));
    },
    [dispatch],
  );
};
