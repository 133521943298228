import {DealState} from '../deal';
import {uploadFile} from '@houseque/core';

export const uploadDealPhotos = async (deal: DealState) => {
  const uploads = (deal.local?.photos ?? []).map(photo => {
    const filename = photo.path.split('/').pop();
    return uploadFile(
      photo.path,
      `user/${deal.createdBy?.id}/deal/${deal.id}/${filename}`,
    );
  });
  await Promise.all(uploads);
};
