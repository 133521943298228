import {useEffect, useMemo, useState} from 'react';
import {PresetCategory, PresetValue} from './types';
import {preferencesService} from './service';

const housequePresets: PresetValue[] = [
  {
    version: '1',
    source: 'houseque',
    category: 'expenses',
    type: 'percent',
    subcategory: 'capex',
    value: 0.08,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'expenses',
    type: 'percent',
    subcategory: 'repairs',
    value: 0.08,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'expenses',
    type: 'percent',
    subcategory: 'vacancy',
    value: 0.05,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'assumptions',
    subcategory: 'propertyvalue',
    type: 'percent',
    value: 0.03,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'assumptions',
    type: 'percent',
    subcategory: 'rent',
    value: 0.02,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'assumptions',
    type: 'percent',
    subcategory: 'expenses',
    value: 0.02,
  },
  {
    version: '1',
    source: 'houseque',
    category: 'assumptions',
    type: 'percent',
    subcategory: 'sell',
    value: 0.05,
  },
];

export const usePresets = (category?: PresetCategory) => {
  const [presets, setPresets] = useState<PresetValue[] | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  useEffect(() => {
    const unsubscribe = preferencesService.presets.onSnapshot(data => {
      const presets = data.docs.map(
        doc =>
          ({
            id: doc.id,
            ...doc.data(),
          } as PresetValue),
      );
      setPresets(presets);
    }, setError);
    return unsubscribe;
  }, []);
  //TODO why is this so complex?
  return useMemo(() => {
    if (category === undefined) {
      return {data: presets, error};
    }
    const filtered = presets?.filter(preset => preset.category === category);
    if (filtered?.length) {
      return {data: filtered, error};
    }
    return {
      data: housequePresets.filter(preset => preset.category === category),
      error,
    };
  }, [error, presets, category]);
};
