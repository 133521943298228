import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {LoginScreen} from './pages/LoginScreen';
import {WelcomeScreen} from './pages/WelcomeScreen';
import {RegistrationScreen} from './pages/RegistrationScreen';
import {ForgotPassword} from './pages/ForgotPassword';
import {LinkAccount} from './pages/LinkAccount';

const Stack = createStackNavigator();

export const PublicNavigationStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        cardStyle: {
          flex: 1,
          overflow: 'visible',
        },
      }}>
      <Stack.Screen
        options={{headerShown: false}}
        name={'Welcome'}
        component={WelcomeScreen}
      />
      <Stack.Screen name={'Login'} component={LoginScreen} />
      <Stack.Screen
        name={'Register'}
        options={{title: 'Get Started'}}
        component={RegistrationScreen}
      />
      <Stack.Screen
        options={{title: 'Reset Password', headerBackTitleVisible: true}}
        name={'ForgotPassword'}
        component={ForgotPassword}
      />
      <Stack.Screen name={'LinkAccount'} component={LinkAccount} />
    </Stack.Navigator>
  );
};
