import firebase from '@react-native-firebase/app';
import '@react-native-firebase/firestore';
import {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';
import {InvestmentTargets, PresetValue} from './types';

class UserPreferenceService {
  private get firestoreDoc(): FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData> {
    const userId = firebase.auth().currentUser?.uid;
    return firebase.firestore().collection(`users`).doc(userId);
  }

  get presets() {
    return this.firestoreDoc.collection('presets');
  }

  get investmentTargets() {
    return this.firestoreDoc.collection('investment-targets').doc('v1');
  }

  async saveInvestmentTargets(investmentTargets: InvestmentTargets) {
    await this.investmentTargets.set(investmentTargets);
  }

  async savePresets(presets: PresetValue[]) {
    const batch = firebase.firestore().batch();
    for (const {id, ...preset} of presets) {
      if (id) {
        batch.update(this.presets.doc(id), preset);
      } else {
        batch.set(this.presets.doc(), preset);
      }
    }
    await batch.commit();
  }
}

export const preferencesService = new UserPreferenceService();
