import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IapHubProductInformation} from 'react-native-iaphub';
import {PaidUser} from './plusUserService';

export interface PaidUserState {
  ready: boolean;
  error?: any;
  status?: Partial<PaidUser>;
  purchases?: IapHubProductInformation[];
}

const initialState: PaidUserState = {
  ready: false,
};

const slice = createSlice({
  initialState,
  name: 'paid-membership',
  reducers: {
    updatePlusStatus: (
      state,
      action: PayloadAction<PaidUserState['status']>,
    ) => {
      state.status = action.payload;
    },
    purchasesReady: (
      state,
      action: PayloadAction<PaidUserState['purchases']>,
    ) => {
      state.error = undefined;
      state.ready = true;
      state.purchases = action.payload;
    },
    purchasesErrorLoading: (
      state,
      action: PayloadAction<PaidUserState['error']>,
    ) => {
      state.ready = true;
      state.error = action.payload;
    },
  },
});

export const {
  purchasesErrorLoading,
  purchasesReady,
  updatePlusStatus,
} = slice.actions;
export default slice.reducer;
