import {useCallback} from 'react';
import {Linking, Alert, Platform} from 'react-native';

type UriSchema = 'href' | 'mailto' | 'geo' | 'maps';

export interface LinkOptions {
  address: string;
  params?: {[index: string]: string};
  schema?: UriSchema;
}

const inputMap: {
  [index in UriSchema]?: (options: LinkOptions) => LinkOptions; // eslint-disable-line
} = {
  geo: Platform.select({
    ios: (options: LinkOptions): LinkOptions => {
      return {
        schema: 'maps',
        address: '',
        params: {
          ...(options.params ?? {}),
          ll: options.address,
        },
      };
    },
    android: (options: LinkOptions) => options,
  }),
};

export const useOpenLink = () => {
  return useCallback(async (options: LinkOptions | string) => {
    let url = '';
    if (typeof options === 'string') {
      url = options;
    } else {
      const mapFunction =
        inputMap[options.schema] || ((input: LinkOptions) => input);
      const {params = {}, schema, address} = mapFunction(options);

      const qs = Object.entries(params)
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join('&');
      url = `${schema}:${address}?${qs}`;
    }
    try {
      const canOpen = await Linking.canOpenURL(url);
      if (canOpen) {
        await Linking.openURL(url);
      } else {
        throw Error(`Unable to open ${url}`);
      }
    } catch (err) {
      Alert.alert('This is embarrassing...', err.message);
    }
  }, []);
};
