import {useCashflow} from '../useCashflow';
import {useEffect, useState} from 'react';

export const useRent = () => {
  const [rent, setRent] = useState(0);
  const {income} = useCashflow();
  useEffect(() => {
    const rentData = income.find(inc => inc.tag === 'rent');
    setRent(rentData?.amount ?? 0);
  }, [income]);
  return rent;
};
