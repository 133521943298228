import {useCallback} from 'react';
import {Alert} from 'react-native';
import {useDispatch} from 'react-redux';
import {groupsErrorAction} from './actions';
import {deleteGroup} from './groupsService';
import {Group} from './types';

interface ConfirmationOptions {
  message: string;
  confirmText?: string;
  cancelText?: string;
  subtitle?: string;
}

const confirm = ({
  message,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  subtitle,
}: ConfirmationOptions) => {
  return new Promise((resolve, reject) => {
    Alert.alert(message, subtitle, [
      {text: confirmText, style: 'destructive', onPress: resolve},
      {text: cancelText, style: 'cancel', onPress: () => reject('cancelled')},
    ]);
  });
};

export const useDeleteGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (group: Group) => {
      try {
        await confirm({
          message: `Are you sure you'd like to delete ${group.displayName}`,
          subtitle: `This will remove all deals, and remove for ${
            Object.keys(group?.members ?? {}).length
          } users`,
        });
        return await deleteGroup(group);
      } catch (err) {
        if (err === 'cancelled') return err;
        dispatch(groupsErrorAction(err));
        throw err;
      }
    },
    [deleteGroup],
  );
};
