import React from 'react';
import {SafeAreaView, ScrollView, ScrollViewProps} from 'react-native';
import {useStyle} from '../core/theme';

interface ScreenProps extends ScrollViewProps {
  children: any;
}

export const Screen = ({children, style, ...props}: ScreenProps) => {
  const styles = useStyle(({edge: {medium}}) => ({
    container: {
      margin: medium,
    },
    flex: {
      flex: 1,
    },
  }));

  return (
    <SafeAreaView style={[styles.container, style, styles.flex]}>
      <ScrollView
        keyboardDismissMode={'none'}
        scrollEnabled={false}
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={styles.flex}
        {...props}>
        {children}
      </ScrollView>
    </SafeAreaView>
  );
};
