import React from 'react';
import {Text, Pointer, Box} from '@houseque/components';
import {SavedAnalysisListItem} from './SavedAnalysisListItem';
import {useSampleDeal, SampleType} from './useSampleDeal';

interface SampleDealProps {
  dealType?: SampleType;
}

const SampleDeal = ({dealType}: SampleDealProps) => {
  const sampleDeal = useSampleDeal(dealType);

  return <SavedAnalysisListItem disableSwipe analysis={sampleDeal} />;
};

const SampleLabel = () => {
  return (
    <Box
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}>
      <Text variant={'body'}> Start from a Sample</Text>
      <Pointer direction={'up'} />
    </Box>
  );
};

export const Welcome = () => {
  return (
    <Box marginVertical={'m'}>
      <SampleDeal />
      <SampleDeal dealType={'flip'} />
      <Box margin={'m'}>
        <SampleLabel />
      </Box>
    </Box>
  );
};
