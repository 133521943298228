import React, {useState, useEffect, useCallback} from 'react';
import {Switch} from '@houseque/components';
import {FlatList} from 'react-native';
import {RateType, ARMType} from '@houseque/types';
import {Selection} from './Selection';

const armOptions: ARMType[] = ['5/1', '7/1', '3/1', '10/1'];

interface SelectRateTypeProps {
  onSelectRateType: (loanRateType: RateType) => void;
  selectedRateType?: RateType;
  onSelectArmType: (armType: ARMType) => void;
  selectedArmType: ARMType;
}

export const SelectLoanRateType = ({
  selectedRateType,
  onSelectRateType,
  selectedArmType,
  onSelectArmType,
}: SelectRateTypeProps) => {
  const [isArm, setIsArm] = useState(selectedRateType === 'arm');
  useEffect(() => {
    onSelectRateType(isArm ? 'arm' : 'frm');
  }, [isArm]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useEffect(() => {
    setSelectedIndex(armOptions.indexOf(selectedArmType));
  }, [selectedArmType]);

  const onPress = useCallback(
    pressedIndex => {
      onSelectArmType(armOptions[pressedIndex]);
    },
    [onSelectArmType],
  );

  return (
    <>
      <Switch
        explanation={'arm'}
        label={'Adjustable rate loan?'}
        value={isArm}
        onValueChange={setIsArm}
      />
      {(isArm && (
        <FlatList
          data={armOptions}
          horizontal
          keyExtractor={item => item + ''}
          renderItem={({item, index}) => {
            return (
              <Selection
                title={item}
                selected={index === selectedIndex}
                onPress={() => onPress(index)}
              />
            );
          }}
        />
      )) ||
        null}
    </>
  );
};
