import {DealState} from '../../member/screens/deal-analyzer/deal';

export const grossMonthlyIncome = (deal: DealState) => {
  const {scenarios, currentScenarioIndex} = deal.cashflow;
  const income = scenarios[currentScenarioIndex]?.value?.income ?? [];
  let grossMonthlyIncome = 0;
  for (const item of income) {
    grossMonthlyIncome += item.amount;
  }
  return grossMonthlyIncome;
};
