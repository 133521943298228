import {put, select, takeLatest} from 'redux-saga/effects';
import {HouseQueAppState} from '../../../houseque';
import {
  changeIncome,
  changeExpenses,
  setDeal,
  resetAnalysis,
  upsertCashflow,
  setMonthlyCashflow,
  setMonthlyIncome,
  setMonthlyNetOperatingIncome,
  computedFinancingUpdate,
} from '../slice';

// TODO Add check for refi, or just add those by default
function* calculateCashflow() {
  const monthlyCashflow = yield select((state: HouseQueAppState) => {
    const {
      selectedDealComputedFinancials: {loans},
      selectedDealEstimates: {monthlyNetOperatingIncome = 0},
    } = state.deals;
    const [loan] = loans || [];
    const loanPayment = loan?.payment ?? 0;
    return monthlyNetOperatingIncome - loanPayment;
  });
  yield put(setMonthlyCashflow(monthlyCashflow));
}

function* calculateGrossMonthlyIncome() {
  const income: Income[] = yield select((state: HouseQueAppState) => {
    const {scenarios, currentScenarioIndex} = state.deals.selectedDeal.cashflow;
    return scenarios[currentScenarioIndex]?.value?.income ?? [];
  });
  let grossMonthlyIncome = 0;
  for (const item of income) {
    grossMonthlyIncome += item.amount;
  }
  yield put(setMonthlyIncome(grossMonthlyIncome));
}

function* calculateMonthlyNetOperatingIncome() {
  const {
    expenses,
    grossMonthlyIncome,
    income,
  }: {
    grossMonthlyIncome: number;
    expenses: Expense[];
    income: Income[];
  } = yield select((state: HouseQueAppState) => {
    const {
      deals: {
        selectedDeal: {
          cashflow: {scenarios, currentScenarioIndex},
        },
        selectedDealEstimates: {grossMonthlyIncome},
      },
    } = state;
    const cashflowState = scenarios[currentScenarioIndex]?.value;
    const {expenses, income} = cashflowState ?? {};
    return {
      expenses: expenses ?? [],
      income: income ?? [],
      grossMonthlyIncome,
    };
  });
  const rent = income.find(inc => inc.tag === 'rent')?.amount ?? 0;
  let netOperatingExpenses = 0;
  for (const expense of expenses) {
    switch (expense.amountType) {
      case 'percent':
        netOperatingExpenses += expense.amount * rent;
        break;
      default:
        netOperatingExpenses += expense.amount;
    }
  }
  const netOperatingIncome = grossMonthlyIncome - netOperatingExpenses;
  yield put(setMonthlyNetOperatingIncome(netOperatingIncome));
}
//TODO Add the scenario change on the first two
export default function* estimatesSaga() {
  yield takeLatest(
    [
      resetAnalysis.type,
      changeIncome.type,
      setDeal.type,
      upsertCashflow.type,
      'CHANGE_CURRENT_SCENARIO_CASHFLOW',
    ],
    calculateGrossMonthlyIncome,
  );
  yield takeLatest(
    [changeExpenses.type, setMonthlyIncome.type],
    calculateMonthlyNetOperatingIncome,
  );
  yield takeLatest(
    [setMonthlyNetOperatingIncome.type, computedFinancingUpdate.type],
    calculateCashflow,
  );
}
