import {FirebaseFirestoreTypes} from '@react-native-firebase/firestore';
import {Group} from './types';

/**
 * Returns the date, and defaults to now (for groups just created)
 * @param group
 */
export const getCreatedDate = (
  group: Group,
  defaultDate: Date = new Date(),
) => {
  let date = defaultDate;
  if (group.createdTimestamp?.seconds) {
    date = new Date(group.createdTimestamp?.seconds * 1000);
  }
  return `${date.getMonth() + 1}, ${date.getFullYear()}`;
};

//Currently hacky
export const formatDate = (timestamp: FirebaseFirestoreTypes.Timestamp) => {
  let date = new Date();
  if (timestamp) {
    date = new Date(timestamp.seconds * 1000);
  }
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};
