import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface NotificationState {
  tags?: {[index: string]: string};
}

const initialState: NotificationState = {
  tags: {},
};

const slice = createSlice({
  name: 'pushnotifications',
  initialState,
  reducers: {
    subscribeToGroup: (state, action: PayloadAction<string>) => {
      state.tags[action.payload] = '1';
    },
    unsubscribeToGroup: (state, action: PayloadAction<string>) => {
      delete state.tags[action.payload];
    },
    tagsLoaded: (state, action: PayloadAction<NotificationState['tags']>) => {
      Object.assign(state.tags, action.payload);
    },
  },
});

export const {subscribeToGroup, unsubscribeToGroup, tagsLoaded} = slice.actions;
export default slice.reducer;
