import {Text} from './Text';
import {Box} from './Box';
import React from 'react';
import {ExplanationIcon} from '@houseque/core/explanations';
import {Explanations} from '@houseque/core/explanations/types';

type TextProps = React.ComponentProps<typeof Text>;

export interface LabelProps extends TextProps {
  explanation?: Explanations;
  children?: any;
}

export const Label = ({style, children, explanation, ...props}: LabelProps) => {
  const Explanation = explanation ? (
    <ExplanationIcon which={explanation} />
  ) : null;
  return (
    <Box flexDirection={'row'} alignItems={'center'}>
      <Text color={'grey1'} variant={'callout'}>
        {children}
      </Text>
      {Explanation}
    </Box>
  );
};
