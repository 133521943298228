import {useEffect, useState, useCallback} from 'react';
import {Dimensions} from 'react-native';

export const useWindowWidth = () => {
  const [width, setWidth] = useState(Dimensions.get('window').width);
  const onDimensionChange = useCallback(event => {
    setWidth(event.window.width);
  }, []);
  useEffect(() => {
    Dimensions.addEventListener('change', onDimensionChange);
    return () => Dimensions.removeEventListener('change', onDimensionChange);
  });
  return width;
};
