import React, {useMemo} from 'react';
import {Provider} from 'react-redux';
import {primary} from '@houseque/core/theme/houseque.theme';
import {theme as paperTheme} from './core/theme/paper';
import restyleTheme from './core/theme/restyle';
import {ThemeProvider as RestyleThemeProvider} from '@shopify/restyle';
import {Provider as PaperProvider} from 'react-native-paper';
import {
  NavigationContainer,
  LinkingOptions,
  PathConfigMap,
} from '@react-navigation/native';
import {NonMemberApp} from './nonmember/NonMemberApp';
import {ThemeProvider} from 'react-native-elements';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import BootSplash from 'react-native-bootsplash';
import {useDispatchNavigationEvent} from '@houseque/core/navigation';
import {useIsLoggedIn} from './core/application/user';
import {MemberApp} from './member/MemberApp';
import {store as nonmemberStore} from './nonmember/store';
import {store as memberStore} from './member/store';

const dealStack = {
  Report: 'report',
  Loan: 'loan',
  Refinance: 'refinance',
  User: 'user',
  Cashflow: 'cashflow',
};

const config: PathConfigMap = {
  home: {
    initialRouteName: 'dashboard',
    screens: {
      dashboard: 'dashboard',
      deal: 'deal',
      ...dealStack,
    },
  },
  Login: 'Login',
  Register: 'Register',
  ForgotPassword: 'ForgotPassword',
};

const linking: LinkingOptions = {
  prefixes: ['https://app.houseque.com', 'https://devapp.houseque.com'],
  config: {
    screens: config,
  },
};

export const AppAuthSwitch = ({isLoggedIn}: {isLoggedIn: boolean}) => {
  const dispatchNavigationEvent = useDispatchNavigationEvent();
  return (
    <NavigationContainer
      linking={linking}
      onReady={BootSplash.hide}
      onStateChange={dispatchNavigationEvent}>
      {isLoggedIn ? <MemberApp /> : <NonMemberApp />}
    </NavigationContainer>
  );
};

export default () => {
  const isLoggedIn = useIsLoggedIn();
  const store = useMemo(() => {
    return isLoggedIn ? memberStore : nonmemberStore;
  }, [isLoggedIn]);
  //TODO make this smoother
  if (isLoggedIn === undefined) {
    return null;
  }
  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <PaperProvider theme={paperTheme}>
          <RestyleThemeProvider theme={restyleTheme}>
            <ThemeProvider theme={primary}>
              <AppAuthSwitch isLoggedIn={isLoggedIn} />
            </ThemeProvider>
          </RestyleThemeProvider>
        </PaperProvider>
      </SafeAreaProvider>
    </Provider>
  );
};
