import {useCallback} from 'react';
import {signout} from './slice';
import {useDispatch} from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(signout());
  }, [dispatch]);
};
