import auth from '@react-native-firebase/auth';
import {useEffect, useState} from 'react';

export const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(state => {
      setIsLoggedIn(!!state);
    });
    return unsubscribe;
  }, [setIsLoggedIn]);
  return isLoggedIn;
};
