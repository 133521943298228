import React from 'react';
import {
  StyleSheet,
  TouchableOpacityProps,
  TouchableOpacity,
  Text,
  Platform,
} from 'react-native';
import {useTheme} from '@react-navigation/native';

interface HeaderLinkProps extends TouchableOpacityProps {
  title: string;
}

const styles = StyleSheet.create({
  label: {
    fontSize: 17,
    // Title and back label are a bit different width due to title being bold
    // Adjusting the letterSpacing makes them coincide better
    letterSpacing: 0.35,
  },
});

const addtlStyle =
  Platform.select({
    web: {
      paddingRight: 14,
    },
  }) || {};

export const HeaderLink = ({title, ...props}: HeaderLinkProps) => {
  const theme = useTheme();
  return (
    <TouchableOpacity {...props}>
      <Text
        style={[
          styles.label,
          {color: theme.colors.primary, opacity: props.disabled ? 0.3 : 1},
          addtlStyle,
        ]}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};
