import React, {useMemo} from 'react';
import {Button} from '@houseque/components';

interface SocialLoginButtonProps {
  type: SocialMediaType;
  title: string;
  disabled?: boolean;
  onPress: () => void;
}

type SocialMediaType = 'google' | 'facebook';

const social: {
  [index in SocialMediaType]: {color: string; text: string};
} = {
  google: {
    color: '#dd4b39',
    text: 'Sign In With Google',
  },
  facebook: {
    color: '#1877F2',
    text: 'Continue with Facebook',
  },
};

export const SocialLoginButton = ({
  type,
  onPress,
  disabled,
}: SocialLoginButtonProps) => {
  const config = useMemo(() => {
    return social[type];
  }, [type]);
  return (
    <Button
      onPress={onPress}
      color={config.color}
      disabled={disabled}
      loading={false}
      icon={type}
      title={config.text}
    />
  );
};
