import {useCallback} from 'react';
import {useNavigation} from '@react-navigation/core';
import {DealState} from '@houseque/core/deals/deal';

export const useGoToDeal = () => {
  const {navigate} = useNavigation();
  return useCallback(
    (deal?: DealState) => {
      if (!deal) {
        navigate('deal');
      } else {
        navigate('deal', {deal: JSON.stringify(deal)});
      }
    },
    [navigate],
  );
};
