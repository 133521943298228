import {
  Keyboard,
  KeyboardEventListener,
  KeyboardEventName,
  KeyboardEvent,
  Platform,
} from 'react-native';
import {useEffect, useState} from 'react';

interface KeyboardEventCustom extends KeyboardEvent {
  duration: number;
  closing?: boolean;
}

export const useKeyboardListener = (isBeforeEvents = false) => {
  const [keyboardEvent, setKeyboardEvent] = useState<KeyboardEventCustom>(
    {} as any,
  );
  useEffect(() => {
    const handler: KeyboardEventListener = event => {
      setKeyboardEvent({
        ...event,
        closing: event
          ? event.startCoordinates
            ? event.startCoordinates.screenY < event.endCoordinates.screenY
            : false
          : true,
      });
    };
    const events: KeyboardEventName[] = Platform.select({
      web: [],
      android: ['keyboardDidHide', 'keyboardDidShow'],
      ios: isBeforeEvents
        ? ['keyboardWillShow', 'keyboardWillHide']
        : ['keyboardDidHide', 'keyboardDidShow'],
    });
    const subscriptions = events.map(event =>
      Keyboard.addListener(event, handler),
    );
    return () => {
      subscriptions.forEach(sub => Keyboard.removeSubscription(sub));
    };
  }, [isBeforeEvents]);
  return keyboardEvent;
};
