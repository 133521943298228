import React, {useCallback} from 'react';
import {Card, Modal, TextInput as Input} from 'react-native-paper';
import {useSelector} from 'react-redux';
import {Box, Text, TextInput} from '../../components';
import {HouseQueAppState} from '../../houseque';
import {categoricalActions} from './utils';
import {stopEdit} from './slice';
import {useDispatch} from 'react-redux';
import {useCategoryScenarioState} from './useCategoryScenarios';

export const ScenarioEdit = () => {
  const dispatch = useDispatch();
  const scenarios = useSelector((state: HouseQueAppState) => {
    return state.scenarios;
  });
  const scenario = useCategoryScenarioState(scenarios.category);

  const onDoneEditing = useCallback(() => {
    dispatch(stopEdit());
  }, [dispatch]);

  const onChangeText = useCallback(
    payload => {
      dispatch(categoricalActions[scenarios.category].renameScenario(payload));
    },
    [scenarios.category],
  );

  const onRemove = useCallback(
    (index: number) => {
      dispatch(categoricalActions[scenarios.category].removeScenario(index));
    },
    [scenarios.category],
  );

  return (
    <Modal visible={scenarios.editing} onDismiss={onDoneEditing} dismissable>
      <Box padding={'m'}>
        <Card>
          <Box padding={'m'}>
            <Text variant={'title3'}>Edit Scenarios</Text>
            <Text variant={'caption1'} color={'grey2'} paddingBottom={'m'}>
              Rename or remove scenarios
            </Text>
            {scenario?.scenarios.map((scenario, index) => {
              return (
                <TextInput
                  onChangeText={(name: string) => onChangeText({name, index})}
                  value={scenario.name}
                  //@ts-ignore
                  right={
                    //@ts-ignore
                    <Input.Icon
                      forceTextInputFocus={false}
                      onPress={() => onRemove(index)}
                      name={'close'}
                    />
                  }
                />
              );
            })}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};
