import {launchCameraRoll} from './actions';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';

export const useLaunchCameraRole = () => {
  const dispatch = useDispatch();
  return useCallback(
    (photoLimit?: number) => {
      dispatch(launchCameraRoll(photoLimit));
    },
    [dispatch],
  );
};
