import React from 'react';
import {SafeAreaView, View, ViewStyle, StyleProp} from 'react-native';
import {useStyle} from '../core/theme';
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view';

interface ScreenWithKeyboardProps extends KeyboardAwareScrollViewProps {
  children: any;
  containerStyle?: StyleProp<ViewStyle>;
  /**
   * Defaults to SafeAreaView
   */
  ViewComponent?: typeof View;
}

export const ScreenWithKeyboard = ({
  children,
  ViewComponent,
  containerStyle,
  style,
  ...props
}: ScreenWithKeyboardProps) => {
  const styles = useStyle(({edge: {medium}}) => ({
    container: {
      margin: medium,
    },
    flex: {
      flex: 1,
    },
  }));
  const WrapperComponent = ViewComponent || SafeAreaView;
  return (
    <WrapperComponent style={[styles.container, styles.flex, containerStyle]}>
      <KeyboardAwareScrollView
        keyboardDismissMode={'none'}
        scrollEnabled={false}
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={[styles.flex, style]}
        {...props}>
        {children}
      </KeyboardAwareScrollView>
    </WrapperComponent>
  );
};
