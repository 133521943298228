import {useState, useEffect} from 'react';
import {useCurrentUser} from '@houseque/core/application/user';
import {FirebaseAuthTypes} from '@react-native-firebase/auth';

export interface UserConditionalProps {
  condition: (user: Partial<FirebaseAuthTypes.User>) => boolean;
  TrueComponent?: JSX.Element;
  FalseComponent?: JSX.Element;
  children?: any;
}

export const UserConditional = ({
  condition,
  TrueComponent,
  FalseComponent,
  children,
}: UserConditionalProps) => {
  const user = useCurrentUser();
  const [conditionalResult, setConditionalResult] = useState(condition(user!));
  useEffect(() => {
    setConditionalResult(!!user && condition(user));
  }, [user, condition]);

  const Component = conditionalResult
    ? TrueComponent || children
    : FalseComponent;
  return Component ?? null;
};
