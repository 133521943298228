import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changeExpenses} from '../../slice';

export const useUpdateExpenses = () => {
  const dispatch = useDispatch();
  const updateExpenses = useCallback(
    financingDetails => {
      dispatch(changeExpenses(financingDetails));
    },
    [dispatch],
  );
  return updateExpenses;
};
