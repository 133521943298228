import React, {useCallback} from 'react';
import {Snackbar, Banner} from 'react-native-paper';
import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {useUpdateDealLocalData} from '@houseque/core/deals/actions';

type SnackbarProps = React.ComponentProps<typeof Snackbar>;

interface UnsavedChangesProps {
  containerStyle?: {};
  onPress: () => void;
}

export const UnsavedChanges = ({
  containerStyle,
  ...props
}: UnsavedChangesProps & Partial<SnackbarProps>) => {
  const currentDeal = useCurrentDeal();
  const updateCurrentDealLocal = useUpdateDealLocalData();
  const onDismiss = useCallback(() => {
    updateCurrentDealLocal({
      unsavedChanges: false,
    });
  }, [updateCurrentDealLocal]);

  return (
    <Banner
      visible={currentDeal.local?.unsavedChanges}
      icon={'alert-circle-outline'}
      actions={[
        {
          label: 'Continue',
          onPress: props.onPress,
        },
        {
          label: 'Dismiss',
          onPress: onDismiss,
        },
      ]}>
      You have unsaved changes, would you like to continue?
    </Banner>
  );
};
