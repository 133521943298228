import React, {useState, useCallback, useEffect} from 'react';
import {Platform} from 'react-native';
import {
  TextInput,
  Button,
  UserAvatar,
  Box,
  Text,
  KeyboardAvoidingView,
  ModalClose,
} from '../../../components';
import {useCurrentUser} from '@houseque/core/application/user';
import ImagePicker, {Image} from 'react-native-image-crop-picker';
import {useFileUpload} from '../../../core';
import {OnlineOnly} from '../../../components/OnlineOnly';
import {UserProfile as UserProfileType} from '@houseque/types';

export const UserProfile = () => {
  const [displayName, setName] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [email, setEmailAddr] = useState('');
  const [stagedPhoto, setStagedPhoto] = useState<Image | undefined>(undefined);
  const uploadFile = useFileUpload();
  const user = useCurrentUser();

  useEffect(() => {
    setEmailAddr(user.email);
    setName(user.displayName);
  }, [user.email, user.displayName]);

  const selectPhoto = useCallback(async () => {
    if (Platform.OS === 'web') return;
    const image = (await ImagePicker.openPicker({
      cropping: true,
      multiple: false,
    })) as Image;
    setStagedPhoto(image);
  }, []);

  const userId = user?.uid;
  const save = useCallback(async () => {
    setSaving(true);
    try {
      let photoURL;
      const itemsToSave = [];
      if (stagedPhoto) {
        const ext = stagedPhoto.path.split('.').pop();
        const fileName = `user/${userId}/profile.${ext}`;
        photoURL = await uploadFile(fileName, stagedPhoto?.path);
      }
      const toSave = {
        photoURL,
        displayName,
      };
      if (email) {
        itemsToSave.push(user?.updateEmail(email));
      }
      if (Object.keys(toSave).length) {
        itemsToSave.push(user?.updateProfile(toSave));
      }
      await Promise.all(itemsToSave);
    } catch (err) {
      console.log(err);
    }
    setSaving(false);
    // eslint-disable-next-line
  }, [stagedPhoto, displayName, email, userId, uploadFile]);

  useEffect(() => {
    setDisabled(!stagedPhoto && !displayName && !email);
  }, [stagedPhoto, displayName, email]);

  return (
    <Box flex={1} margin={'m'}>
      <Box
        marginVertical={'m'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}>
        <ModalClose />
      </Box>
      <Box justifyContent={'center'} alignItems={'center'} flex={0.8}>
        <UserAvatar onPress={selectPhoto} user={user as UserProfileType} />
        <Text variant={'caption1'} color={'grey2'}>
          Tap to update photo
        </Text>
      </Box>
      <KeyboardAvoidingView
        keyboardVerticalOffset={20}
        behavior={'position'}
        style={{flex: 0.6}}>
        <TextInput
          keyboardType={'name-phone-pad'}
          label={'Name'}
          autoCompleteType={'name'}
          value={displayName}
          onChangeText={setName}
        />
        <TextInput
          onChangeText={setEmailAddr}
          label={'Email'}
          autoCompleteType={'email'}
          keyboardType={'email-address'}
          textContentType={'emailAddress'}
          value={email}
        />
        <Box paddingTop={'m'} />
        <OnlineOnly>
          <Button
            disabled={disabled}
            loading={saving}
            onPress={save}
            title={'Save'}
          />
        </OnlineOnly>
      </KeyboardAvoidingView>
      <Box flex={0.4} />
    </Box>
  );
};
