import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {NonmemberAuthState} from './types';
import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {SupportedLogins} from './services/socialLoginService';
import {userNavigation} from '@houseque/core/navigation';

const initialState: NonmemberAuthState = {
  inFlight: false,
  errorMessage: undefined,
  forgotPasswordError: undefined,
};

const slice = createSlice({
  name: 'nonmemberauth',
  initialState,
  extraReducers: builder => {
    // Reset error messages on navigate
    builder.addCase(userNavigation, state => {
      state.errorMessage = undefined;
    });
  },
  reducers: {
    authLoginSocialEmailExists: (
      state,
      action: PayloadAction<{
        credentials: FirebaseAuthTypes.AuthCredential;
        email: string;
      }>,
    ) => {
      state.associateAccount = action.payload;
    },
    authLoginLink: (state, _: PayloadAction<string>) => {
      state.inFlight = true;
    },
    authLoginLinkComplete: state => {
      state.inFlight = true;
      state.errorMessage = undefined;
      state.associateAccount = undefined;
    },
    authLoginLinkReject: state => {
      state.inFlight = false;
      state.associateAccount = undefined;
    },
    authError: (state, action: PayloadAction<string>) => {
      state.inFlight = false;
      state.errorMessage = action.payload;
    },
    authLoginSocialComplete: state => {
      state.inFlight = false;
    },
    authLoginSocial: (state, _: PayloadAction<SupportedLogins>) => {
      state.inFlight = true;
      state.errorMessage = undefined;
    },
    authLogin: (state, _: PayloadAction<{email: string; password: string}>) => {
      state.inFlight = true;
      state.errorMessage = undefined;
    },
    registerUser: (
      state,
      _: PayloadAction<{
        email: string;
        password: string;
        displayName: string;
      }>,
    ) => {
      state.inFlight = true;
      state.errorMessage = undefined;
    },
    registerUserSuccess: state => {
      state.inFlight = false;
      state.errorMessage = undefined;
    },
  },
});

export default slice;
