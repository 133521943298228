import {createTheme} from '@shopify/restyle';
import {colors} from './colors';
const palette = {
  purpleLight: '#8C6FF7',
  purplePrimary: '#5A31F4',
  purpleDark: '#3F22AB',

  greenLight: '#56DCBA',
  greenPrimary: '#0ECD9D',
  greenDark: '#0A906E',
  grey: '#aaa',
  black: '#0B0B0B',
  // white: '#F0F2F3',
  white: '#FFF',
};

const theme = createTheme({
  colors,
  spacing: {
    xxxs: 2,
    xxs: 4,
    xs: 6,
    s: 8,
    m: 16,
    l: 24,
    xl: 32,
    xxl: 43,
    '-s': -8,
    '-m': -16,
    '-l': -24,
    '-xl': -32,
    '-xxl': -43,
  },
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
  borderRadii: {
    standard: 5,
  },
  textVariants: {
    largeTitle: {
      fontSize: 34,
      lineHeight: 41,
    },
    title1: {
      fontSize: 28,
      lineHeight: 34,
    },
    title2: {
      fontSize: 22,
      lineHeight: 28,
    },
    title3: {
      fontSize: 20,
      lineHeight: 25,
    },
    headline: {
      fontSize: 17,
      lineHeight: 22,
      fontWeight: '700',
      letterSpacing: 0.7,
    },
    button: {
      fontSize: 17,
      fontWeight: '600',
      letterSpacing: 0.6,
    },
    body: {
      fontSize: 17,
      lineHeight: 22,
    },
    callout: {
      fontSize: 16,
      lineHeight: 21,
    },
    subhead: {
      fontSize: 15,
      lineHeight: 20,
    },
    footnote: {
      fontSize: 13,
      lineHeight: 18,
    },
    caption1: {
      fontSize: 12,
      lineHeight: 16,
    },
    caption2: {
      fontSize: 11,
      lineHeight: 13,
    },
  },
  buttonVariants: {},
  cardVariants: {
    raised: {
      elevation: 4,
      shadowColor: 'black',
      borderRadius: 7,
      shadowWidth: 3,
      shadowOpacity: 0.25,
      shadowOffset: {
        x: 0,
        y: -7,
      },
    },
  },
  textInputVariants: {},
});

export type Theme = typeof theme;
export default theme;
