import {useSelector, shallowEqual} from 'react-redux';
import {useCallback} from 'react';
import {HouseQueAppState} from '../../../houseque';
import {DealState} from '../../../member/screens/deal-analyzer/deal';

export const useCurrentDeal = (): DealState => {
  const deal = useSelector(
    useCallback((state: HouseQueAppState) => {
      return state.deals.selectedDeal;
    }, []),
    shallowEqual,
  );
  return deal;
};
