import {Deal} from '@houseque/types';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {addMultipleDealsToGroupAction} from './actions';

export const useAddDealsToGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    (deals: Deal[], groupId: string) => {
      dispatch(addMultipleDealsToGroupAction({deals, groupId}));
    },
    [dispatch],
  );
};
