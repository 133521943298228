import React, {useCallback, useState, useRef} from 'react';
import {Box, TextInput} from '@houseque/components';
import Decimal from 'decimal.js';
import {Selection} from './Selection';
import {FlatList} from 'react-native';

const loanToValueOptions = [0.8, 0.75, 0.7, 0.85, 0.5, 0.6, 0.65, 0.9];

const formatOption = (option: string | number) => {
  return new Decimal(option).times(-1).plus(1).toNumber();
};

const formatForDisplay = (value: number) => {
  return `${Decimal.mul(value, 100).toString()} %`;
};

export interface DownPaymentSelectorProps {
  onSelect: (downpayment: number) => void;
  selected?: number;
}

export const SelectLoanToValue = ({
  selected,
  onSelect,
}: DownPaymentSelectorProps) => {
  const inputRef = useRef(undefined as any);
  const [selectedIndex, setSelectedIndex] = useState(
    loanToValueOptions.indexOf(+selected!),
  );

  const onPress = useCallback(
    pressedIndex => {
      const value = loanToValueOptions[pressedIndex];
      inputRef.current?.blur();
      setSelectedIndex(loanToValueOptions.indexOf(value));
      onSelect(formatOption(value));
    },
    [onSelect, inputRef],
  );

  const onChangeText = useCallback(
    text => {
      setSelectedIndex(loanToValueOptions.indexOf(+text));
      const downPaymentAsLtv = new Decimal(1).minus(+text).toNumber();
      onSelect(downPaymentAsLtv);
    },
    [onSelect],
  );

  return (
    <>
      <TextInput
        keyboardType={'decimal-pad'}
        label={'Loan to value'}
        value={formatOption(selected)}
        onChangeText={onChangeText}
        ref={inputRef.current}
        type={'percent'}
        placeholder={'Input LTV, or select below'}
      />
      <Box marginRight={'-m'} marginVertical={'m'}>
        <FlatList
          data={loanToValueOptions}
          horizontal
          keyExtractor={item => item + ''}
          renderItem={({item, index}) => {
            return (
              <Selection
                title={formatForDisplay(item)}
                selected={index === selectedIndex}
                onPress={() => onPress(index)}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
