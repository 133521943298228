import React from 'react';
import {EstimatePane} from './EstimatePane';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../../../houseque';
import {usePurchaseState} from '@houseque/core/deals/selectors/financing';

export const useCapRate = () => {
  const [capRate, setCapRate] = useState<number | undefined>(undefined);
  const purchase = usePurchaseState();
  const noi = useSelector(
    (store: HouseQueAppState) =>
      store.deals.selectedDealEstimates.netOperatingIncome!,
  );

  useEffect(() => {
    const result = noi / (purchase.price ?? 1);
    setCapRate(result);
  }, [noi, purchase.price]);
  return capRate;
};

export const CapRate = () => {
  const capRate = useCapRate();
  return (
    <EstimatePane
      explanation={'caprate'}
      valueType={'percent'}
      label={'Cap Rate'}
      value={capRate}
    />
  );
};
