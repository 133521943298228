import {takeLatest, call, put, select} from 'redux-saga/effects';
import authSlice from './slice';
import {HouseQueNonMemberState, ReduxAction} from '@houseque/core/types';
import {
  login,
  registerUser,
  SupportedLogins,
  socialLogins,
  SocialCredentials,
} from './services';
import auth from '@react-native-firebase/auth';
import {logLoginAction} from '@houseque/core/application/analytics/actions';
import {exceptionCaughtAction} from '@houseque/core/application/exceptions';

function* handleError(error: Error) {
  yield put(exceptionCaughtAction(error));
}

function* handleLogin({
  payload: {email, password},
}: ReduxAction<{email: string; password: string}>) {
  try {
    yield call(login, email, password);
    yield put(
      logLoginAction({
        method: 'emailpassword',
      }),
    );
  } catch (err) {
    yield handleError(err);
    yield put(
      authSlice.actions.authError(
        '"Unrecognized username/password, please try again."',
      ),
    );
  }
}

function* handleSocialLogin({payload}: ReduxAction<SupportedLogins>) {
  try {
    let user;
    const results: SocialCredentials = yield call(async () => {
      return socialLogins[payload]();
    });
    try {
      //Cancelled logins
      if (results !== undefined) {
        yield call(async () => {
          return await auth().signInWithCredential(results.credentials);
        });
        yield put(
          logLoginAction({
            method: payload,
          }),
        );
      }
    } catch (err) {
      if (!err.message.match(/account-exists-with-different/i)) {
        throw err;
      }
      //Time to associate it.
      user = results.user ?? (yield call(results.getUser));
      //Do something
      yield put(
        authSlice.actions.authLoginSocialEmailExists({
          credentials: results.credentials,
          email: user?.email,
        }),
      );
    }
  } catch (err) {
    yield handleError(err);
    yield put(
      authSlice.actions.authError(
        'Unable to login...we have been alerted, we will restore soon.',
      ),
    );
  }
  yield put(authSlice.actions.authLoginSocialComplete());
}

function* handleLinkAccount(action: ReduxAction<string>) {
  const associatedAccount: {email: string; credentials: any} = yield select(
    (store: HouseQueNonMemberState) => store.authentication.associateAccount,
  );
  try {
    yield call(async () => {
      const user = await auth().signInWithEmailAndPassword(
        associatedAccount.email,
        action.payload,
      );
      await user.user.linkWithCredential(associatedAccount.credentials);
    });

    yield put(authSlice.actions.authLoginLinkComplete());
  } catch (err) {
    let message = 'Incorrect password.';
    if (!err.message?.match(/wrong-password/)) {
      message = 'We ran into an error linking the accounts.';
      yield handleError(err);
    }

    yield put(authSlice.actions.authError(message));
  }
}

function* handleRegistration({
  payload: {email, password, displayName},
}: ReduxAction<{email; password; displayName}>) {
  try {
    yield call(registerUser, email, password, displayName);

    yield put(authSlice.actions.registerUserSuccess());
  } catch (err) {
    yield handleError(err);
    const message = err.message?.split(']').pop().trim();

    yield put(authSlice.actions.authError(message));
  }
}

export default function* authSaga() {
  yield takeLatest(authSlice.actions.authLoginSocial.type, handleSocialLogin);
  yield takeLatest(authSlice.actions.authLogin.type, handleLogin);
  yield takeLatest(authSlice.actions.registerUser.type, handleRegistration);
  yield takeLatest(authSlice.actions.authLoginLink.type, handleLinkAccount);
}
