//Kinda hackish, but gets the job done to start
export const styles = `
<style>
:root {
    --primary-color: rgb(52, 69, 104);
    --light-gray: #ddd;
    --dark-gray: #444;
    --primary-color-opacity: rgba(52, 69, 104, .5);
}

@page {
    @bottom-left {
        content: counter(page) "/"counter(pages);
    }
}

body {
    padding: 25;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.border-top {
    border-top: 1px solid var(--primary-color-opacity);
}

.border-top {
    border-bottom: 1px solid var(--primary-color-opacity);
}

    p {
        margin: 0;
    }

    table {
        border-spacing: 0px;
    }

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .container {
        display: flex;
    }

    .container.column {
        flex-direction: column;
    }

    .center {
        justify-content: center;
        align-items: center;
    }

    .primary-text {
      color: var(--primary-color);
    }

    .start {
        align-items: flex-start;
    }
    .flex-1 {
        flex: 1;
    }

    .flex-tenth {
        flex: .1;
    }

    .space-between {
        justify-content: space-between;
    }

    tbody tr:nth-of-type(even) {
        background-color: #eee;
        border: none;
    }

    a {
        color: var(--primary-color);
    }

    h3 {
        color: var(--primary-color);
        margin-bottom: 10px;
    }

    h1.logo {
        color: var(--primary-color);
        font-family: AvenirNext-Regular;
        text-transform: uppercase;
        font-weight: 600;

    }

    h1.logo .suffix {
        padding-left: 5px;
        font-weight: 200;
    }

    td {
        padding: 8 5;
    }

    td:not(:last-of-type) {
        border-right: 1px solid #eee;
    }

    .rounded {
        border-radius: 8px;
    }

    .section {
        border: 1px solid var(--light-gray);
        /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, .1); */
    }

    .bold {
      font-weight: 600;
    }

    .padding-10 {
        padding: 10;
    }

    .width-50 {
        width: 50%;
    }

    .width-33 {
        width: 33%;
    }

    .no-top-margin {
        margin-top: 0;
    }

    .full-width {
        width: 100%;
    }

    .divider {
        border-top: 1px solid var(--light-gray);
    }
</style>
`;
