import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {PredictionsSection} from '../deal-analyzer/predictions';
import {View, Platform} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {TextNumeric, Text, TextNumericProps} from '@houseque/components';
import {ListItem} from 'react-native-elements';
import Modal from 'react-native-modalbox';
import {SectionHeader} from './SectionHeader';
import {useProjectionsOverTime} from './useOverTimeProjections';
import {useYears} from './useYears';
import {usePredictions} from '@houseque/core/deals/selectors/usePredictions';

type TextProps = React.ComponentProps<typeof Text>;
const style = {
  cell: {
    padding: 10,
    paddingVertical: 8,
  },
  modal: {
    marginTop: Platform.OS === 'ios' ? '40%' : '20%',
    padding: 30,
    // justifyContent: 'flex-start',
  },
};

export const PropertyProjections = () => {
  const visibleYears = useYears();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const projections = usePredictions();
  const projectionData = useProjectionsOverTime();
  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const visibleProjection = useMemo(() => {
    return projectionData.filter(projection => {
      return visibleYears[projection.period - 1];
    });
  }, [visibleYears, projectionData]);

  return (
    <View>
      <SectionHeader>Projections</SectionHeader>
      <ListItem
        chevron
        onPress={openModal}
        title={
          <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <View>
              <Text variant={'caption2'}>Property Value</Text>
              <TextNumeric
                type={'percent'}
                value={projections.periodicPropertyAppraisal}
              />
            </View>
            <View>
              <Text variant={'caption2'}>Income</Text>
              <TextNumeric
                type={'percent'}
                value={projections.periodicRentVariance}
              />
            </View>
            <View>
              <Text variant={'caption2'}>Expenses</Text>
              <TextNumeric
                type={'percent'}
                value={projections.periodicExpenseAppreciation}
              />
            </View>
            <View>
              <Text variant={'caption2'}>Selling Costs</Text>
              <TextNumeric
                type={'percent'}
                value={projections.eventualSellingCosts}
              />
            </View>
          </View>
        }
      />

      <View
        style={{
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: '#eee',
          backgroundColor: '#fff',
          marginTop: 10,
          flexDirection: 'row',
          flex: 1,
          justifyContent: 'space-evenly',
        }}>
        <View>
          <RowHeader>Year</RowHeader>
          <RowHeader>Cash Flow to date</RowHeader>
          <RowHeader>Loan Balance</RowHeader>
          <RowHeader>Property Value</RowHeader>
          <RowHeader>Profits if Sold</RowHeader>
        </View>
        <ScrollView horizontal>
          {visibleProjection.map((projection, i) => {
            return (
              <View key={i} style={{flex: 1}}>
                <Text style={[style.cell, {backgroundColor: '#fff'}]}>
                  {projection.period}
                </Text>
                <RowCell value={projection.cashflow} />
                <RowCell value={projection.currentBalance} />
                <RowCell value={projection.propertyValue} />
                <RowCell value={projection.profits} />
              </View>
            );
          })}
        </ScrollView>
      </View>
      <Modal
        style={style.modal}
        coverScreen
        onClosed={closeModal}
        isOpen={isModalOpen}
        useNativeDriver={true}>
        <PredictionsSection />
      </Modal>
    </View>
  );
};

const RowHeader = (props: TextProps) => {
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: 'transparent',
        borderRightColor: '#ddd',
      }}>
      <Text
        variant={'caption2'}
        style={[style.cell, {paddingVertical: 10.5}]}
        {...props}>
        {props.children}
      </Text>
    </View>
  );
};

const RowCell = ({type = 'currency', ...props}: Partial<TextNumericProps>) => {
  return (
    <View
      style={{
        borderColor: 'transparent',
        borderWidth: 1,
        borderTopColor: '#ddd',
      }}>
      <TextNumeric
        style={[style.cell]}
        type={type}
        {...(props as TextNumericProps)}>
        {props.children}
      </TextNumeric>
    </View>
  );
};
