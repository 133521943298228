import {useMemo} from 'react';
import {DealState} from '../deal';

type DealType = 'fixhold' | 'rental' | 'flip';

export const useDealType = (deal: DealState): DealType => {
  return useMemo(() => {
    const {rehab, financing} = deal;
    const [mortgage] =
      financing?.scenarios[financing.currentScenarioIndex].value?.loans ?? [];
    const {duration, arv} =
      rehab?.scenarios[rehab.currentScenarioIndex].value ?? {};
    if (mortgage?.refinance?.refinanceAfter < 24) {
      return 'fixhold';
    } else if (duration && arv) {
      return 'flip';
    }
    return 'rental';
  }, [deal.rehab, deal.financing]);
};
