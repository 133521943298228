import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';

export const useComputedFinancing = () => {
  const currentState = useSelector(
    useCallback((store: HouseQueAppState) => {
      return store.deals.selectedDealComputedFinancials;
    }, []),
  );

  return useMemo(() => {
    return currentState;
  }, [currentState]);
};
