import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {SupportedLogins} from './services/socialLoginService';
import authSlice from './slice';

export const useSocialLogin = () => {
  const dispatch = useDispatch();
  return useCallback(
    (type: SupportedLogins) => {
      dispatch(authSlice.actions.authLoginSocial(type));
    },
    [dispatch],
  );
};
