import React, {useCallback} from 'react';
import {Icon} from './Icon';
import {useNavigation} from '@react-navigation/core';
import {Platform} from 'react-native';

const visible = Platform.OS === 'ios';

export const ModalClose = () => {
  const navigation = useNavigation();
  const goBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    }
    const {index, routeNames, routes} = navigation.dangerouslyGetState();
    const current = routes[index];
    const currentIndex = routeNames.indexOf(current.name);
    const parent = Math.max(currentIndex - 1, 0);

    navigation.navigate(routeNames[parent]);
  }, []);
  if (!visible) {
    return null;
  }
  return <Icon size={30} icon={'close'} onPress={goBack} />;
};
