import React, {useCallback} from 'react';
import {useNavigation} from '@react-navigation/native';
import {Link} from '@houseque/components';
import {StyleSheet} from 'react-native';
import {TutorialHighlight} from '@houseque/core/tutorial';

const styles = StyleSheet.create({
  container: {
    flex: 3,
    flexBasis: 70,
  },
});

export const ViewProjections = () => {
  const {navigate} = useNavigation();
  const goToReport = useCallback(() => {
    navigate('Report');
  }, [navigate]);

  return (
    <TutorialHighlight
      emphasisScale={1.1}
      style={styles.container}
      stepId={'projections'}
      onExit={state => {
        if (state.direction === 'forward') {
          goToReport();
        }
      }}>
      <Link onPress={goToReport} title={'Projections'} />
    </TutorialHighlight>
  );
};
