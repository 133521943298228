import {gql} from '@apollo/client';

export const DealDetailsFragment = gql`
  fragment DealDetails on Deal {
    id
    name
    description
    state
    property {
      address
      sqFt
      location {
        lat
        long
        streetNumber
        route
        county
        city
        state
        postalCode
        country
      }
      # details {
      # 	address
      # 	sqFt
      # 	coordinates {
      # 		lat
      # 		long
      # 	}
      # }
    }
    rehab {
      currentScenarioIndex
      scenarios {
        value {
          rehabCosts
          duration
          arv
        }
        name
      }
    }
    cashflow {
      currentScenarioIndex
      scenarios {
        name
        value {
          income {
            amountType
            amount
            tag
          }
          expenses {
            amountType
            amount
            tag
          }
        }
      }
    }
    financing {
      currentScenarioIndex
      scenarios {
        name
        value {
          price
          closingCosts
          loans {
            rateType
            armType
            paymentFrequency
            interestOnly
            term
            downPayment
            rate
            amount
            financeClosingCosts
            borrowAgainstArv
            refinance {
              rateType
              armType
              amount
              paymentFrequency
              interestOnly
              closingCosts
              term
              downPayment
              rate
              financeClosingCosts
              refinanceAfter
              amount
            }
          }
        }
      }
    }
    predictions {
      currentScenarioIndex
      scenarios {
        name
        value {
          periodicRentVariance
          periodicPropertyAppraisal
          eventualSellingCosts
          periodicExpenseAppreciation
        }
      }
    }
    timestamp
    createdBy {
      id
    }
  }
`;
