import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {removeDeal} from './actions';

function noop() {
  //empty :)
}

type Callback = (params: any) => void;

export const useRemoveDeal = () => {
  const dispatch = useDispatch();
  return useCallback(
    (dealId: string, onSuccess: Callback = noop, onError: Callback = noop) => {
      dispatch(
        removeDeal({
          dealId,
          onSuccess,
          onError,
        }),
      );
    },
    [dispatch],
  );
};
