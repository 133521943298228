import {useCallback} from 'react';
import {InvestmentTargets} from './types';
import {exceptionCaughtAction} from '@houseque/core/application/exceptions';
import {useDispatch} from 'react-redux';
import {preferencesService} from './service';

export const useSaveInvestmentTargets = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (investmentTargets: InvestmentTargets) => {
      try {
        await preferencesService.saveInvestmentTargets(investmentTargets);
      } catch (err) {
        dispatch(exceptionCaughtAction(err));
        throw err;
      }
    },
    [dispatch],
  );
};
