import {ScenariosCategory} from '@houseque/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type ScenariosState = {
  editing: boolean;
  category?: ScenariosCategory;
};

const initialState: ScenariosState = {
  editing: false,
  category: undefined,
};

const slice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    stopEdit: state => {
      state.editing = false;
      state.category = undefined;
    },
    editScenarioCategory: (state, action: PayloadAction<ScenariosCategory>) => {
      state.editing = true;
      state.category = action.payload;
    },
  },
});

export const {stopEdit, editScenarioCategory} = slice.actions;
export default slice.reducer;
