import {IndividualPurchaseState, Refinance, Mortgage} from '@houseque/types';
import {ClosingCosts, ComputedFinanceState} from './types';
import {calculatePayment} from '@houseque/core/calculations';
//Split these up
const defaultLoan = {
  amount: undefined,
  closingCosts: 0,
  financeClosingCosts: false,
  downPayment: 1,
  refinance: {} as Refinance, // eslint-disable-line
};

export const getOutOfPocket = (
  purchase: IndividualPurchaseState,
  rehab: RehabStateInstance,
  loanAmount: number,
  closingCosts: ClosingCosts[],
) => {
  let totalClosings = 0;
  // Take the total closings, whether or not it was financed in the original loan, it either
  // came out of pocket, or it will be paid back with the second loan.
  for (const closing of closingCosts) {
    totalClosings += closing.amount;
  }

  const expenses =
    (purchase.price ?? 0) +
    totalClosings +
    (rehab.holdingCosts ?? 0) +
    (rehab.rehabCosts ?? 0);
  return expenses - loanAmount;
};

export const getFullLoanAmount = (
  purchase: IndividualPurchaseState,
  closingCosts: ClosingCosts,
  loan: Partial<Refinance> = defaultLoan,
  arv: number,
) => {
  let additionalCosts = 0;
  if (loan?.financeClosingCosts) {
    additionalCosts = closingCosts.financed;
  }
  const purchasePrice = loan.borrowAgainstArv ? arv : purchase.price;
  return purchasePrice! * (1 - (loan.downPayment ?? 0)) + additionalCosts;
};

export const getClosingCosts = (
  purchase: IndividualPurchaseState,
  mortgage: Partial<Mortgage> = {
    closingCosts: 0,
    financeClosingCosts: false,
    downPayment: 1,
  },
): ClosingCosts => {
  const costs = purchase.closingCosts ?? mortgage.closingCosts ?? 0;
  return {
    financed: mortgage.financeClosingCosts ? costs : 0,
    outOfPocket: +(mortgage.financeClosingCosts ? 0 : costs),
    amount: costs,
  };
};

export const getMortgagePayment = (
  loanAmount: number,
  loan: Partial<Mortgage> = {rate: 0, term: 0},
) => {
  return calculatePayment(
    loan.rate,
    (loan.term ?? 0) * 12,
    loanAmount,
    loan.interestOnly,
  );
};

export const computeFinanceState = (
  purchaseState: IndividualPurchaseState,
  rehabState: RehabStateInstance,
) => {
  const state: ComputedFinanceState = {
    loans: [],
  };

  //Base state, without loans factored in
  state.closingCosts = {
    amount: purchaseState.closingCosts,
    outOfPocket: purchaseState.closingCosts,
  };
  state.outOfPocket = getOutOfPocket(purchaseState, rehabState, 0, [
    {amount: purchaseState.closingCosts},
  ]);
  //Muuuuuch cleaner
  const [loan] = purchaseState.loans;
  //Build accumulative closing for deeper loan out of pocket
  const closings = [];
  if (loan) {
    const mortgageClosingCosts = getClosingCosts(purchaseState, loan);
    closings.push(mortgageClosingCosts);
    const firstLoanAmount = getFullLoanAmount(
      purchaseState,
      mortgageClosingCosts,
      loan,
      rehabState?.arv,
    );
    const payment = getMortgagePayment(firstLoanAmount, loan);
    state.loans.push({
      payment: payment,
      closingCosts: mortgageClosingCosts,
      loanAmount: firstLoanAmount,
      outOfPocket: getOutOfPocket(
        purchaseState,
        rehabState,
        firstLoanAmount,
        closings,
      ),
    });
    if (loan.refinance) {
      const {closingCosts, ...withoutClosingCosts} = purchaseState;
      const refiClosing = getClosingCosts(withoutClosingCosts, loan.refinance);
      closings.push(refiClosing);
      const refiLoanAmount = getFullLoanAmount(
        purchaseState,
        refiClosing,
        loan.refinance,
        rehabState?.arv,
      );
      const refiPayment = getMortgagePayment(refiLoanAmount, loan.refinance);
      state.loans.push({
        payment: refiPayment,
        closingCosts: refiClosing,
        loanAmount: refiLoanAmount,
        outOfPocket: getOutOfPocket(
          purchaseState,
          rehabState,
          refiLoanAmount,
          closings,
        ),
      });
    }
  }
  return state;
};
