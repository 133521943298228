import {useMemo} from 'react';
import {useCashflow} from '../useCashflow';
import {usePredictions} from '../usePredictions';
import {useRehab} from '../useRehab';
import {compound} from '@houseque/core/calculations';

/**
 * Project out 10 years by default
 */
const DEFAULT_INTERVALS = 120;

/**
 * This returns an anticipated cashflow stream, accounting for
 * changes in rent, and penalties associated with rehab.
 * @param intervals How many intervals to project, this is generally measured in months.
 * @default {120} eg 10 years
 */
export const useActualCashflowStream = (intervals = DEFAULT_INTERVALS) => {
  const cashflow = useCashflow();
  const {
    periodicRentVariance = 0,
    periodicExpenseAppreciation = 0,
    holdFor = intervals,
  } = usePredictions();
  const {duration = 0, vacancyPenalty = 1} = useRehab();
  return useMemo(() => {
    const {income, expenses} = cashflow;
    const stream = new Array(holdFor).fill(0).map((_, index) => {
      const isDuringRehab = duration > index;
      const rehabVacancyPenalty = 1 - (isDuringRehab ? vacancyPenalty : 0);

      const period = Math.floor(index / 12);
      const rentEntry = income.find(item => item.tag === 'rent') || {
        amount: 0,
      };
      const additionalIncome = income
        .filter(item => item !== rentEntry)
        .reduce((sum, item) => {
          sum += item.amount;
          return sum;
        }, 0);
      const rent = compound(
        rentEntry?.amount * rehabVacancyPenalty,
        period,
        periodicRentVariance as number,
      );
      const totalExpenses = expenses.reduce((sum, item) => {
        if (item.amountType === 'percent') {
          sum += item.amount * rent;
        } else {
          sum += item.amount;
        }

        return sum;
      }, 0);

      return (
        rent +
        additionalIncome -
        compound(totalExpenses, period, periodicExpenseAppreciation as number)
      );
    });
    return stream;
  }, [
    duration,
    vacancyPenalty,
    periodicRentVariance,
    periodicExpenseAppreciation,
    cashflow,
    holdFor,
  ]);
};
