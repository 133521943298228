import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormik} from 'formik';
import {SafeAreaView} from 'react-native';
import {
  Button,
  TextInput,
  Text,
  Box,
  DismissKeyboardView,
  Switch,
  Icon,
} from '../../../components';
import {Group} from './types';
import {useCreateGroup} from './useCreateGroup';
import {ActivityIndicator, Chip} from 'react-native-paper';
import {useNavigation} from '@react-navigation/core';
import {useUserDeals} from '../../../core/deals/selectors';
import {Deal} from '@houseque/types';
import {useAddDealsToGroup} from './useAddDealsToGroup';
import {ScrollView} from 'react-native-gesture-handler';

export const NewGroupScreen = () => {
  const createGroup = useCreateGroup();
  const addDealsToGroup = useAddDealsToGroup();
  const navigation = useNavigation();
  const [selectedDeals, setSelectedDeals] = useState({});
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(undefined);
  const [isPublic, setIsPublic] = useState(true);
  const [accessLabel, setAccessLabel] = useState('');

  const {values, errors, setFieldValue, submitForm} = useFormik<Group>({
    initialValues: {
      access: 'public',
    },
    validate: group => {
      const errors = {};
      if (!group.displayName) {
        errors['name'] = 'Group name required';
      }
      return errors;
    },
    onSubmit: async data => {
      setSaving(true);
      try {
        const groupId = await createGroup(data);
        addDealsToGroup(Object.values(selectedDeals), groupId);
        navigation.goBack();
      } catch (err) {
        setSaveError(err);
      }
      setSaving(false);
    },
  });

  useEffect(() => {
    if (isPublic) {
      setFieldValue('access', 'public');
      setAccessLabel('This deal will be public and searchable by all users');
    } else {
      setFieldValue('access', 'private');
      setAccessLabel(
        'This deal will be private, only users you invite can join',
      );
    }
  }, [isPublic, setFieldValue, setAccessLabel]);

  const onSelectDeal = useCallback(
    (deal: Deal) => {
      const toSet = {...selectedDeals};
      if (toSet[deal.id] ?? false) {
        delete toSet[deal.id];
      } else {
        toSet[deal.id] = deal;
      }
      setSelectedDeals(toSet);
    },
    [selectedDeals, setSelectedDeals],
  );

  const setGroupName = useCallback(
    value => {
      setFieldValue('displayName', value);
    },
    [setFieldValue],
  );

  const setGroupDescription = useCallback(
    value => {
      setFieldValue('description', value);
    },
    [setFieldValue],
  );

  return (
    <DismissKeyboardView>
      <SafeAreaView style={{flex: 1}}>
        <Box flex={1} marginVertical={'l'} margin={'m'}>
          <Text variant={'largeTitle'}>New Group</Text>
          <Box marginVertical={'m'}>
            <TextInput
              label={'Group Name *'}
              onChangeText={setGroupName}
              value={values.displayName}
            />
          </Box>
          <TextInput
            label={'Group Description'}
            onChangeText={setGroupDescription}
            value={values.description}
          />
          <AvailableDeals
            selections={selectedDeals}
            onPressDeal={onSelectDeal}
          />
          <Switch
            onValueChange={setIsPublic}
            value={isPublic}
            label={'Public group access?'}
          />
          <Text variant={'caption2'}>{accessLabel}</Text>

          <Box flex={1} />
          <Text paddingBottom={'m'} variant={'callout'} color={'grey1'}>
            {errors.name}
          </Text>
          <Button
            disabled={!!errors.name}
            loading={saving}
            title={`Create ${isPublic ? 'Public' : 'Privage'} Group`}
            onPress={submitForm}
          />
        </Box>
      </SafeAreaView>
    </DismissKeyboardView>
  );
};

const AvailableDeals = ({
  selections = {},
  onPressDeal,
}: {
  selections: {[index: string]: Deal};
  onPressDeal: (deal: Deal) => void;
}) => {
  const selectedText = useMemo(() => {
    const totalSelectedProperties = Object.keys(selections).filter(Boolean)
      .length;
    if (!totalSelectedProperties) {
      return `Select a property from below to share with the group.`;
    }
    const prefixText = `${totalSelectedProperties} propert${
      totalSelectedProperties === 1 ? 'y' : 'ies'
    }`;
    return `${prefixText} will be shared when you Create the Group`;
  }, [selections]);
  const {deals, loading} = useUserDeals();
  if (loading) {
    return <ActivityIndicator />;
  }
  if (deals?.length < 1) {
    return <Text>No deals to add, create and save one.</Text>;
  }
  return (
    <>
      <Text paddingVertical={'m'} variant={'subhead'}>
        Available Deals
      </Text>
      <Text variant={'caption1'}>{selectedText}</Text>
      <Box paddingVertical={'m'} flexDirection={'row'}>
        <ScrollView horizontal>
          {deals?.map(deal => {
            const isSelected = !!selections[deal.id];
            return (
              <Chip
                selected={isSelected}
                icon={isSelected ? 'check' : undefined}
                onPress={() => onPressDeal?.(deal)}>
                {deal.name}
              </Chip>
            );
          })}
        </ScrollView>
      </Box>
    </>
  );
};
