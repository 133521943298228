import {Tutorial} from 'react-native-onboard-tutorial';

export const tutorial: Tutorial = {
  id: 'rentalanalysis',
  active: false,
  steps: [
    {
      id: 'address',
      text: 'Start typing an address, and select a result',
    },
    {
      id: 'lookup',
      text:
        'View the property on a map, or\n check out what we could find about the property!',
      showOutlet: true,
    },
    {
      id: 'name',
      text: 'Add a name that you can identify',
    },
    {
      id: 'purchase',
      text: 'Add details about the purchase here',
    },
    {
      id: 'estimatepane',
      text: 'Watch the values above to see financial impact of options.',
      showOutlet: true,
    },
    {
      id: 'scenarios',
      text:
        'Scenarios allow you to quickly toggle options\n Think loan terms, down payments, etc.',
      type: 'highlight',
      showOutlet: true,
    },
    {
      id: 'cashflow',
      text:
        'This is where you can adjust cashflow details\nTap either section to adjust values',
    },
    {
      id: 'rehab',
      text: 'Add values here related to any\nwork needed for the property',
    },
    {
      id: 'projections',
      text: 'View details about the investment over time',
      type: 'highlight',
      showOutlet: true,
    },
    {
      id: 'sharing',
      text: 'Share the details!\nShare as a link, or PDF.',
      type: 'highlight',
      showOutlet: true,
    },
    {
      id: 'save',
      text: `Be sure to save when you're done\nIf you forgot we'll remind you!`,
      type: 'highlight',
      showOutlet: true,
    },
  ],
  currentStep: 0,
};
