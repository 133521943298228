import {useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import {GetPropertyInfo} from './GetPropertyInfo';

export const usePropertyDetails = (parcelId: string) => {
  const [getPropertyDetails, queryState] = useLazyQuery<
    any,
    {parcelId: string}
  >(GetPropertyInfo, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });
  useEffect(() => {
    getPropertyDetails({
      variables: {
        parcelId,
      },
    });
  }, [parcelId]);
  return queryState;
};
