import React from 'react';
import {StyleProp, TouchableOpacity, ViewStyle} from 'react-native';
import {TextNumeric, Text} from '@houseque/components';
import {useShowExplanation} from '@houseque/core/explanations';
import {Explanations} from '@houseque/core/explanations/types';

type ValueType = 'percent' | 'currency' | 'decimal';

interface EstimatePaneProps {
  label: string;
  value: number;
  valueType?: ValueType;
  explanation?: Explanations;
  /**
   * Defaults to '-'
   */
  missingText?: string;
  style?: StyleProp<ViewStyle>;
}

const invalidValue = (value: any) => {
  const valueIsFalsy = !value;
  const isInfinity = Math.abs(value) === Infinity;
  return (value !== 0 && valueIsFalsy) || isInfinity;
};

export const EstimatePane = ({
  label,
  value,
  valueType = 'decimal',
  missingText = '-',

  ...props
}: EstimatePaneProps) => {
  let Component;
  const showExplanation = useShowExplanation(props.explanation);
  if (invalidValue(value)) {
    Component = (
      <Text color={'primary'} variant={'caption1'}>
        {missingText}
      </Text>
    );
  } else {
    Component = (
      <TextNumeric type={valueType} value={value} variant={'callout'} />
    );
  }
  return (
    <TouchableOpacity
      disabled={!showExplanation}
      onPress={showExplanation}
      {...props}
      style={[props.style]}>
      <Text style={{maxWidth: 65}} variant={'caption2'} color={'grey1'}>
        {label}
      </Text>
      {Component}
    </TouchableOpacity>
  );
};
