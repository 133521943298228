import React, {useState} from 'react';

import {useRegister} from '../authentication';
import {validEmail} from '../validations';

import {useFormik} from 'formik';
import {
  Link,
  Text,
  TextInput,
  Button,
  Box,
  Icon,
  KeyboardAvoidingView,
  DismissKeyboardView,
} from '../../components';
import {useAuthenticationState} from '../authentication';
import {SocialLogins} from '../SocialLogins';
import {SafeAreaView} from 'react-native-safe-area-context';

const minPasswordLength = 2;
const passwordHint = `At least ${minPasswordLength} characters.`;
const emailHint = `Valid email address required`;

const validations: ValidationFunctions = {
  username: (email = '') => {
    const patternMatch = email.match(validEmail);
    return !!email && !!patternMatch;
  },
  password: (password: string) =>
    !!password && password.length >= minPasswordLength,
  name: (data?: string) => true,
};

const DEFAULT_FORM = {
  username: '',
  password: '',
  name: '',
};

export const RegistrationScreen = () => {
  const [isEmailSignup, setIsEmailSignup] = useState(false);
  const [hasFocus, setHasFocus] = useState('');
  const authState = useAuthenticationState();
  const register = useRegister();
  const {submitForm, setFieldValue, errors, isValid} = useFormik({
    initialValues: DEFAULT_FORM,
    isInitialValid: false,
    validate: values => {
      const username = !validations.username(values.username) && emailHint;
      const password = !validations.password(values.password) && passwordHint;
      if (username || password) {
        return {
          username,
          password,
        };
      }
    },
    onSubmit: formValues => {
      const {username, password, name} = formValues;
      register(username, password, name);
    },
  });

  const onBlur = () => setHasFocus('');

  const setField = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  if (!isEmailSignup) {
    return (
      <SafeAreaView style={{flex: 1}}>
        <Box marginHorizontal={'m'} flex={1} justifyContent={'center'}>
          <Button
            title={'Sign up with Email'}
            onPress={() => setIsEmailSignup(true)}
          />
          <SocialLogins authState={authState} />
        </Box>
      </SafeAreaView>
    );
  }

  return (
    <DismissKeyboardView>
      <KeyboardAvoidingView
        behavior={'padding'}
        style={{flex: 1, justifyContent: 'center'}}>
        <Box flexDirection={'row'} alignItems={'center'}>
          <Icon color={'anchorblue'} icon={'chevron-left'} />
          <Link
            style={{marginLeft: -8}}
            title={'Change registration method'}
            onPress={() => setIsEmailSignup(false)}
          />
        </Box>
        <Box margin={'m'}>
          <TextInput onChangeText={setField('name')} label={'Full Name'} />
          <TextInput
            onChangeText={setField('username')}
            keyboardType={'email-address'}
            autoCorrect={false}
            autoCapitalize={'none'}
            onBlur={onBlur}
            onFocus={() => setHasFocus('username')}
            label={'Email'}
          />
          <TextInput
            onChangeText={setField('password')}
            onBlur={onBlur}
            onFocus={() => setHasFocus('password')}
            secureTextEntry
            label={'Password'}
          />
          {authState.errorMessage && <Error error={authState.errorMessage} />}
          <Box marginVertical={'s'}>
            <Button
              rounded
              onPress={submitForm}
              loading={authState.inFlight}
              disabled={!isValid}
              title={authState.inFlight ? 'Registering...' : 'Register'}
            />
          </Box>
        </Box>
      </KeyboardAvoidingView>
    </DismissKeyboardView>
  );
};

const Error = (props: {error?: string}) => {
  if (!props.error) return null;
  return <Text>{props.error}</Text>;
};
