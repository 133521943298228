import {useTheme} from '@shopify/restyle';
import React from 'react';
import {TutorialControls as OTTC} from 'react-native-onboard-tutorial';
import {Theme} from '../../../../core/theme/restyle';

export const TutorialControls = () => {
  const theme = useTheme<Theme>();
  return (
    <OTTC
      containerStyle={{backgroundColor: theme.colors.light}}
      nextText={'Continue Tutorial'}
      nextStyle={{
        flex: 2.5,
        backgroundColor: theme.colors.primary,
        borderRadius: 10,
        marginVertical: 5,
      }}
      nextTextProps={{
        style: [theme.textVariants.callout, {color: theme.colors.light}],
      }}
    />
  );
};
