import {useEffect, useState} from 'react';
import {InvestmentTargets} from './types';

import {preferencesService} from './service';

export const useInvestmentTargets = () => {
  const [investmentTarget, setInvestmentTargets] = useState<
    InvestmentTargets | undefined
  >(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  useEffect(() => {
    const unsubscribe = preferencesService.investmentTargets.onSnapshot(
      data => {
        setInvestmentTargets(data.data());
      },
      setError,
    );
    return unsubscribe;
  }, []);
  return {data: investmentTarget, error};
};
