import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {displayExplanation} from './slice';
import {Explanations} from './types';

export const useShowExplanation = (which: Explanations) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    const action = displayExplanation(which);
    dispatch(action);
  }, [which, dispatch]);
};
