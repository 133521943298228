import React, {useCallback, useEffect, useState} from 'react';
import {Box, TextInput} from '@houseque/components';
import {FlatList} from 'react-native';
import {Selection} from './Selection';

const termOptions = ['30', '15', '10', '20'];

interface DownPaymentSelectorProps {
  onSelect: (downpayment: number) => void;
  selected?: number;
}

const displayTerm = (term: string) => `${term} yr`;

export const SelectTerm = ({selected, onSelect}: DownPaymentSelectorProps) => {
  const [selectedIndex, setSelectedIndex] = useState(
    termOptions.indexOf(selected + ''),
  );
  useEffect(() => {
    setSelectedIndex(termOptions.indexOf(selected + ''));
  }, [selected]);

  const onPress = useCallback(
    pressedIndex => {
      const value = termOptions[pressedIndex];
      onSelect(+value);
    },
    [onSelect],
  );

  const onChangeText = useCallback(
    text => {
      onSelect(+text);
    },
    [onSelect],
  );

  return (
    <>
      <TextInput
        keyboardType={'number-pad'}
        type={'text'}
        label={'Term'}
        explanation={'term'}
        onChangeText={onChangeText}
        placeholder={'Enter or select term'}
        value={selectedIndex > -1 ? selected + '' : undefined}
      />
      <Box marginRight={'-m'} marginVertical={'m'}>
        <FlatList
          horizontal
          data={termOptions}
          keyExtractor={item => item}
          renderItem={({item, index}) => {
            return (
              <Selection
                title={displayTerm(item)}
                selected={index === selectedIndex}
                onPress={() => onPress(index)}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
