import {ScenariosCategory, ScenarioState, Scenario} from '@houseque/types';
import {AnyAction} from 'redux';

/**
 * This allows handling of all scenario actions in a similar fashion
 * @param name
 * @param state
 * @param action
 */
export function handleScenarioActions(
  name: ScenariosCategory,
  state: ScenarioState<unknown>,
  action: AnyAction,
) {
  const {type, payload} = action;
  switch (type) {
    case `ADD_SCENARIO_${name.toUpperCase()}`:
      const clone = {
        ...state.scenarios[state.currentScenarioIndex],
        name: `${name} Scenario ${state.scenarios.length + 1}`,
      };
      //Set new scenario as the current;
      state.currentScenarioIndex = state.scenarios.length;
      state.scenarios.push(clone);
      break;
    case `RENAME_SCENARIO_${name.toUpperCase()}`:
      state.scenarios[payload.index].name = payload.name;
      break;
    case `CHANGE_CURRENT_SCENARIO_${name.toUpperCase()}`:
      state.currentScenarioIndex = payload;
      break;
    case `REMOVE_SCENARIO_${name.toUpperCase()}`:
      //Can't remove if only one scenario
      if (state.scenarios.length > 1) {
        const currentScenarioIndex =
          payload === state.currentScenarioIndex
            ? payload - 1
            : state.currentScenarioIndex - 1;
        state.currentScenarioIndex = currentScenarioIndex;
        state.scenarios.splice(payload, 1);
      }
      break;
  }
}
/**
 * Useful for handling scenario updates.
 * @param scenario
 * @param action
 */
export function updateScenario<T>(
  scenario: ScenarioState<T>,
  action: (selected: Scenario<T>) => void,
) {
  const currentScenario = scenario.scenarios[scenario.currentScenarioIndex];
  action(currentScenario);
}
