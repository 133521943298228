import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';

export const useSearchResults = () => {
  const {searching, searchResults, searchError} = useSelector(
    (app: HouseQueAppState) => {
      return app.groups;
    },
  );
  return {
    searching,
    searchResults,
    searchError,
  };
};
