import React, {useEffect, useState} from 'react';
import {EstimatePane} from './EstimatePane';

interface CashOnCashReturnProps {
  cashInvested?: number;
  debtService?: number;
  netOperatingIncome?: number;
}

export const CashOnCashReturn = ({
  cashInvested = 1,
  netOperatingIncome = 0,
  debtService = 0,
}: CashOnCashReturnProps) => {
  const [cashOnCash, setCashOnCash] = useState<number | any>(0);
  useEffect(() => {
    if (cashInvested < 0) {
      setCashOnCash(undefined);
    } else {
      setCashOnCash((netOperatingIncome - debtService) / cashInvested);
    }
  }, [cashInvested, netOperatingIncome, debtService]);
  return (
    <EstimatePane
      explanation={'cashoncash'}
      valueType={'percent'}
      label={'Cash on Cash'}
      value={cashOnCash}
    />
  );
};
