import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import authSlice from './slice';

export const useRegister = () => {
  const dispatch = useDispatch();
  return useCallback(
    (email: string, password: string, displayName: string) => {
      dispatch(
        authSlice.actions.registerUser({
          email,
          password,
          displayName,
        }),
      );
    },
    [dispatch],
  );
};
