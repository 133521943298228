import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {useFindParcel} from './useFindParcel';

interface CheckParcelAvailabilityProps {
  children?: (parcelID: string) => any;
}

export const CheckParcelAvailability = ({
  children = () => null,
}: CheckParcelAvailabilityProps) => {
  const currentDeal = useCurrentDeal();
  const parcelInfo = useFindParcel(currentDeal.property);
  const {findParcels} = parcelInfo.data ?? {};
  if (!findParcels?.length) {
    return null;
  }
  return children(findParcels[0].parcelID);
};
