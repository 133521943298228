import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changeIncome} from '../../slice';

export const useUpdateIncome = () => {
  const dispatch = useDispatch();
  const updateIncome = useCallback(
    financingDetails => {
      dispatch(changeIncome(financingDetails));
    },
    [dispatch],
  );
  return updateIncome;
};
