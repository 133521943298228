import React, {useCallback, useState, useRef, useMemo, useEffect} from 'react';
import {StyleSheet, SafeAreaView, RefreshControl, Animated} from 'react-native';
import {useGoToDeal} from './useGoToDeal';
import {useCurrentDeal, useUserDeals} from '@houseque/core/deals/selectors';
import {useLoadUserDeals} from '@houseque/core/deals/actions';

import {Deals} from './Deals';
import {useStyle} from '../../../core/theme';
import {Text, Fab, Box} from '../../../components';
import {ErrorWithRetry} from '../../../components/ErrorWithRetry';
import {Searchbar} from 'react-native-paper';
import {useFilteredDeals} from './useFilteredDeals';
import {Welcome} from './Welcome';
import {useSafeArea} from 'react-native-safe-area-context';
import {UnsavedChanges} from './UnsavedChanges';
import {Deal} from '@houseque/types';
import {DealsLoading} from './DealsLoading';
import {useStartTutorial} from '../../../core/tutorial';
import {OfflineDeals} from './OfflineDeals';

const styles = StyleSheet.create({
  unsavedChanges: {
    position: 'absolute',
    bottom: 10,
    right: 80,
    left: 20,
  },
  searchBar: {borderWidth: 0},
  searchBarContainer: {
    padding: 0,
    paddingHorizontal: 0,
    marginHorizontal: 0,
    paddingBottom: 0,
    paddingTop: 0,
  },
  searchBarInput: {marginLeft: 0},
  dealTitle: {fontWeight: '500'},
  outer: {
    flex: 1,
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  flexGrow: {
    alignItems: 'stretch',
    flexGrow: 1,
  },
});

export const Home = () => {
  const loadUserDeals = useLoadUserDeals();
  useEffect(() => {
    loadUserDeals();
  }, []);

  const area = useSafeArea();
  const {deals, loading} = useUserDeals();
  //todo replace me
  const error = undefined;
  const refetch = useCallback(() => {
    loadUserDeals({
      fetchPolicy: 'network-only',
    });
  }, [loadUserDeals]);
  const [refreshing, setRefreshing] = useState(false);
  const [filterText, setFilterText, filteredDeals] = useFilteredDeals(deals);
  const [headerHeight, setHeaderHeight] = useState(116);
  const animatedValue = useRef(new Animated.Value(0));
  const opacity = animatedValue.current.interpolate({
    inputRange: [0, 25],
    outputRange: [1, 0],
  });

  const startTutorial = useStartTutorial();
  useEffect(() => {
    //Can't really make a distinction here.
    if (loading || error) {
      return;
    }
    if (!deals?.length) {
      startTutorial('rentalanalysis');
    }
  }, [deals, loading, error]);

  const translateY = animatedValue.current.interpolate({
    inputRange: [0, 50, 100, 200],
    outputRange: [0, -40, -50, -50],
  });

  const goToDeal = useGoToDeal();
  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      await refetch!();
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  }, [refetch]);
  const currentDeal = useCurrentDeal();
  const resumeAnalysis = useCallback(() => {
    goToDeal(currentDeal);
  }, [currentDeal, goToDeal]);
  const themedStyles = useStyle(
    ({colors: {primary}, edge: {xlarge, medium}}) => ({
      horizontalPadding: {
        paddingHorizontal: medium,
      },
      verticalPadding: {
        paddingVertical: medium,
      },
      container: {
        paddingVertical: xlarge,
        paddingLeft: xlarge,
      },
      rightPadding: {
        paddingRight: xlarge,
      },
      bottomPadding: {
        paddingBottom: xlarge,
      },
      resumeButton: {
        backgroundColor: primary,
        paddingHorizontal: xlarge,
      },
      addButton: {
        position: 'absolute',
        bottom: xlarge,
        right: xlarge,
      },
      dealListContainer: {
        paddingVertical: headerHeight,
      },
    }),
    [headerHeight],
  );
  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: {height},
      },
    }) => {
      if (headerHeight !== height) {
        setHeaderHeight(height);
      }
    },
    [setHeaderHeight, headerHeight],
  );
  const refreshControl = useMemo(() => {
    return <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />;
  }, [refreshing, onRefresh]);
  const ListEmptyComponent = useMemo(() => {
    if (loading) {
      return <DealsLoading />;
    }
    if (deals.length)
      return (
        <Text style={themedStyles.horizontalPadding}>
          No deals meet this criteria
        </Text>
      );
    return <Welcome />;
  }, [themedStyles.horizontalPadding, loading, deals.length]);
  const keyExtractor = useCallback((deal: Deal) => deal?.id, []);

  if (error) {
    return (
      <ErrorWithRetry
        containerStyle={[themedStyles.container, themedStyles.rightPadding]}
        text={`We aren't able to load your deals, tap to try again`}
        onRetry={refetch!}></ErrorWithRetry>
    );
  }
  return (
    <SafeAreaView style={{flex: 1}}>
      <Deals
        titleStyle={[
          themedStyles.horizontalPadding,
          themedStyles.verticalPadding,
          styles.dealTitle,
        ]}
        keyboardDismissMode={'interactive'}
        keyboardShouldPersistTaps={'handled'}
        scrollEventThrottle={16}
        contentContainerStyle={themedStyles.dealListContainer}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {y: animatedValue.current},
              },
            },
          ],
          {useNativeDriver: true},
        )}
        keyExtractor={keyExtractor}
        refreshControl={refreshControl}
        ListEmptyComponent={ListEmptyComponent}
        deals={filteredDeals}
      />
      <Animated.View
        onLayout={onLayout}
        style={{
          top: area.top,
          position: 'absolute',
          left: 0,
          right: 0,
          transform: [
            {
              translateY,
            },
          ],
        }}>
        <Animated.View style={{opacity}}>
          <Text variant={'largeTitle'} margin={'m'} marginBottom={'s'}>
            Saved Properties
          </Text>
        </Animated.View>
        <Box paddingHorizontal={'m'}>
          <Searchbar
            textAlign={'left'}
            onChangeText={setFilterText}
            value={filterText}
            placeholder={'Filter properties'}
          />
        </Box>
      </Animated.View>

      <Box position={'absolute'} right={0} left={0} bottom={0}>
        <Fab
          avoidKeyboard={false}
          containerStyle={{position: 'relative', alignSelf: 'flex-end'}}
          icon={'plus'}
          onPress={() => goToDeal()}
        />
        <UnsavedChanges onPress={resumeAnalysis} />
      </Box>
      <OfflineDeals />
    </SafeAreaView>
  );
};
