import {call, takeLatest, put} from 'redux-saga/effects';
import ImagePicker, {
  Image as ImageResult,
} from 'react-native-image-crop-picker';
import {stageLocalPhotos} from './slice';
import {launchCameraRoll} from './actions';
import {Alert, Platform} from 'react-native';

function* onLaunchCameraRole(action: ReturnType<typeof launchCameraRoll>) {
  try {
    const selections = yield call(ImagePicker.openPicker, {
      multiple: true,
      // maxFiles: //5,
      includeBase64: true,
      compressImageQuality: 0.3,
      mediaType: 'photo',
    });
    yield put(stageLocalPhotos(selections));
  } catch (err) {
    if (/Cannot access images/.test(err.message)) {
      Alert.alert(
        `Can't access photos`,
        Platform.select({
          ios:
            'To upload photos, please grant us permissions at: \n Settings > Privacy > Photos > HouseQue',
          android:
            'If you would like to proceed, please give HouseQue permissions to access the camera roll in permissions',
        }),
      );
    }
  }
}

export default function* photosSaga() {
  yield takeLatest(launchCameraRoll.type, onLaunchCameraRole);
}
