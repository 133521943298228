import {Box, Text} from '@houseque/components';
import React, {useRef, useState} from 'react';
import {ImageSourcePropType, useWindowDimensions, Image} from 'react-native';
import Carousel, {Pagination} from 'react-native-snap-carousel';

interface Benefit {
  title: string;
  description: string;
  image?: ImageSourcePropType;
}
const benefits: Benefit[] = [
  {
    title: 'Scenarios',
    description: 'Quickly toggle and compare different investment scenarios',
    image: require('./assets/scenarios.gif'),
  },
  {
    title: 'Speed',
    description: 'See investment numbers calculated in real time',
    image: require('./assets/fast.gif'),
  },
  //   {
  //     title: 'Groups',
  //     description: 'Share investment opportunities with others',
  //     image: require('./assets/Presets.gif'),
  //   },
];

export const BenefitsCaraousel = () => {
  const carouselRef = useRef(undefined);
  const {width} = useWindowDimensions();
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <>
      <Carousel
        vertical={false}
        containerCustomStyle={{
          flex: 0.5,
          overflow: 'hidden',
        }}
        ref={carouselRef}
        data={benefits}
        sliderWidth={width}
        itemWidth={width - 40}
        inactiveSlideOpacity={0.7}
        inactiveSlideScale={0.8}
        inactiveSlideShift={2}
        autoplay={true}
        autoplayInterval={6000}
        onScrollIndexChanged={setSelectedIndex}
        renderItem={({item}) => {
          return (
            <Box
              elevation={5}
              backgroundColor={'grey5'}
              shadowColor={'grey0'}
              shadowRadius={3}
              shadowOpacity={0.2}
              //@ts-ignore
              borderRadius={'standard'}
              shadowOffset={{width: 0, height: 0}}
              marginVertical={'s'}
              flexShrink={1}
              flexGrow={0}
              padding={'m'}>
              <Text
                textAlign={'center'}
                color={'grey1'}
                paddingBottom={'m'}
                variant={'body'}>
                {item.description}
              </Text>
              <Box backgroundColor={'warning'} overflow={'hidden'} height={240}>
                <Image
                  style={{
                    flex: 1,
                    height: undefined,
                    width: undefined,
                    resizeMode: 'cover',
                  }}
                  source={item.image}
                />
              </Box>
            </Box>
          );
        }}
      />
      <Pagination
        containerStyle={{paddingVertical: 12}}
        tappableDots
        dotsLength={benefits.length}
        activeDotIndex={selectedIndex}
        carouselRef={carouselRef}
      />
    </>
  );
};
