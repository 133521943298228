import React, {useCallback, useState} from 'react';
import {UserConditional} from '@houseque/components';
import {useCurrentDeal} from '@houseque/core/deals/selectors/useCurrentDeal';
import {HeaderLink} from '@houseque/components/HeaderLink';
import {useSaveDeal} from '@houseque/core/deals/actions/useSaveDeal';

interface SaveDealProps {
  onSave: () => void;
  onError?: () => void;
}

export const SaveDeal = ({
  onSave,
  onError = () => {
    // ignore
  },
}: SaveDealProps) => {
  const saveDeal = useSaveDeal();
  const deal = useCurrentDeal();
  const [saving, setSaving] = useState(false);

  const onPress = useCallback(async () => {
    setSaving(true);
    try {
      await saveDeal(deal);
      onSave();
    } catch (err) {
      onError();
    }
    setSaving(false);
  }, [deal, saveDeal, onSave, onError]);

  const doIOwnTheDeal = useCallback(
    user => {
      if (!deal?.createdBy?.id) {
        return true;
      }
      return deal.createdBy?.id === user.uid;
    },
    [deal],
  );
  return (
    <UserConditional condition={doIOwnTheDeal}>
      <HeaderLink title={'Save'} onPress={onPress} disabled={saving} />
    </UserConditional>
  );
};
