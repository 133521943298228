import React, {useCallback, useMemo, useState} from 'react';
import {ActivityIndicator} from 'react-native';
import {Box, Text, TextNumeric} from '../../../components';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import {useGoToDeal} from './useGoToDeal';
import {Card, TouchableRipple} from 'react-native-paper';
import {useStyle} from '../../../core/theme';
import {DealState} from '../deal-analyzer/deal';
import {Deal} from '@houseque/types';
import {useLogEvent} from '@houseque/core/application/analytics';
import {DealCalculator} from '../../../core/calculations';
import {useDealType} from '../../../core/deals';
import {useRemoveDeal} from '@houseque/core/deals/actions';

interface SavedAnalysisListItemProps {
  analysis: DealState;
  disableSwipe?: boolean;
}

interface AddressProps {
  deal: Deal;
}

const Address = ({deal}: AddressProps) => {
  const {address = 'No Address.'} = deal.property;
  if (!address) return null;
  return <Text variant={'caption1'}>{address}</Text>;
};

export const SavedAnalysisListItem = (props: SavedAnalysisListItemProps) => {
  const {analysis, disableSwipe} = props;
  const logEvent = useLogEvent();
  const [loading, setLoading] = useState(false);

  const goToDeal = useGoToDeal();
  const navigateToDeal = useCallback(() => {
    if (analysis?.local?.sample) {
      logEvent('view_sample', {name: analysis.name});
    }
    goToDeal(analysis);
  }, [analysis, goToDeal, logEvent]);
  const removeDeal = useRemoveDeal();
  const themedStyles = useStyle(({colors: {error}, edge: {medium}}) => ({
    deleteButton: {
      flex: 1,
      width: 100,
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundColor: error,
      paddingRight: medium,
    },
  }));
  const onDelete = useCallback(async () => {
    setLoading(true);
    removeDeal(analysis.id!, undefined, () => setLoading(false));
  }, [analysis.id, removeDeal]);
  return (
    <Swipeable
      rightThreshold={100}
      friction={disableSwipe ? 10000.2 : 1.2}
      renderRightActions={() => {
        return (
          <TouchableOpacity
            disabled={loading}
            style={themedStyles.deleteButton}
            onPress={onDelete}>
            {(loading && <ActivityIndicator color={'white'} />) || (
              <Text variant={'button'} color={'white'}>
                Delete
              </Text>
            )}
          </TouchableOpacity>
        );
      }}>
      <TouchableRipple onPress={navigateToDeal}>
        <Card elevation={5}>
          <Card.Title
            style={{minHeight: 0, paddingTop: 5}}
            title={analysis.name || 'Saved Deal'}
            subtitle={<Address deal={analysis} />}
          />
          <Card.Content>
            <EstimateRow deal={analysis} />
          </Card.Content>
        </Card>
      </TouchableRipple>
    </Swipeable>
  );
};

type Estimate = {
  label: string;
  value: number;
  type: 'currency' | 'percent' | 'decimal';
};

const EstimateRow = ({deal}: {deal: DealState}) => {
  const dealType = useDealType(deal);
  const [calculations] = useState(new DealCalculator(deal));

  const estimates = useMemo(() => {
    const items: Estimate[] = [];
    switch (dealType) {
      case 'flip':
        items.push({
          label: 'ROI',
          value: calculations.returnOnInvestment,
          type: 'percent',
        });
        items.push({
          label: 'Profits',
          value: calculations.flipProfits,
          type: 'currency',
        });
        items.push({
          label: 'Costs',
          value: calculations.outOfPocket,
          type: 'currency',
        });
        break;
      case 'fixhold':
        items.push({
          label: 'Return on Investment',
          value: calculations.returnOnInvestment,
          type: 'percent',
        });
        items.push({
          label: 'Out of Pocket',
          value: calculations.outOfPocket,
          type: 'currency',
        });
        break;
      default:
        items.push({
          label: 'Cash on Cash',
          value: calculations.cashOnCash,
          type: 'percent',
        });
        items.push({
          label: 'Monthly Cashflow',
          value: calculations.monthlyCashflow,
          type: 'currency',
        });
        break;
    }

    return items;
  }, [deal, dealType]);

  return (
    <Box flexDirection={'row'}>
      {estimates.map((estimate, index) => {
        return (
          <Box opacity={0.9} paddingRight={'l'} key={estimate.label}>
            <Text variant={'caption1'}>{estimate.label}</Text>
            <TextNumeric
              variant={'caption1'}
              type={estimate.type}
              value={estimate.value}
            />
          </Box>
        );
      })}
    </Box>
  );
};
