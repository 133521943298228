import {NavigationState} from '@react-navigation/core';
import {useRef} from 'react';
import {useDispatch} from 'react-redux';
import {userNavigation} from './actions';

/**
 * Pulls the navigation route
 * @param navigationState
 */
const getActiveRouteName = (
  navigationState: NavigationState | any,
): string | null => {
  if (!navigationState) {
    return null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.state) {
    return getActiveRouteName(route.state);
  }
  return route.name;
};

export const useDispatchNavigationEvent = () => {
  const dispatch = useDispatch();
  const navigationNameRef = useRef('');
  return (state: NavigationState) => {
    const currentRoute = getActiveRouteName(state);
    if (currentRoute !== navigationNameRef.current && !!currentRoute) {
      dispatch(userNavigation(currentRoute));
    }
    navigationNameRef.current = currentRoute!;
  };
};
