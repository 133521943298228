import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changeProperty} from '../slice';

export const useUpdateProperty = () => {
  const dispatch = useDispatch();
  const updateProperty = useCallback(
    propertyChanges => {
      dispatch(changeProperty(propertyChanges));
    },
    [dispatch],
  );
  return updateProperty;
};
