import React, {useEffect, useCallback, useState} from 'react';
import {useFormik} from 'formik';
import {useRoute, useNavigation, Route} from '@react-navigation/core';
import {Link, ScreenWithKeyboard, Fab, Text, Box} from '../../../components';
import {CashflowOption} from './CashflowOption';
import {
  useCashflow,
  useUpdateExpenses,
  useUpdateIncome,
} from '../deal-analyzer/cashflow';
import {useOptions} from './useOptions';
import {View, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {usePresets} from '../../preferences/usePresets';

interface CashflowModalProps {
  title: string;
  type: 'income' | 'expense';
  cashflow: (Income | Expense)[];
  options: CashflowOptions<IncomeTag | ExpenseTag>[];
  onChange: (cashflow: (Income | Expense)[]) => void;
}

interface Form {
  [index: string]: FormEntry;
}

interface FormEntry {
  form: CashflowOptions<IncomeTag | ExpenseTag>;
  actual?: Income | Expense;
}

interface CashflowRouteProps extends Route<any> {
  params: {options: CashflowModalProps};
}

//Example of code that becomes confusing as hell, pretty sure there's a much more straight forward way.
// Available options
// Add 50% rule option?
export const CashflowScreen = () => {
  const navigation = useNavigation();
  const updateIncome = useUpdateIncome();
  const updateExpenses = useUpdateExpenses();
  const route = useRoute<CashflowRouteProps>();
  const {type, title} = route.params.options;
  navigation.setOptions({
    title,
  });

  const {data: presets = []} = usePresets(
    type === 'expense' ? 'expenses' : undefined,
  );

  const {goBack} = navigation;
  const options = useOptions(type);
  const {income, expenses} = useCashflow();
  const [cashflow] = useState<any[]>(type === 'expense' ? expenses : income);
  const onChange = useCallback(
    selections => {
      switch (type) {
        case 'expense':
          updateExpenses(selections);
          break;
        case 'income':
          updateIncome(selections);
          break;
        default:
          break;
      }
    },
    [type, updateExpenses, updateIncome],
  );

  const onSubmit = useCallback(
    (form: Form) => {
      const formList = Object.entries(form).reduce(
        (current: CashflowEntryBase[], [key, value]: [string, FormEntry]) => {
          if (value.actual && +value.actual.amount) {
            const {tag, amountType, type} = value.actual;
            return [
              ...current,
              {
                tag,
                type,
                amountType,
                amount: +value.actual.amount,
              },
            ];
          }
          return current;
        },
        [],
      );
      onChange(formList as any);
      goBack();
    },
    [goBack, onChange],
  );

  const {submitForm, values, setValues, setFieldValue} = useFormik({
    initialValues: {} as Form,
    onSubmit,
  });

  const setDefaults = useCallback(() => {
    for (const preset of presets) {
      const value = values[preset.subcategory];
      if (!value) {
        continue;
      }
      setFieldValue(preset.subcategory, {
        ...value,
        actual: {
          amountType: value.form.type ?? 'fixed',
          tag: value.form.tag,
          amount: preset.value,
        },
      });
    }
  }, [values, setFieldValue, presets]);

  const onCashflowChange = useCallback(
    (option: CashflowOptions<any>, cashflow?: CashflowEntryBase) => {
      const current = values[option.tag];
      setFieldValue(option.tag, {
        ...current,
        actual: cashflow,
      });
    },
    [values, setFieldValue],
  );

  useEffect(() => {
    const start = options.reduce((prev: any, opt: any) => {
      const actual = cashflow.find(cflow => cflow.tag === opt.tag);
      prev[opt.tag] = {
        form: opt,
        actual,
      };
      return prev;
    }, {} as any);
    setValues(start);
  }, [options, cashflow, setValues]);

  return (
    <ScreenWithKeyboard
      ViewComponent={View}
      scrollEnabled={false}
      keyboardShouldPersistTaps={'handled'}
      contentContainerStyle={{
        flex: 1,
      }}>
      <FlatList
        keyboardDismissMode={'on-drag'}
        keyboardShouldPersistTaps={'handled'}
        data={Object.entries(values)}
        numColumns={2}
        renderItem={({item}) => {
          const [key, entry] = item;
          return (
            <CashflowOption
              key={key}
              onChange={onCashflowChange}
              option={entry.form}
              value={entry.actual}
            />
          );
        }}
      />
      <SafeAreaView>
        {(presets.length && (
          <Link onPress={setDefaults} title={'Use Presets'} />
        )) ||
          undefined}
      </SafeAreaView>
      <Fab icon={'check'} onPress={submitForm} />
    </ScreenWithKeyboard>
  );
};
