import React, {useState, useEffect, useMemo} from 'react';
import {useTheme} from '../core/theme';
import {HouseQueColors} from '../core/theme/houseque.theme';
import {IconButton} from 'react-native-paper';

type IconProps = React.ComponentProps<typeof IconButton>;

export interface HQIconProps {
  name?: string;
  color?: keyof HouseQueColors; // eslint-disable-line
}

export const Icon = ({
  name,
  onPress,
  color,
  icon,
  ...props
}: HQIconProps & IconProps) => {
  const {colors} = useTheme();
  const [colorProps, setColorProps] = useState({color: colors[color!]});
  useEffect(() => {
    setColorProps({color: colors[color!]});
  }, [color, colors]);

  return useMemo(() => {
    return (
      <IconButton
        icon={icon || name}
        color={colorProps.color as string}
        onPress={onPress}
        {...props}
        // {...(colorProps as any)}
      />
    );
  }, [onPress, props, color, name, icon]);
};
