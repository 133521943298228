import {DealState} from '../deal-analyzer/deal';
import sampleFlip from './sample-flip.json';
import sampleRental from './sample-rental.json';

export type SampleType = 'rental' | 'flip' | 'fliphold';

export const useSampleDeal = (type: SampleType = 'rental'): DealState => {
  switch (type) {
    case 'flip':
      return sampleFlip as DealState;
    default:
      //Remove next release
      sampleRental.cashflow.scenarios[0].value.expenses.forEach(expense => {
        delete (expense as Expense).name;
      });
      return sampleRental as DealState;
  }
};
