import {Mortgage} from '@houseque/types';

interface FinacingFields {
  isFinanced?: boolean;
  loanAmount?: number;
  rate?: number;
  closingCosts?: number;
  term?: number;
  downPayment?: number;
}

export const validations = ({rate, closingCosts}: Mortgage) => {
  const errors: {rate?: string} = {};
  if (rate) {
    if (rate >= 1) {
      errors.rate =
        'If you are really paying over 100% for this deal, maybe we should talk?';
    }
  }
};
