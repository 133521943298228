import {useComputedFinancing} from '../useComputedFinancing';

export const useClosingCosts = () => {
  const computedFinancing = useComputedFinancing();
  const [loan] = computedFinancing.loans;
  if (loan) {
    return loan.closingCosts;
  }
  return computedFinancing.closingCosts;
};
