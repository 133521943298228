import React, {useCallback, useState, useEffect} from 'react';
import {Switch, TextInput, Fab, Box} from '@houseque/components';
import {useFormik} from 'formik';
import {validations} from './financing.validations';
import {SelectDownPayment} from './SelectDownPayment';
import {
  useComputedPayment,
  useClosingCosts,
  usePurchaseState,
} from '../../../core/deals/selectors/financing';
import {SelectTerm} from './SelectTerm';
import {SelectLoanRateType} from './SelectRateType';
import {Card} from 'react-native-paper';
import {EstimatePane} from '../deal-analyzer/estimates/component/EstimatePane';
import {useStyle} from '../../../core/theme';
import {BorrowAgainstArv} from './BorrowAgainstArv';
import {Refinance} from '@houseque/types';
import {SelectLoanToValue} from './SelectLoanToValue';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';

interface FinancingFormProps {
  //Currently only refinance
  allowDelayedLoan: boolean;
  loan: Refinance;
  refinanceOnly: boolean;
  onSubmit: (item: Refinance) => void;
}

export const FinancingForm = ({
  allowDelayedLoan,
  loan,
  refinanceOnly,
  onSubmit,
}: FinancingFormProps) => {
  const [mortgage, setMortgage] = useState(loan);
  const [paymentLabel, setPaymentLabel] = useState('');
  const [loanAmount, setLoanAmount] = useState<number | undefined>(undefined);
  const [propertyValue, setPropertyValue] = useState(0);

  const purchase = usePurchaseState();
  const closingCosts = useClosingCosts();
  const rehab = useRehab();

  const {
    values: {
      rate,
      term,
      downPayment,
      armType,
      rateType,
      interestOnly,
      closingCosts: refiClosingCosts,
      financeClosingCosts,
      refinanceAfter,
      borrowAgainstArv,
    },
    setFieldValue,
    submitForm,
  } = useFormik<Refinance>({
    initialValues: mortgage,
    validate: validations,
    onSubmit: values => {
      values.amount = propertyValue! * (1 - downPayment!);
      onSubmit(values);
    },
  });

  useEffect(() => {
    // If it's a refinance, or borrowing against ARV, allow property value to increase.
    if (refinanceOnly || borrowAgainstArv) {
      setPropertyValue(rehab.arv! || purchase.price!);
    } else {
      setPropertyValue(purchase.price ?? 0);
    }
  }, [purchase.price, rehab.arv, refinanceOnly, borrowAgainstArv]);

  useEffect(() => {
    const actualClosing =
      (refinanceOnly ? refiClosingCosts : closingCosts.amount) ?? 0;
    const additionalCharges = financeClosingCosts ? actualClosing : 0;
    const amount = propertyValue! * (1 - downPayment!) + additionalCharges;
    setLoanAmount(amount);
  }, [
    propertyValue,
    downPayment,
    closingCosts.amount,
    financeClosingCosts,
    refiClosingCosts,
  ]);

  const payment = useComputedPayment(
    rate!,
    +term! * 12,
    +loanAmount!,
    interestOnly,
  );

  useEffect(() => {
    const label = interestOnly ? 'Interest payment' : 'P&I payment';
    setPaymentLabel(label);
  }, [interestOnly]);

  const handleChange = useCallback((fieldName: keyof Refinance) => {
    return (value: any) => setFieldValue(fieldName, value);
  }, []);

  const styles = useStyle(({edge: {xlarge, medium}, colors: {grey4}}) => ({
    paddingHorizontal: {
      paddingHorizontal: xlarge,
    },
    footer: {
      borderTopWidth: 1,
      borderTopColor: grey4,
    },
    scrollContainer: {
      padding: 0,
      margin: 0,
    },
    sectionStyle: {
      margin: xlarge,
      borderRadius: 10,
    },
    padTop: {
      paddingTop: medium,
    },
  }));

  return (
    <Box flex={1}>
      <Box paddingHorizontal={'s'}>
        <Card elevation={5}>
          <Card.Content
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            <EstimatePane
              label={'Loan Amount'}
              valueType={'currency'}
              value={loanAmount!}
            />
            <EstimatePane
              label={paymentLabel}
              valueType={'currency'}
              value={payment!}
            />
          </Card.Content>
        </Card>
      </Box>
      <KeyboardAwareScrollView extraScrollHeight={150}>
        <Box marginHorizontal={'m'} paddingVertical={'m'}>
          <TextInput
            label={'Rate'}
            type={'percent'}
            value={mortgage.rate}
            keyboardType={'decimal-pad'}
            placeholder={'Loan Rate'}
            onChangeText={handleChange('rate')}
          />

          <SelectTerm selected={term} onSelect={handleChange('term')} />

          {((borrowAgainstArv || refinanceOnly) && (
            <SelectLoanToValue
              selected={downPayment}
              onSelect={handleChange('downPayment')}
            />
          )) || (
            <SelectDownPayment
              selected={downPayment}
              onSelect={handleChange('downPayment')}
            />
          )}
          {(!refinanceOnly && (
            <BorrowAgainstArv
              borrowingAgainstArv={borrowAgainstArv ?? false}
              onValueChange={handleChange('borrowAgainstArv')}
            />
          )) ||
            null}
        </Box>
        <Box marginHorizontal={'m'} marginBottom={'xxl'} paddingVertical={'m'}>
          <Switch
            label={'Include closing costs in loan?'}
            value={financeClosingCosts}
            onValueChange={handleChange('financeClosingCosts')}
          />
          {(!refinanceOnly && (
            <Switch
              label={'Interest only?'}
              value={interestOnly}
              onValueChange={handleChange('interestOnly')}
            />
          )) ||
            null}

          <SelectLoanRateType
            selectedRateType={rateType}
            onSelectRateType={handleChange('rateType')}
            selectedArmType={armType!}
            onSelectArmType={handleChange('armType')}
          />
          {(allowDelayedLoan && (
            <TextInput
              type={'numeric'}
              keyboardType={'decimal-pad'}
              value={refinanceAfter}
              label={'Refinance after months'}
              placeholder={''}
              onChangeText={handleChange('refinanceAfter')}
            />
          )) ||
            null}
          {refinanceOnly && (
            <TextInput
              type={'currency'}
              keyboardType={'decimal-pad'}
              value={refiClosingCosts}
              explanation={'closingcosts'}
              label={'Closing Costs'}
              onChangeText={handleChange('closingCosts')}
            />
          )}
        </Box>
      </KeyboardAwareScrollView>
      <Fab icon={'check'} onPress={submitForm} />
    </Box>
  );
};
