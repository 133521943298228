import {useCallback} from 'react';
import {HeaderLink} from '@houseque/components';
import React from 'react';
import {useCurrentDeal} from '@houseque/core/deals/selectors/useCurrentDeal';
import {Alert} from 'react-native';
import Share from 'react-native-share';
import {useGenerateHtml} from './pdf/generateHtml';
import RNHTMLtoPDF from 'react-native-html-to-pdf';
import {shareLink} from './shareLink';
import {useLogShare} from '@houseque/core/application/analytics';
import {FirebaseAnalyticsTypes} from '@react-native-firebase/analytics';

//TODO move this elsewhere
const generatePdf = async (html: string) => {
  const file = await RNHTMLtoPDF.convert({
    html,
    fileName: `housequereport-${Date.now()}`,
    bgColor: '#ffffff',
    padding: 20,
  });
  const link = `file://${file.filePath}`;
  return link;
};

export const ShareDeal = () => {
  const logShare = useLogShare();
  const deal = useCurrentDeal();
  const generateHtml = useGenerateHtml();

  const onShare = useCallback(
    async (sharePdf = false) => {
      let url = shareLink(deal);
      const shareEvent: FirebaseAnalyticsTypes.ShareEventParameters = {
        content_type: 'url', // eslint-disable-line @typescript-eslint/camelcase
        item_id: deal?.id ?? 'unsaved', // eslint-disable-line @typescript-eslint/camelcase
        method: sharePdf ? 'file' : 'link', // eslint-disable-line @typescript-eslint/camelcase
      };
      if (sharePdf) {
        shareEvent.content_type = 'pdf'; // eslint-disable-line @typescript-eslint/camelcase
        const html = generateHtml();
        url = await generatePdf(html);
      }
      logShare(shareEvent);
      await Share.open({
        title: 'HouseQue Report',
        url,
      });
    },
    [generateHtml, deal, logShare],
  );

  const shareDeal = useCallback(async () => {
    Alert.alert('How would you like to share?', '', [
      {onPress: () => onShare(true), text: 'Share as PDF'},
      {onPress: () => onShare(), text: 'Share Link'},
      {text: 'Cancel', style: 'cancel'},
    ]);
  }, [deal, onShare]);

  return <HeaderLink title={'Share'} onPress={shareDeal} />;
};
