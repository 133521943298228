import {MutationHookOptions} from '@apollo/client';
import {RemoveDeal} from '../queries';
import {getClient} from '@houseque/core/apollo';
/**
 * Cache update only necessary if not using local state.
 */
const options: MutationHookOptions<any> = {
  update: (cache, {data: {removeDeal: id}}) => {
    cache.modify({
      fields: {
        myDeals(existing = []) {
          return existing.filter(deal => deal.id !== id);
        },
      },
    });
  },
};

export const removeUserDeal = async (id: string) => {
  const apolloClient = await getClient();
  const result = await apolloClient.mutate({
    mutation: RemoveDeal,
    update: options.update,
    variables: {
      id,
    },
  });
  return result;
};
