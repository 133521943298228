import {useCallback} from 'react';
import {DealState} from '../deal';
import {useDispatch} from 'react-redux';
import {useSaveOfflineDeal} from './useSaveOfflineDeal';
import {saveDeal} from '../services/saveDealService';
import {useNetInfo} from '@react-native-community/netinfo';
import {saveDealError} from './actions';
import {saveDealSuccess} from '../slice';

/**
 * This is a bit complicated, probably can be simplified.
 * In most cases, it just saves the deal, then uploads the photos, and done.
 * In the case a user is not online, the deal is saved local and will sync when online.
 * @param alwaysUseNetwork Ensures that the call is online, used to queue up online calls
 */
export const useSaveDeal = () => {
  const netInfo = useNetInfo();
  const saveOffline = useSaveOfflineDeal();
  const dispatch = useDispatch();
  const save = useCallback(
    async (deal: DealState) => {
      if (netInfo.isInternetReachable) {
        try {
          const result = await saveDeal(deal);
          //Put deal save success
          dispatch(saveDealSuccess(result));
        } catch (err) {
          dispatch(saveDealError(err));
          throw err;
        }
      } else {
        saveOffline(deal);
      }
    },
    [netInfo.isInternetReachable, saveOffline],
  );

  return save;
};
