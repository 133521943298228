import React, {ComponentProps} from 'react';
import {ViewProjections, EstimatePane} from './component';
import {styles} from './styles';
import {Card} from 'react-native-paper';

type EstimateWithComponent = {Component: React.ReactElement};
export type EstimateContainerPane =
  | ComponentProps<typeof EstimatePane>
  | EstimateWithComponent;

type EstimatesContainerProps = {
  estimates: EstimateContainerPane[];
};

export const EstimatesContainer = (props: EstimatesContainerProps) => {
  return (
    <Card>
      <Card.Content
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
        {props.estimates.map(estimate => {
          if ('Component' in estimate) {
            return estimate.Component;
          }
          return (
            <EstimatePane
              label={estimate.label}
              valueType={estimate.valueType}
              value={estimate.value}
            />
          );
        })}
      </Card.Content>
      <Card.Actions>
        <ViewProjections />
      </Card.Actions>
    </Card>
  );
};
