import {Action} from '@reduxjs/toolkit';
import {call, put, takeLatest, debounce, takeEvery} from 'redux-saga/effects';
import {sendError} from '../../../core/application/exceptions';
import {
  addMultipleDealsToGroupAction,
  joinGroupAction,
  leaveGroupAction,
  createGroupAction,
  searchGroupsAction,
  groupsErrorAction,
} from './actions';
import {
  addMultipleDealsToGroup,
  joinGroup,
  leaveGroup,
  saveGroup,
  searchGroups,
} from './groupsService';
import groupsSlice from './slice';

function* putError(error) {
  yield sendError(error);
}

function* onSeachGroups(action) {
  try {
    //Ensure we get over 2 characters
    yield put(groupsSlice.actions.searchGroupsBeginning());
    const results = yield call(searchGroups, action.payload);
    yield put(groupsSlice.actions.searchGroupsComplete(results));
  } catch (err) {
    yield put(groupsSlice.actions.searchGroupsError(err));
    yield putError(err);
  }
}

function* onGroupAction(action: Action) {
  try {
    if (addMultipleDealsToGroupAction.match(action)) {
      yield call(
        addMultipleDealsToGroup,
        action.payload?.deals,
        action.payload?.groupId,
      );
    } else if (joinGroupAction.match(action)) {
      yield call(joinGroup, action.payload);
    } else if (leaveGroupAction.match(action)) {
      yield call(leaveGroup, action.payload);
    } else if (createGroupAction.match(action)) {
      yield call(saveGroup, action.payload);
    }
  } catch (err) {
    yield putError(err);
  }
}

function* handleGroupNetworkError(action) {
  yield putError(action.payload);
}

export default function* groupsSagas() {
  yield takeLatest(
    [
      createGroupAction.type,
      leaveGroupAction.type,
      joinGroupAction.type,
      addMultipleDealsToGroupAction.type,
    ],
    onGroupAction,
  );
  yield takeEvery(groupsErrorAction.type, handleGroupNetworkError);
  yield debounce(500, searchGroupsAction.type, onSeachGroups);
}
