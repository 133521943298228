import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {logShareAction} from './actions';
import {FirebaseAnalyticsTypes} from '@react-native-firebase/analytics';

export const useLogShare = () => {
  const dispatch = useDispatch();

  return useCallback(
    (shareEvent: FirebaseAnalyticsTypes.ShareEventParameters) => {
      dispatch(logShareAction(shareEvent));
    },
    [dispatch],
  );
};
