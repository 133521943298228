import {eventChannel} from 'redux-saga';
import {call, takeLatest, put} from 'redux-saga/effects';
import NetInfo, {NetInfoState} from '@react-native-community/netinfo';
import {NETWORK_ONLINE, NETWORK_OFFLINE} from './actions';

const createNetworkChannel = () => {
  return eventChannel(emitter => {
    const unsubscribe = NetInfo.addEventListener(state => {
      emitter(state);
    });
    return unsubscribe;
  });
};

function* onNetworkChange(state: NetInfoState) {
  if (state.isInternetReachable) {
    yield put({type: NETWORK_ONLINE});
    //Try to restore tasks.
  } else {
    yield put({type: NETWORK_OFFLINE});
  }
}

export default function* networkSaga() {
  const chan = yield call(createNetworkChannel);
  yield takeLatest(chan, onNetworkChange);
}
