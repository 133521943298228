import React from 'react';
import {Pagination} from './Pagination';

interface EvaluationPaginationProps {
  options: any[];
  activeSlide: number;
  onPress: (index: number) => void;
}

export const DealAnalyzerPagination = ({
  options,
  activeSlide,
  onPress,
}: EvaluationPaginationProps) => {
  return (
    <Pagination
      activeIndex={activeSlide}
      onChangeIndex={onPress}
      items={options}
    />
  );
};
