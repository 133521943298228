import React, {useEffect, useState, useCallback} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
} from '@react-navigation/drawer';
import {useNavigation, DrawerActions} from '@react-navigation/native';
import {SettingsScreen} from './screens/settings';
import {Home} from './screens/home';
import {BrandLogo, Icon} from '@houseque/components';
import {UserProfile} from './screens/update-profile';
import {DealAnalyzer, PropertyDetails} from './screens/deal-analyzer';
import {ReportScreen} from './screens/report';
import {LoanForm, RefinanceForm} from './screens/financing';
import {StackParams} from './nav';
import {useWindowDimensions} from 'react-native';
import {View} from 'react-native';
import {CashflowScreen} from './screens/cashflow';
import {
  NewGroupScreen,
  GroupSearch,
  GroupsScreen,
  GroupDetailScreen,
} from './screens/groups';
import {UpdateInvestmentTargets} from './screens/investment-targets';
import {PresetsPage} from './screens/presets/PresetsPage';

const WIDTH_BREAKPOINT = 600;

const Stack = createStackNavigator<StackParams>();
const Drawer = createDrawerNavigator();

const DealStack = [
  <Stack.Screen key={'deal'} name={'deal'} component={DealAnalyzer} />,
  <Stack.Screen key={'report'} name={'Report'} component={ReportScreen} />,
  <Stack.Screen key={'cflow'} name={'Cashflow'} component={CashflowScreen} />,
  <Stack.Screen key={'loan'} name={'Loan'} component={LoanForm} />,
  <Stack.Screen key={'refi'} name={'Refinance'} component={RefinanceForm} />,
  <Stack.Screen name={'PropertyDetails'} component={PropertyDetails} />,
];

const HomeStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={'dashboard'}
      headerMode={'float'}
      screenOptions={{
        headerTitle: () => <HeaderTitle />,
        headerRight: () => <RightMenu />,
      }}>
      <Stack.Screen name={'dashboard'} component={Home} />
      {DealStack}
    </Stack.Navigator>
  );
};

const GroupStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={'groups'}
      screenOptions={{
        headerTitle: () => <HeaderTitle />,
        headerRight: () => <RightMenu />,
      }}>
      <Stack.Screen name={'groups'} component={GroupsScreen} />
      <Stack.Screen name={'create-group'} component={NewGroupScreen} />
      <Stack.Screen name={'group-details'} component={GroupDetailScreen} />
      <Stack.Screen name={'search-groups'} component={GroupSearch} />
      {DealStack}
    </Stack.Navigator>
  );
};

const OptionsStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerTitle: () => <HeaderTitle />,
        headerRight: () => <RightMenu />,
      }}>
      <Stack.Screen name={'options'} component={SettingsScreen} />
      <Stack.Screen name={'UpdateProfile'} component={UserProfile} />
      <Stack.Screen name={'Presets'} component={PresetsPage} />
      <Stack.Screen
        name={'InvestmentTargets'}
        component={UpdateInvestmentTargets}
      />
    </Stack.Navigator>
  );
};

const DrawerStack = () => {
  const {height, width} = useWindowDimensions();
  const [drawerType, setDrawerType] =
    useState<'slide' | 'permanent' | 'front' | 'back'>('slide');
  useEffect(() => {
    if (width > WIDTH_BREAKPOINT) {
      setDrawerType('permanent');
    } else {
      setDrawerType('back');
    }
  }, [width]);
  return (
    <Drawer.Navigator
      drawerStyle={{width: 250}}
      sceneContainerStyle={{height}}
      drawerContent={props => <CustomDrawerContent {...props} />}
      drawerType={drawerType}
      initialRouteName={'home'}>
      <Drawer.Screen
        options={{title: 'Home'}}
        name={'home'}
        component={HomeStack}
      />
      <Drawer.Screen
        options={{title: 'Groups'}}
        name={'groups'}
        component={GroupStack}
      />
      <Drawer.Screen
        options={{title: 'Options'}}
        name={'options'}
        component={OptionsStack}
      />
    </Drawer.Navigator>
  );
};

export const AppNavigator = () => {
  return <DrawerStack />;
};

const CustomDrawerContent = props => {
  const height = 64;
  const navigation = props.navigation;
  const closeMenu = useCallback(() => {
    navigation.dispatch(DrawerActions.closeDrawer());
  }, [navigation]);
  return (
    <DrawerContentScrollView {...props}>
      <View
        style={{
          height,
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingHorizontal: 16,
        }}>
        <HeaderTitle inverseVisible />
        <ScreenSize condition={size => size < WIDTH_BREAKPOINT}>
          <Icon
            style={{alignSelf: 'flex-start'}}
            color={'grey3'}
            onPress={closeMenu}
            icon={'chevron-left'}
          />
        </ScreenSize>
      </View>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};

interface HeaderTitleProps {
  inverseVisible?: boolean;
}

const HeaderTitle = ({inverseVisible}: HeaderTitleProps) => {
  return (
    <ScreenSize
      condition={size =>
        inverseVisible ? size > WIDTH_BREAKPOINT : size < WIDTH_BREAKPOINT
      }>
      <BrandLogo />
    </ScreenSize>
  );
};

const RightMenu = () => {
  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.dispatch(DrawerActions.openDrawer());
  }, [navigation]);
  return (
    <ScreenSize condition={size => size < WIDTH_BREAKPOINT}>
      <Icon icon={'menu'} color={'primary'} onPress={onPress} />
    </ScreenSize>
  );
};

interface ScreenSizeProps {
  condition: (width: number) => boolean;
  children: any;
  FalseComponent?: any;
}

const ScreenSize = ({
  condition,
  children,
  FalseComponent = null,
}: ScreenSizeProps) => {
  const [result, setResult] = useState(undefined);
  const {width} = useWindowDimensions();
  useEffect(() => {
    setResult(condition(width));
  }, [width, condition]);
  if (result === undefined) return null;
  return (result && children) || FalseComponent;
};
