import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {groupsErrorAction} from './actions';
import {createGroup} from './groupsService';
import {Group} from './types';

export const useCreateGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (group: Group) => {
      try {
        return await createGroup(group);
      } catch (err) {
        dispatch(groupsErrorAction(err));
        throw err;
      }
    },
    [createGroup],
  );
};
