import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import authSlice from './slice';

export const useLinkAccount = () => {
  const dispatch = useDispatch();
  /**
   * The password for the existing account.
   */
  return useCallback(
    (password: string) => {
      dispatch(authSlice.actions.authLoginLink(password));
    },
    [dispatch],
  );
};
