import React from 'react';
import {SafeAreaView} from 'react-native';
import {ShareDeal} from './ShareDeal';
import {useNavigation} from '@react-navigation/core';
import {useEstimates} from '@houseque/core/deals/selectors/useEstimates';
import {PropertyPhotos} from '../deal-analyzer/property/PropertyPhotos';
import {ScrollView} from 'react-native-gesture-handler';
import {PropertyProjections} from './PropertyProjections';
import {CashflowDetails} from './CashflowDetails';
import {PurchaseDetails} from './PurchaseDetails';
import {Text} from '@houseque/components';
import {ReportLine} from './ReportLine';
import {View} from 'react-native';
import {SectionHeader} from './SectionHeader';
import {
  TutorialControls,
  TutorialTextOutlet,
} from '../deal-analyzer/components';
import {TutorialHighlight} from '@houseque/core/tutorial';
import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {useCapRate} from '../deal-analyzer/estimates/component';
import {useOutOfPocket} from '@houseque/core/deals/selectors/financing';

export const ReportScreen = () => {
  const deal = useCurrentDeal();
  const navigation = useNavigation();

  navigation.setOptions({
    title: deal.name,
    headerRight: () => (
      <TutorialHighlight
        emphasisScale={1.2}
        stepId={'sharing'}
        onExit={state => {
          navigation.goBack();
        }}>
        <ShareDeal />
      </TutorialHighlight>
    ),
  });

  return (
    <SafeAreaView style={{flex: 1}}>
      <TutorialTextOutlet />
      <ScrollView contentContainerStyle={{paddingTop: 20, paddingBottom: 50}}>
        <Text paddingHorizontal={'m'}>{deal.property.address}</Text>
        <PropertyPhotos
          containerStyle={{paddingLeft: 10, marginTop: 20, marginBottom: 12}}
          viewOnly
        />
        <EstimateDetails />
        <PurchaseDetails />
        <CashflowDetails />
        <PropertyProjections />
        <View style={{paddingVertical: 50}} />
      </ScrollView>
      <TutorialControls />
    </SafeAreaView>
  );
};

const EstimateDetails = () => {
  const estimates = useEstimates();
  const outOfPocket = useOutOfPocket();
  const capRate = useCapRate();
  return null;
  return (
    <>
      <SectionHeader>Estimates</SectionHeader>
      <ReportLine
        label={'NOI'}
        value={estimates.netOperatingIncome}
        type={'currency'}
      />
      <ReportLine
        label={'Out of Pocket'}
        value={outOfPocket}
        type={'currency'}
      />
      <ReportLine label={'Cap Rate'} value={capRate} type={'percent'} />
      <ReportLine
        label={'Cash on Cash'}
        value={(estimates.monthlyCashflow * 12) / outOfPocket}
        type={'percent'}
      />
    </>
  );
};
