import React, {useCallback, useEffect, useState} from 'react';
import {
  TextInput,
  Text,
  ScreenWithKeyboard,
  Fab,
  Box,
  ModalClose,
} from '../../../components';
import {usePresets} from '../../preferences/usePresets';
import {useSavePresets} from '../../preferences/useSavePresets';
import {useFormik} from 'formik';
import {PresetValue} from '../../preferences/types';
import {useNavigation} from '@react-navigation/core';

export const PresetsPage = () => {
  const {data: presets} = usePresets();
  const savePresets = useSavePresets();
  const [initialValues, setInitialValues] = useState({});
  const navigation = useNavigation();
  const onSubmit = useCallback(values => {
    const presetsToSave = Object.entries(values).map(([key, value]) => {
      const [category, subcategory] = key.split('|');
      const existing = presets?.find(preset => {
        return (
          preset.category === category && preset.subcategory === subcategory
        );
      });

      const preset: PresetValue = {
        id: existing?.id,
        //@ts-ignore
        category,
        //@ts-ignore
        subcategory,
        //@ts-ignore
        value,
        version: '1',
        source: 'user',
        type: 'percent',
      };
      return preset;
    });
    savePresets(presetsToSave);
    navigation.goBack();
  }, []);

  useEffect(() => {
    const updatedValues = {};
    for (const preset of presets ?? []) {
      updatedValues[`${preset.category}|${preset.subcategory}`] = preset.value;
    }
    setInitialValues(updatedValues);
  }, [presets]);

  const {values, submitForm, setFieldValue} = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  const updateField = (fieldName: string) => (value: unknown) => {
    setFieldValue(fieldName, value);
  };

  return (
    <ScreenWithKeyboard>
      <Box
        flexDirection={'row'}
        justifyContent={'space-between'}
        marginVertical={'m'}
        alignItems={'center'}>
        <Text variant={'largeTitle'}>Presets</Text>
        <ModalClose />
      </Box>
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['assumptions|propertyvalue']}
        type={'percent'}
        label={'Annual Property Value Increase'}
        onChangeText={updateField('assumptions|propertyvalue')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['assumptions|rent']}
        type={'percent'}
        label={'Annual Rent Increases'}
        onChangeText={updateField('assumptions|rent')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['assumptions|expenses']}
        type={'percent'}
        label={'Annual Expense Increases'}
        onChangeText={updateField('assumptions|expenses')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['assumptions|sell']}
        type={'percent'}
        label={'Selling Costs'}
        onChangeText={updateField('assumptions|sell')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['expenses|repairs']}
        type={'percent'}
        label={'Maintenance'}
        onChangeText={updateField('expenses|repairs')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['expenses|capex']}
        type={'percent'}
        label={'Capital Expenditures'}
        onChangeText={updateField('expenses|capex')}
      />
      <Box paddingTop={'s'} />
      <TextInput
        keyboardType={'decimal-pad'}
        value={values['expenses|vacancy']}
        type={'percent'}
        label={'Vacancy'}
        onChangeText={updateField('expenses|vacancy')}
      />
      <Fab icon={'check'} onPress={submitForm} />
    </ScreenWithKeyboard>
  );
};
