import React, {useRef, useEffect} from 'react';
import {Animated, Easing} from 'react-native';
import {Icon} from './Icon';
import {HouseQueColors} from '../core/theme/houseque.theme';

export type PointerDirections = 'up' | 'left' | 'right' | 'down';

export interface PointerProps {
  /**
   * @default 'upward'
   */
  direction?: PointerDirections;
  color?: keyof HouseQueColors;
}

export const Pointer = ({
  direction = 'up',
  color = 'primary',
}: PointerProps) => {
  const animation = useRef(new Animated.Value(0));

  useEffect(() => {
    const throb = Animated.sequence([
      Animated.timing(animation.current, {
        toValue: -10,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: true,
      }),
      Animated.timing(animation.current, {
        toValue: 0,
        duration: 200,
        easing: Easing.ease,
        useNativeDriver: true,
      }),
    ]);
    const pulseTwice = Animated.loop(throb, {iterations: 2});
    const pulse = Animated.sequence([Animated.delay(2000), pulseTwice]);
    const nudgeAnimationLoop = Animated.loop(pulse);
    nudgeAnimationLoop.start();
    return nudgeAnimationLoop.stop;
  }, [animation]);
  return (
    <Animated.View style={{transform: [{translateY: animation.current}]}}>
      <Icon color={color} icon={`arrow-${direction}`} />
    </Animated.View>
  );
};
