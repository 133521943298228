import Config from 'react-native-config';
import {Deal} from '@houseque/types';

export const shareLink = (deal: Deal, encode = false) => {
  const url = `${Config.RN_REPORT_URL}/deal`;
  if (encode) {
    return `${url}?deal=${encodeURIComponent(JSON.stringify(deal))}`;
  }
  return `${url}?deal=${JSON.stringify(deal)}`;
};
