import {useDealType} from '@houseque/core/deals';
import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {
  useClosingCosts,
  useLoanPayment,
  useOutOfPocket,
  usePurchaseState,
} from '@houseque/core/deals/selectors/financing';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'src/member/store';
import {CapRate, Cashflow, CashOnCashReturn, RehabCashflow} from './component';
import {EstimateContainerPane} from './EstimatesContainer';

export const useDashboardEstimates = () => {
  const deal = useCurrentDeal();
  const dashboardType = useDealType(deal);
  const rehab = useRehab();
  const closingCosts = useClosingCosts();
  const [flipProfits, setFlipProfits] = useState<number | undefined>(undefined);
  const purchase = usePurchaseState();
  const [flipCosts, setFlipCosts] = useState(0);
  const estimate = useSelector(
    (store: RootState) => store.deals.selectedDealEstimates,
  );

  useEffect(() => {
    // Usually about a 6% cost
    const sellProceedsPenalty = 0.94;
    const profit = (rehab.arv ?? 0) * sellProceedsPenalty;
    setFlipProfits(profit - flipCosts);
  }, [rehab.arv, flipCosts]);

  useEffect(() => {
    //Adds the purchase price, total cashflow incursions during rehab, and
    const losses = [rehab.rehabCosts ?? 0, purchase.price ?? 0].reduce(
      (total, loss) => total + loss,
      0,
    );

    const rehabCashflow =
      (rehab.duration ?? 0) * (estimate.monthlyCashflow ?? 0);

    setFlipCosts(losses - rehabCashflow + closingCosts.amount);
  }, [rehab, purchase, estimate.monthlyCashflow, closingCosts.amount]);

  const isBRRRRR = dashboardType === 'fixhold';
  const isRental = dashboardType === 'rental';
  const isFlip = dashboardType === 'flip';

  const outOfPocket = useOutOfPocket(isBRRRRR);
  const loanPayment = useLoanPayment(isBRRRRR);

  let estimates: EstimateContainerPane[] = [];

  estimates.push({
    label: isBRRRRR ? 'Cash in after Refi' : 'Out of pocket',
    valueType: 'currency',
    value: outOfPocket,
  });
  //Cashflow
  if (isRental || isBRRRRR) {
    estimates.push({
      Component: <Cashflow />,
    });
  }
  if (isFlip) {
    estimates.push({
      Component: <RehabCashflow />,
    });
  }
  if (isRental) {
    estimates.push({
      Component: <Cashflow monthly />,
    });
  }
  if (isBRRRRR || isFlip) {
    estimates.push({
      label: 'ROI',
      valueType: 'percent',
      value: flipProfits / outOfPocket,
    });
  }
  if (isBRRRRR || isRental) {
    estimates.push({
      Component: (
        <CashOnCashReturn
          cashInvested={outOfPocket}
          debtService={loanPayment * 12}
          netOperatingIncome={estimate.netOperatingIncome}
        />
      ),
    });
  }
  if (isRental) {
    estimates.push({
      Component: <CapRate />,
    });
  }
  if (isFlip) {
    estimates.push({
      label: 'Total costs',
      valueType: 'currency',
      value: flipCosts ?? 0,
    });
  }
  if (isFlip || isBRRRRR) {
    estimates.push({
      label: 'Net Profits',
      valueType: 'currency',
      value: flipProfits ?? 0,
    });
  }
  return estimates;
};
