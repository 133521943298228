import React, {ComponentProps} from 'react';
import {StyleSheet} from 'react-native';
import appleAuth, {
  AppleButton,
} from '@invertase/react-native-apple-authentication';

import {Box} from '@houseque/components';
import {SocialLoginButton} from './SocialLoginButton';
import {useSocialLogin} from './authentication';
import {NonMemberRootState} from './store';

interface SocialLoginProps {
  containerProps?: ComponentProps<typeof Box>;
  authState: NonMemberRootState['authentication'];
}

const styles = StyleSheet.create({
  logo: {
    fontSize: 42,
  },
  appleButton: {
    height: 56,
  },
});

export const SocialLogins = ({
  authState,
  containerProps = {},
}: SocialLoginProps) => {
  const socialLogin = useSocialLogin();
  return (
    <Box justifyContent={'center'} {...containerProps}>
      <Box paddingTop={'s'} />
      <SocialLoginButton
        type={'facebook'}
        disabled={authState.inFlight}
        title={'Continue with Facebook'}
        onPress={() => socialLogin('facebook')}
      />
      <Box paddingTop={'s'} />
      <SocialLoginButton
        type={'google'}
        disabled={authState.inFlight}
        title={'Continue with Google'}
        onPress={() => socialLogin('google')}
      />
      <Box paddingTop={'s'} />
      {appleAuth.isSupported &&
        ((
          <AppleButton
            onPress={() => {
              if (authState.inFlight) return;
              socialLogin('apple');
            }}
            style={styles.appleButton}
            buttonStyle={AppleButton.Style.WHITE_OUTLINE}
            buttonType={AppleButton.Type.CONTINUE}
          />
        ) ||
          null)}
    </Box>
  );
};
