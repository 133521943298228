import {takeLatest, call, takeLeading, put, delay} from 'redux-saga/effects';
import {setUser, signout} from './slice';
import auth from '@react-native-firebase/auth';
import {eventChannel} from 'redux-saga';
import {getClient} from '@houseque/core/apollo';

import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {Platform} from 'react-native';

const isIOS = Platform.OS === 'ios';

export const onAuthStateChange = (
  listener: FirebaseAuthTypes.AuthListenerCallback,
) => {
  return auth().onAuthStateChanged(listener);
};

export const onUserChanged = (
  listener: FirebaseAuthTypes.AuthListenerCallback,
) => {
  // React native provides this, but it's missing on web.
  if (auth().onUserChanged) {
    return auth().onUserChanged(listener);
  }
  return onAuthStateChange(listener);
};

interface FirebaseUserWithInternals extends FirebaseAuthTypes.User {
  _user: FirebaseAuthTypes.User;
}

/**
 * For iOS only, checks the top level provider and grabs data from additional sources.
 * Specifically some hairy situations with apple login.
 * @param user
 */
const retrieveCompleteProfile = (user: FirebaseUserWithInternals) => {
  if (!isIOS || !user) return user;
  const [previousProvider] = user.providerData ?? [
    {} as FirebaseAuthTypes.UserInfo,
  ];
  user._user.displayName = user.displayName ?? previousProvider.displayName;
  user._user.photoURL = user.photoURL ?? previousProvider.photoURL;
  return user;
};

const userChangedChannel = () =>
  eventChannel(emitter => {
    const unsubscribe = onUserChanged(user => {
      return emitter({user});
    });
    return () => unsubscribe();
  });

function* handleUserChange({user}) {
  yield put(setUser(retrieveCompleteProfile(user)));
  //Seems to trigger multiple times, this limits to just one
  //SKip for tests
  if (process.env.NODE_ENV !== 'test') {
    yield delay(5);
  }
}

function* handleSignout() {
  yield call(async () => await auth().signOut());
  //Remove all local data, then sign out
  yield call(async () => {
    const apolloClient = await getClient();
    apolloClient.clearStore();
  });
}

export default function* userSaga() {
  const channel = yield call(userChangedChannel);
  yield takeLeading(channel, handleUserChange);
  yield takeLatest(signout.type, handleSignout);
}
