export const compound = (
  amount: number,
  periods = 0,
  appreciation = 0,
  appreciationFrequency = 1, //If appreciation accumulates more than once per period
) => {
  const periodAppreciation = appreciation / appreciationFrequency;
  const effectivePeriods = periods * appreciationFrequency;
  const modifier = (1 + periodAppreciation) ** effectivePeriods;
  return amount * modifier;
};
