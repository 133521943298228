import React from 'react';
import {IconButton} from 'react-native-paper';

export const IconTab = ({
  name,
  ...props
}: {
  name: string;
  tintColor: string;
  color: string;
  size: number;
}) => {
  return (
    <IconButton
      color={props.tintColor || props.color}
      icon={name}
      size={props.size}
    />
  );
};
