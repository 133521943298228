import {usePurchaseState} from '../../../../../core/deals/selectors/financing';
import {View} from 'react-native';
import React, {useCallback, useMemo} from 'react';
import {Box, Link, Text, TextNumeric} from '../../../../../components';
import {LoanItem} from './LoanItem';
import {useNavigation} from '@react-navigation/core';
import {TouchableRipple} from 'react-native-paper';
import {Mortgage} from '@houseque/types';

export const Loans = () => {
  const purchase = usePurchaseState();
  const {navigate} = useNavigation();
  const {
    loans: [mortgage],
  } = purchase;

  const displayLoanForm = useCallback(() => {
    navigate('Loan', {
      mortgage: JSON.stringify(mortgage),
      title: 'Loan Information',
    });
  }, [mortgage, navigate]);

  const displayRefinanceForm = useCallback(() => {
    navigate('Refinance', {
      mortgage: JSON.stringify(mortgage),
      title: 'Refinance Information',
    });
  }, [mortgage, navigate]);

  return (
    <View>
      <Box
        paddingVertical={'s'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}>
        <Text paddingRight={'m'} color={'grey2'} variant={'headline'}>
          Loans
        </Text>
        <AddLoan
          onPress={mortgage ? displayRefinanceForm : displayLoanForm}
          currentLoan={mortgage}
        />
      </Box>
      {mortgage && (
        <Text variant={'caption1'} color={'grey1'}>
          Tap and hold a loan to remove it.
        </Text>
      )}

      <Box>
        <LoanItem loan={mortgage} onPress={displayLoanForm} />
        {mortgage && (
          <LoanItem
            newLoanText={'Add Refinance'}
            loan={mortgage.refinance!}
            refinance={true}
            onPress={displayRefinanceForm}
          />
        )}
      </Box>
    </View>
  );
};

type AddLoanProps = {
  currentLoan?: Mortgage;
  onPress?: () => void;
};

const AddLoan = ({
  currentLoan,
  onPress = () => {
    // ignore
  },
}: AddLoanProps) => {
  const shouldHide = useMemo(() => {
    return currentLoan?.refinance ?? false;
  }, [currentLoan]);
  const text = useMemo(() => {
    if (!currentLoan) {
      return 'Add Loan';
    }
    return 'Add Refinance';
  }, [currentLoan]);
  if (shouldHide) return null;
  return (
    <TouchableRipple onPress={onPress}>
      <Text color={'link'} variant={'body'}>
        {text}
      </Text>
    </TouchableRipple>
  );
};
