import React, {useEffect} from 'react';
import {Image} from 'react-native';
import {useNavigation} from '@react-navigation/core';
import {Link} from '@react-navigation/native';
import {Text, Button, Box} from '../../components';
import {BenefitsCaraousel} from './BenefitsCarousel';

import {useAuthenticationState} from '../authentication';
import {SafeAreaView} from 'react-native-safe-area-context';

export const WelcomeScreen = () => {
  const {navigate} = useNavigation();
  const authState = useAuthenticationState();
  const getStarted = () => navigate('Register');

  useEffect(() => {
    if (!!authState.associateAccount) {
      navigate('LinkAccount');
    }
  }, [navigate, authState.associateAccount]);

  return (
    <SafeAreaView style={{flex: 1}}>
      <Box
        paddingTop={'xl'}
        minHeight={150}
        justifyContent={'center'}
        alignItems={'center'}
        padding={'m'}>
        <Image
          resizeMode={'cover'}
          style={{
            flex: 1,
            maxWidth: '100%',
          }}
          source={require('./assets/logo-text.png')}
        />
        <Text variant={'subhead'} paddingTop={'s'}>
          Analyze and share deals in minutes.
        </Text>
      </Box>
      <Box flex={1} justifyContent={'space-between'} paddingBottom={'m'}>
        <BenefitsCaraousel />
        <Box marginHorizontal={'m'}>
          <Button title={'Get Started'} onPress={getStarted} />
          <Box paddingTop={'m'} flexDirection={'row'} alignItems={'center'}>
            <Text variant={'body'} color={'grey0'}>
              Already onboard?
            </Text>
            <Box paddingLeft={'s'} />
            <Link to={'/Login'}>
              <Text color={'link'} variant={'button'}>
                Login
              </Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </SafeAreaView>
  );
};
