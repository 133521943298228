import React from 'react';

import {useNavigation, useRoute} from '@react-navigation/core';
import {Mortgage} from '@houseque/types';
import {FinancingForm} from './FinancingForm';
import {useChangeFinancing} from '@houseque/core/deals/selectors/financing/useChangeFinancing';

const formDefaults: Mortgage = {
  downPayment: 0.2,
  rate: 0.0425,
  term: 30,
  rateType: 'frm',
  paymentFrequency: 1,
  armType: null,
  interestOnly: false,
  financeClosingCosts: false,
  refinance: null,
};

export const LoanForm = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const changeFinancing = useChangeFinancing();
  let mortgage = route.params['mortgage'] || formDefaults;
  if (typeof mortgage === 'string') {
    try {
      mortgage = JSON.parse(mortgage);
    } catch (err) {
      console.warn(`Unable to parse ${mortgage}`);
      mortgage = formDefaults;
    }
  }
  return (
    <>
      {/* <Link to={"/AmortizationDetails"}>View Amortization Details</Link> */}
      <FinancingForm
        loan={mortgage as any}
        allowDelayedLoan={false}
        refinanceOnly={false}
        onSubmit={values => {
          changeFinancing([values]);
          navigation.goBack();
        }}
      />
    </>
  );
};

LoanForm.navigationOptions = ({navigation}: any) => ({
  title: `Loan Information`,
  headerTitle: null,
});
