import {StyleSheet} from 'react-native';
import {useMemo} from 'react';
import {useTheme} from './useTheme';
import {HousequeTheme} from '../houseque.theme';

const defaultDeps: any[] = [];

export const useStyle = <T extends StyleSheet.NamedStyles<T>>(
  getStyle: (theme: HousequeTheme) => T,
  additionalDeps: any[] = defaultDeps,
) => {
  const theme = useTheme();
  return useMemo(() => {
    return getStyle(theme);
    // eslint-disable-next-line
  }, [theme.name, ...additionalDeps]);
};
