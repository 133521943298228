import {call, takeLatest, takeEvery} from 'redux-saga/effects';
import crashlytics from '@react-native-firebase/crashlytics';
import {exceptionCaughtAction} from './actions';
import {setUser} from '../user/slice';

function* onUserChange({payload: user}: ReturnType<typeof setUser>) {
  //Reset analytics
  return yield call(async () => {
    await crashlytics().setUserId(user?.uid ?? '');
  });
}

function* onCaughtError({
  payload: error,
}: ReturnType<typeof exceptionCaughtAction>) {
  return yield call(() => {
    crashlytics().recordError(error);
  });
}

export default function* analyticsSaga() {
  yield takeLatest(setUser.type, onUserChange);
  yield takeEvery(exceptionCaughtAction.type, onCaughtError);
}
