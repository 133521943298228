import {QueryFunctionOptions} from '@apollo/client';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {loadUserDeals} from '../slice';

export const useLoadUserDeals = () => {
  const dispatch = useDispatch();
  return useCallback(
    (options?: QueryFunctionOptions) => {
      dispatch(loadUserDeals(options));
    },
    [dispatch],
  );
};
