import React from 'react';
import {Text} from './Text';
import {StyleSheet, StyleProp, TextStyle, View} from 'react-native';
import {useStyle} from '../core/theme';

//TODO remove me
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
});

export const BrandLogo = ({style}: {style?: StyleProp<TextStyle>}) => {
  const localStyles = useStyle(({edge, colors, font}) => ({
    first: {
      marginRight: edge.xsmall,
      color: colors.primary,
      fontFamily: font.bold,
    },
    second: {
      color: colors.secondary,
    },
  }));
  return (
    <View style={[styles.container, style]}>
      <Text style={[localStyles.first, style]}>HOUSE</Text>
      <Text style={[localStyles.second, style]}>QUE</Text>
    </View>
  );
};
