import React, {useCallback, useEffect} from 'react';
import {Box, Fab, Link, Text} from '../../../components';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useCurrentUserGroups} from './useCurrentUserGroups';
import {FlatList} from 'react-native-gesture-handler';
import {RouteProp} from '@react-navigation/core';
import {GroupTile} from './GroupTile';
import {useCurrentUser} from '@houseque/core/application/user';
import {StackNavigationProp} from '@react-navigation/stack';
import {StackParams} from '../../../member/nav';
import {ExplanationIcon, useShowExplanation} from '@houseque/core/explanations';

type GroupsScreenProps = StackNavigationProp<StackParams, 'groups'>;

interface GroupsProps {
  navigation: GroupsScreenProps;
  route: RouteProp<StackParams, 'groups'>;
}

const NoGroupsYet = () => {
  const showExplanation = useShowExplanation('groups');
  return (
    <Box margin={'m'}>
      <Text variant={'body'} color={'grey1'} marginBottom={'m'}>
        You aren't currently apart of any groups. Create or join a group today!
      </Text>
      <Link
        color={'link'}
        title={'What are groups?'}
        onPress={showExplanation}
      />
    </Box>
  );
};

export const GroupsScreen = ({navigation, route: {params}}: GroupsProps) => {
  const {groups, loading} = useCurrentUserGroups();
  const currentUser = useCurrentUser();
  const goToGroup = useCallback(
    (groupId: string, addtnl: any = {}) => {
      navigation.navigate('group-details', {groupId, ...addtnl});
    },
    [navigation.navigate],
  );

  // Relays the information to the appropriate screen
  const groupIdParam = params?.groupId;
  useEffect(() => {
    if (groupIdParam) {
      goToGroup(groupIdParam, {
        dealId: params?.sectionId,
      });
    }
  });

  return (
    <SafeAreaView style={{flex: 1}}>
      <Box margin={'m'} flexDirection={'row'} alignItems={'center'}>
        <Text color={'grey1'} variant={'largeTitle'}>
          Groups
        </Text>
        <ExplanationIcon size={24} which={'groups'} />
      </Box>
      <Box
        flexDirection={'row'}
        alignItems={'center'}
        marginHorizontal={'m'}
        marginBottom={'m'}>
        <Link title={'Search for a group'} href={'search-groups'} />
      </Box>
      {(loading && <Text padding={'m'}>Loading groups...</Text>) || (
        <FlatList
          data={groups}
          scrollEnabled={!!groups?.length}
          ListEmptyComponent={NoGroupsYet}
          keyExtractor={item => item.id}
          renderItem={({item}) => {
            return (
              <GroupTile
                user={currentUser}
                group={item}
                onPress={() => {
                  goToGroup(item.id);
                }}
              />
            );
          }}
        />
      )}
      <Box margin={'m'} flex={1}></Box>
      <Fab
        icon={'plus'}
        label={'Create Group'}
        onPress={() => {
          console.log('hi :)');
          navigation.navigate('create-group');
        }}
      />
    </SafeAreaView>
  );
};
