import React, {useCallback, useEffect, useState, useRef} from 'react';
import {Box, TextInput} from '@houseque/components';
import Decimal from 'decimal.js';
import {FlatList} from 'react-native-gesture-handler';
import {Selection} from './Selection';

const downPayOptions = [0.2, 0.25, 0.3, 0.15, 0.1, 0.035, 0.05, 0];

const formatForDisplay = (value: number) => {
  return `${Decimal.mul(value, 100).toString()} %`;
};

export interface DownPaymentSelectorProps {
  onSelect: (downpayment: number) => void;
  selected?: number;
}

export const SelectDownPayment = ({
  selected,
  onSelect,
}: DownPaymentSelectorProps) => {
  const inputRef = useRef(undefined as any);
  const [selectedIndex, setSelectedIndex] = useState(
    downPayOptions.indexOf(+selected!),
  );
  useEffect(() => {
    setSelectedIndex(downPayOptions.indexOf(+selected!));
  }, [selected]);

  const onPress = useCallback(
    pressedIndex => {
      setTimeout(() => {
        const value = downPayOptions[pressedIndex];
        inputRef.current?.blur();
        onSelect(+value);
      }, 0);
    },
    [onSelect, inputRef],
  );

  const onChangeText = useCallback(
    text => {
      onSelect(+text);
    },
    [onSelect],
  );

  return (
    <>
      <TextInput
        keyboardType={'decimal-pad'}
        label={'Down Payment'}
        value={selected}
        onChangeText={onChangeText}
        ref={inputRef.current}
        type={'percent'}
        placeholder={'Enter or select down payment'}
      />
      <Box marginRight={'-m'} marginVertical={'m'}>
        <FlatList
          keyExtractor={item => item + ''}
          horizontal
          data={downPayOptions}
          renderItem={({item, index}) => {
            return (
              <Selection
                title={formatForDisplay(item)}
                selected={index === selectedIndex}
                onPress={() => onPress(index)}
              />
            );
          }}
        />
      </Box>
    </>
  );
};
