import React, {useCallback, useEffect, useState} from 'react';
import {TextInput, Link, Box} from '../../../../components';
import {useFormik} from 'formik';
import {GooglePlaces} from '../../../../components/GooglePlaces';
import {PropertyState} from './property';
import {useOpenLink, LinkOptions} from '../../../../core';
import {useNavigation} from '@react-navigation/core';
import {
  TutorialText,
  TutorialHighlight,
  useNextTutorialStep,
} from '../../../../core/tutorial';
import {PropertyPhotos} from './PropertyPhotos';
import {StagedPhotos} from '../../../../core/photos/StagedPhotos';
import {CheckParcelAvailability} from '../../property-details';
import {usePropertyDetails} from '../../../../core/deals/selectors/usePropertyDetails';
import {useUpdateProperty} from '../../../../core/deals/actions/useUpdateProperty';
import {useCurrentDeal} from '../../../../core/deals/selectors';
import {
  useUpdateDealLocalData,
  useUpdateDealProperties,
} from '../../../../core/deals/actions';

const TYPE_MAP = {
  street_number: 'streetNumber', // eslint-disable-line @typescript-eslint/camelcase
  route: 'route',
  locality: 'city',
  administrative_area_level_2: 'county', // eslint-disable-line @typescript-eslint/camelcase
  administrative_area_level_1: 'state', // eslint-disable-line @typescript-eslint/camelcase
  country: 'country',
  postal_code: 'postalCode', // eslint-disable-line @typescript-eslint/camelcase
};

export const PropertySection = () => {
  const skipStep = useNextTutorialStep();
  const [linkOptions, setLinkOptions] = useState<LinkOptions | undefined>(
    undefined,
  );
  const deal = useCurrentDeal();
  const updateDealLocalData = useUpdateDealLocalData();
  const navigation = useNavigation();
  const updateProperty = useUpdateProperty();
  const propertyDetails = usePropertyDetails();
  const updateDealProperties = useUpdateDealProperties();
  const updateDealName = useCallback(
    (name: string) => {
      updateDealProperties({name});
    },
    [updateDealProperties],
  );
  const openLink = useOpenLink();
  const updateDescription = useCallback(
    (description: string) => {
      updateDealProperties({description});
    },
    [updateDealProperties],
  );

  useEffect(() => {
    navigation.setOptions({
      title: deal.name || 'New Deal',
    });
  }, [deal.name]);

  const {values, setFieldValue} = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...propertyDetails,
    },
    onSubmit: () => {
      //Lint ignore this:)
    },
  });

  const handleChange = useCallback(
    (fieldName: keyof PropertyState) => {
      return (value: any) => {
        console.log('updating', fieldName, value);
        // setFieldValue(fieldName, value);
        updateProperty({
          [fieldName]: value,
        });
      };
    },
    [updateProperty],
  );

  const onChangeStagedPhotos = useCallback(
    photos => {
      updateDealLocalData({
        photos,
      });
    },
    [updateDealLocalData],
  );

  useEffect(() => {
    const {lat, long} = deal.property?.location ?? {};
    if (lat && long) {
      setLinkOptions({
        schema: 'geo',
        address: `${lat},${long}`,
        params: {
          q: deal.property.address ?? '',
        },
      });
    }
  }, [deal.property]);
  const viewOnMap = useCallback(async () => {
    await openLink(linkOptions!);
  }, [linkOptions, openLink]);

  return (
    <Box flex={1}>
      <Box paddingBottom={'s'}>
        <StagedPhotos
          onChangeStagedPhotos={onChangeStagedPhotos}
          maxPhotos={5}
        />
      </Box>
      <PropertyPhotos />
      <TutorialText stepId={'address'}></TutorialText>
      <GooglePlaces
        value={values.address}
        onChangeText={handleChange('address')}
        onSelection={(search, details) => {
          if (details) {
            const mapped = details.address_components.reduce(
              (total, component) => {
                const [type] = component.types;
                const addressKey = TYPE_MAP[type];
                if (addressKey) {
                  total[addressKey] = component.short_name;
                }
                return total;
              },
              {} as any,
            );
            const {lat, lng} = details.geometry.location;
            handleChange('location')({
              ...mapped,
              lat,
              long: lng,
            });
          }
        }}
      />
      <TutorialHighlight
        stepId={'lookup'}
        emphasisScale={1.05}
        onEnter={direction => {
          if (!propertyDetails.address) {
            skipStep(1);
          }
        }}>
        <Box
          padding={'s'}
          flexDirection={'row'}
          justifyContent={'space-between'}>
          {(linkOptions && (
            <Link title={'View on map'} onPress={viewOnMap} />
          )) ||
            null}
          <CheckParcelAvailability>
            {parcelId => (
              <Link
                title={'View Details'}
                href={'PropertyDetails'}
                params={{parcelId}}
              />
            )}
          </CheckParcelAvailability>
        </Box>
      </TutorialHighlight>
      <TutorialText stepId={'name'}>
        <TextInput
          value={deal.name}
          autoCorrect={false}
          label={'Analysis Name'}
          onChangeText={updateDealName}
        />
      </TutorialText>
      <TextInput
        value={deal.description}
        multiline
        autoCorrect={false}
        label={'Description'}
        onChangeText={updateDescription}
      />
      <TextInput
        value={values.sqFt!}
        type={'numeric'}
        keyboardType={'number-pad'}
        label={'Square Feet'}
        onChangeText={handleChange('sqFt')}
      />
    </Box>
  );
};
