import React, {useRef, useEffect, useState} from 'react';
import {TextInput} from '../components';
import type {
  GooglePlacesAutocompleteProps,
  GooglePlaceData,
} from 'react-native-google-places-autocomplete/GooglePlacesAutocomplete';
// import Config from "react-native-config";

const loadingMaps = new Promise((resolve, reject) => {
  const API_KEY = 'AIzaSyCatpBcwf9fZZPf2AlbhoM-X3uvWYY5V-k';
  // const API_KEY = Config.GOOGLE_PLACES_KEY;
  const uri = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=initMap`;
  document.write(
    `<script async defer type="text/javascript" src="${uri}"}></script>`,
  );
  globalThis.initMap = function () {
    resolve('loaded');
  };
});

type GooglePlacesProps = {
  onSelection?: (
    data: GooglePlaceData,
    details?: GooglePlaceData | undefined,
  ) => void;
  onChangeText?: (text: string) => void;
  value?: string;
  placeholder?: string;
  multiline?: boolean;
  currentLocation?: boolean;
  nearbyPlacesAPI?: GooglePlacesAutocompleteProps['nearbyPlacesAPI'];
  enableHighAccuracyLocation?: boolean;
  //Not used - for now
  styles?: GooglePlacesAutocompleteProps['styles'];
};

export const GooglePlaces = ({
  value,
  multiline = true,
  onChangeText = () => {},
  onSelection = () => {},
  styles = {},
  ...props
}: GooglePlacesProps) => {
  const inputRef = useRef({} as any);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);
  useEffect(() => {
    if (!ready) return;
    loadingMaps.then(() => {
      const input = inputRef.current.root;
      const options = {
        types: ['geocode'],
        componentRestrictions: {country: 'us'},
      };

      //@ts-ignore
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.setFields([
        'formatted_address',
        'address_components',
        'geometry',
        'types',
      ]);
      autocomplete.addListener('place_changed', function () {
        const {types, address_components, geometry, formatted_address} =
          this.getPlace();
        const selection: any = {
          types,
        };
        const details: any = {
          address_components,
          geometry: {
            ...geometry,
            location: {
              lat: geometry.location.lat(),
              lng: geometry.location.lng(),
            },
          },
        };
        onChangeText(formatted_address);
        inputRef.current.setNativeProps({text: formatted_address});
        onSelection(selection, details);
      });
    });
    // eslint-disable-next-line
  }, [ready]);
  return (
    <TextInput
      multiline={multiline}
      value={value ?? ''}
      onChangeText={onChangeText}
      ref={inputRef}
      placeholder={props.placeholder}
    />
  );
};
