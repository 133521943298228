import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ExplanationState} from './types';

const initialState: ExplanationState = {
  visible: false,
};

const slice = createSlice({
  name: 'explanations',
  initialState,
  reducers: {
    dismissExplanation: state => {
      state.visible = false;
    },
    displayExplanation: (
      state,
      action: PayloadAction<ExplanationState['which']>,
    ) => {
      state.visible = true;
      state.which = action.payload;
    },
    displayInlineExplanation: (
      state,
      action: PayloadAction<ExplanationState['which']>,
    ) => {
      state.visible = false;
      state.which = action.payload;
    },
  },
});

export const {
  dismissExplanation,
  displayExplanation,
  displayInlineExplanation,
} = slice.actions;
export default slice.reducer;
