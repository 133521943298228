import {DefaultTheme} from 'react-native-paper';
import {colors} from './colors';

type Theme = typeof DefaultTheme;

export const theme: Theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    placeholder: colors.grey1,
    primary: colors.primary,
    accent: colors.secondary,
  },
};
