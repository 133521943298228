import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {setDeal} from '../slice';
import {DealState} from '../deal';

export const useAnalyzeExistingDeal = () => {
  const dispatch = useDispatch();
  return useCallback(
    (deal: DealState) => {
      dispatch(setDeal(deal));
    },
    [dispatch],
  );
};
