import React, {useCallback} from 'react';
import {useNavigation} from '@react-navigation/core';
import {TouchableRipple} from 'react-native-paper';
import {Text} from './Text';

type LinkProps = React.ComponentProps<typeof Text> & {
  href?: string;
  params?: any;
  title: string;
};

export const Link = ({href, params, title, ...props}: LinkProps) => {
  const {navigate} = useNavigation();
  const onPress = useCallback(() => {
    navigate(href, params);
  }, [navigate, href, params]);
  if (href) {
    props.onPress = onPress;
  }
  return (
    <TouchableRipple onPress={props.onPress}>
      <Text variant={'body'} color={'link'} {...props}>
        {title}
      </Text>
    </TouchableRipple>
  );
  // return (
  //   <RNEButton
  //     raised
  //     type={"clear"}
  //     title={title}
  //     hitSlop={{ top: 15, bottom: 15 }}
  //     containerStyle={themedStyles.containerStyle}
  //     titleStyle={themedStyles.link}
  //     buttonStyle={themedStyles.noPadding}
  //     {...props}
  //   />
  // );
};
