import {createAction} from '@reduxjs/toolkit';
import {DealState} from '../deal';
import {getCurrentOfflineDeals} from '../services/offlineDealsService';
import {getOfflineDeals as getOfflineDealsAction} from '../slice';

export const saveDealError = createAction<Error>('SAVE_DEAL_ERROR');
export const removeDeal = createAction<{
  dealId: string;
  onSuccess: (params: any) => void;
  onError: (params: any) => void;
}>('REMOVE_DEAL');
export const saveDeal = createAction<DealState>('SAVE_DEAL');
export const dealSaved = createAction('DEAL_SAVED');
//Used to trigger calculations, collects the sources and emits the event.
export const sourceUpdated = createAction('SOURCE_UPDATED');

//Offline deals
export const removeOfflineDeal = createAction<DealState>('REMOVE_OFFLINE_DEAL');
export const saveOfflineDeal = createAction<DealState>('SAVE_OFFLINE_DEAL');

export const getOfflineDeals = () => {
  return async (dispatch: Function) => {
    const payload = await getCurrentOfflineDeals();
    dispatch(getOfflineDealsAction(payload));
  };
};
