import React, {useRef, useState, useCallback, useEffect} from 'react';
import {TextInput, TextInputProps} from 'react-native';

export interface TextInputNumericProps extends TextInputProps {
  format: Intl.NumberFormatOptions;
  TextInputComponent?: typeof TextInput;
  onChangeValue?: (value: number) => void;
}

const placeholder = {
  currency: '$',
};

function noop() {
  //sup lint
}

const numericFormat = (text = '', formatter: Intl.NumberFormat) => {
  let prefix = '';
  const config = formatter.resolvedOptions();
  if (config.style === 'currency') {
    prefix = '$';
  }
  text = text.replace(/(-)(\.)/, '$10$2');
  const negativeMatches = text.match(/^(-)(0{0,1}[.]{0,1}0*)$/);

  if (negativeMatches) {
    return `${negativeMatches[1]}${prefix}${negativeMatches[2]}`;
  }
  //Leading decimals, with 0000s strips
  const decimalMatches = text.match(/^[$]{0,1}0{0,1}([.]{1}0*)$/);
  if (decimalMatches) {
    return `${prefix}0${decimalMatches[1]}`;
  }
  const [suffix] = text.match(/\.$/) ?? [''];
  const formatted = formatter.format(+text);
  return formatted + suffix;
};

export const TextInputNumeric = ({
  value: originalValue,
  onChangeText = noop,
  onChangeValue = noop,
  onBlur: externalOnBlur = noop,
  ...props
}: TextInputNumericProps) => {
  const input = useRef<TextInput>(undefined);
  const [value, setValue] = useState('');
  //Prevent firing on change events when not focused
  const [isFocused, setIsFocused] = useState(false);
  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, [value]);

  const format = useCallback(
    value => {
      const formatter = Intl.NumberFormat('en-US', props.format);
      return numericFormat(value, formatter);
    },
    [props.format],
  );

  const onFormattedTextChange = useCallback(
    (text: string) => {
      const num = text?.replace(/[^\d.-]+/g, '');
      if (num == null || text === '$' || text === '-$') {
        return setValue('');
      }
      const formatted = format(num);
      if (formatted.startsWith('NaN')) {
        return;
      }
      setValue(formatted);
    },
    [value, format],
  );

  const onBlur = useCallback(e => {
    externalOnBlur(e);
    setIsFocused(false);
  }, []);

  useEffect(() => {
    const numericValue = value?.replace(/[^\d.-]+/g, '');

    if (isFocused) {
      onChangeText(numericValue);
      if (!isNaN(+numericValue)) {
        onChangeValue(+numericValue);
      }
    }
  }, [value, props.format, isFocused]);

  useEffect(() => {
    if (!isFocused) {
      onFormattedTextChange(originalValue);
    }
  }, [originalValue, isFocused]);

  const TextInputComponent = props.TextInputComponent ?? TextInput;
  return (
    <TextInputComponent
      ref={input}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onChangeText={onFormattedTextChange}
      placeholder={placeholder[props.format.style]}
      {...props}
    />
  );
};
