import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Chip} from 'react-native-paper';

interface ScenarioProps {
  iconName?: string;
  selected?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  title: string;
}

export const Scenario = (props: ScenarioProps) => {
  const {onPress, title, selected, style} = props;

  return (
    <Chip style={style} mode={'outlined'} onPress={onPress} selected={selected}>
      {title}
    </Chip>
  );
};
