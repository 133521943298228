import React, {forwardRef, useMemo} from 'react';
import {
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  TextInputProps,
} from 'react-native';

import {TextInputNumeric} from './TextInputNumeric';
import {Decimal} from 'decimal.js';
import {TextInputPercent} from './TextInputPercent';
import {TextInput as RNPTextInput, Surface} from 'react-native-paper';
import {useShowExplanation} from '../core/explanations';
import {Explanations} from '@houseque/core/explanations/types';

function noop() {
  //comment (eslint)
}

//This component maybe doing too much, but it works for now.
type TextInputType = 'currency' | 'text' | 'percent' | 'numeric';

export interface TextInputHQProps {
  onPress?: () => void;
  label?: string;
  explanation?: Explanations;
  type?: TextInputType;
  containerStyle?: StyleProp<ViewStyle>;
  value?: number | string;
}

type CustomTextInputProps = Omit<TextInputProps, 'value'>;

type TextInputHQPropsAll = CustomTextInputProps & TextInputHQProps;

type ConfigMap = {[index in TextInputType]?: Intl.NumberFormatOptions};

const configurations: ConfigMap = {
  currency: {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  percent: {
    style: 'percent',
    maximumFractionDigits: 6,
  },
  numeric: {},
};

export const TextInput = forwardRef((props: TextInputHQPropsAll, ref: any) => {
  const {
    onPress,
    placeholder,
    style,
    containerStyle,
    label,
    type = 'text',
    explanation,
    value,
    onChangeText,
    ...rest
  } = props;
  const showExplanation = useShowExplanation(explanation);
  const rightIcon = useMemo(() => {
    if (explanation) {
      return (
        //@ts-ignore
        <RNPTextInput.Icon
          // icon={}
          // color={"#888"}
          name={'help-circle'}
          onPress={showExplanation}
          forceTextInputFocus={false}
        />
      );
    } else {
      return null;
    }
  }, [showExplanation]);
  const NumericInputComponent =
    type === 'percent' ? TextInputPercent : TextInputNumeric;
  return (
    <TouchableOpacity
      disabled={!onPress}
      onPress={onPress}
      style={[containerStyle, {paddingLeft: 0, marginLeft: 0}]}>
      <Surface style={{elevation: 2}}>
        {(type === 'text' && (
          <RNPTextInput
            value={value ?? ''}
            label={label}
            style={{
              backgroundColor: 'rgba(256,256,256,.8)',
              borderBottomWidth: 0,
            }}
            underlineColor={'transparent'}
            right={rightIcon}
            placeholder={placeholder}
            onChangeText={onChangeText}
            {...(rest as any)}
            ref={ref}
          />
        )) || (
          <NumericInputComponent
            style={{backgroundColor: 'rgba(256,256,256,.8)'}}
            value={(value && new Decimal(value)?.toString()) || undefined}
            label={label}
            right={rightIcon}
            TextInputComponent={RNPTextInput as any}
            format={configurations[type]}
            underlineColor={'transparent'}
            onChangeText={noop}
            //@ts-ignore this is handled fine - though look into changing
            onChangeValue={onChangeText}
            renderErrorMessage={false}
            {...rest}
            ref={ref}
          />
        )}
      </Surface>
    </TouchableOpacity>
  );
});
