import React from 'react';
import {TutorialText as TutorialTextBase} from 'react-native-onboard-tutorial';
import {useTheme} from '../theme';

export const TutorialText = props => {
  const theme = useTheme();
  return (
    <TutorialTextBase
      {...props}
      containerStyle={{backgroundColor: theme.colors.primary}}
      textStyle={{color: theme.colors.light}}></TutorialTextBase>
  );
};
