import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface UserState {
  user?: FirebaseAuthTypes.User;
  authToken?: string;
}

const initialState: UserState = {
  user: undefined,
  authToken: undefined,
};

const slice = createSlice({
  name: 'applicationuser',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<FirebaseAuthTypes.User>) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    signout: state => {
      state.user = null;
      state.authToken = null;
    },
  },
});

export const {setUser, setAuthToken, signout} = slice.actions;
export default slice.reducer;
