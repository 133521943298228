import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {searchGroupsAction} from './actions';

export const useSearchGroups = () => {
  const dispatch = useDispatch();
  return useCallback(
    (searchCriteria: string) => {
      dispatch(searchGroupsAction(searchCriteria));
    },
    [dispatch],
  );
};
