import React, {useCallback} from 'react';
import {ApolloProvider} from '@apollo/client';
import {AppNavigator} from './AppNavigation';
import {Explanation} from '@houseque/core/explanations';
import {ScenarioEdit} from '@houseque/core/scenarios';
import {TutorialProvider} from 'react-native-onboard-tutorial';
import {tutorial} from '@houseque/core/tutorial';
import {useLogEvent} from '@houseque/core/application/analytics';
import {useApolloClient} from '@houseque/core/apollo/useApolloClient';

export const MemberApp = () => {
  const logEvent = useLogEvent();
  const onTutorialEvent = useCallback(
    event => {
      logEvent('tutorial_action', {
        event,
      });
    },
    [logEvent],
  );
  const apolloClient = useApolloClient();
  if (!apolloClient) return null;

  return (
    <TutorialProvider tutorial={tutorial} onEvent={onTutorialEvent}>
      <ApolloProvider client={apolloClient}>
        <>
          <AppNavigator />
          <Explanation />
          <ScenarioEdit />
        </>
      </ApolloProvider>
    </TutorialProvider>
  );
};
