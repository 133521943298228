import {usePredictions} from '../usePredictions';
import {usePurchaseState} from '../usePurchaseState';
import {useLoanPayment} from './useLoanPayment';
import {useFullLoanAmount} from './useFullLoanAmount';
import {useRehab} from '../useRehab';
import {useAmortizationGenerator} from './useAmortization';
import {useMemo} from 'react';
import {calculatePayment, compound} from '@houseque/core/calculations';
import {AmortizationPayment, Mortgage} from '@houseque/types';

const defaultRefinance = false;
const defaultMortgage: any = {
  refinance: defaultRefinance,
};

export const useActualAmortization = () => {
  const {periodicPropertyAppraisal = 0} = usePredictions();
  const financing = usePurchaseState();
  const amount = useFullLoanAmount();
  const payment = useLoanPayment();
  const rehab = useRehab();
  const [primary = defaultMortgage as Mortgage] = financing.loans;
  const {refinance = undefined} = primary;
  const estimatedArv = rehab.arv! || financing.price!;
  const generateInitialAmortization = useAmortizationGenerator(
    primary.rateType,
  );
  const generateRefiAmortization = useAmortizationGenerator(
    refinance?.rateType,
  );

  //Combines the two amortizations into a true stream
  return useMemo(() => {
    let refiAm: AmortizationPayment[] = [];
    const initialAm = generateInitialAmortization({
      amount,
      rate: primary.rate,
      totalPayments: (primary?.term ?? 0) * 12,
      payment,
      armType: primary.armType!,
    });
    //Builds refi amortization based on variables.
    if (!!refinance) {
      const periodsBeforeRefinance = Math.floor(refinance.refinanceAfter / 12);
      const valueAtRefi = compound(
        estimatedArv,
        periodsBeforeRefinance,
        periodicPropertyAppraisal as number,
      );
      const refiAmount = valueAtRefi * (1 - refinance.downPayment);
      const refiPayment = calculatePayment(
        refinance.rate,
        refinance.term! * 12,
        refiAmount,
        refinance.interestOnly,
      );
      refiAm = generateRefiAmortization({
        amount: refiAmount,
        payment: refiPayment,
        rate: refinance.rate,
        totalPayments: refinance.term! * 12,
        armType: refinance.armType!,
      });
    }
    const refinanceAfter = refinance
      ? refinance.refinanceAfter
      : initialAm.length;
    return [
      ...initialAm.slice(0, refinanceAfter),
      ...(refinance ? refiAm : []),
    ];
  }, [
    amount,
    refinance,
    estimatedArv,
    periodicPropertyAppraisal,
    generateInitialAmortization,
    generateRefiAmortization,
    calculatePayment,
    payment,
    primary,
  ]);
};
