import {ApolloClient} from '@apollo/client';
import {useEffect, useState} from 'react';
import {getClient} from './index';

export const useApolloClient = () => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<unknown>>(
    undefined,
  );
  useEffect(() => {
    getClient().then(setApolloClient);
  }, [setApolloClient]);
  return apolloClient;
};
