import {removeStagedPhoto} from './slice';
import {useDispatch} from 'react-redux';
import {useCallback} from 'react';

export const useRemoveStagedPhoto = () => {
  const dispatch = useDispatch();
  return useCallback(
    (index: number) => {
      dispatch(removeStagedPhoto(index));
    },
    [dispatch],
  );
};
