import {useCallback} from 'react';
import {useOpenLink} from './useOpenLink';

interface Email {
  to: string;
  subject: string;
}

export const useSendEmail = () => {
  const openLink = useOpenLink();
  return useCallback(
    async ({to, subject}: Email) => {
      return await openLink({
        address: to,
        params: {subject},
        schema: 'mailto',
      });
    },
    [openLink],
  );
};
