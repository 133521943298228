import {call, takeEvery, put, takeLatest} from 'redux-saga/effects';
import analytics from '@react-native-firebase/analytics';
import {exceptionCaughtAction} from '../exceptions';
import {logLoginAction, logEventAction, logShareAction} from './actions';
import {Action} from '@reduxjs/toolkit';
import {setUser, signout} from '../user/slice';
import {userNavigation} from '@houseque/core/navigation';

function* handleSignout(_: ReturnType<typeof signout>) {
  return yield call(async () => {
    await analytics().resetAnalyticsData();
  });
}

function* handleUserChange({payload: user}: ReturnType<typeof setUser>) {
  if (user?.uid) {
    return yield call(async () => {
      await analytics().setUserId(user?.uid);
    });
  }
}

//Add back screen tracking

function* handleAnalyticsEvent(action: Action) {
  try {
    yield call(async () => {
      if (logEventAction.match(action)) {
        await analytics().logEvent(action.payload.name, action.payload.params);
      } else if (logLoginAction.match(action)) {
        await analytics().logLogin(action.payload);
      } else if (logShareAction.match(action)) {
        await analytics().logShare(action.payload);
      } else if (userNavigation.match(action)) {
        await analytics().logScreenView({
          screen_class: action.payload, // eslint-disable-line @typescript-eslint/camelcase
          screen_name: action.payload, // eslint-disable-line @typescript-eslint/camelcase
        });
      }
    });
  } catch (err) {
    yield put(exceptionCaughtAction(err));
  }
}

export default function* analyticsSaga() {
  yield takeEvery(
    [
      userNavigation.type,
      logEventAction.type,
      logShareAction.type,
      logLoginAction.type,
    ],
    handleAnalyticsEvent,
  );
  yield takeLatest(setUser.type, handleUserChange);
  yield takeLatest(signout.type, handleSignout);
}
