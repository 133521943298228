import {QueryOptions} from '@apollo/client';
import {
  Deal,
  IndividualPurchaseState,
  Mortgage,
  Prediction,
  PropertyDetails,
  PropertyState,
  ScenariosCategory,
} from '@houseque/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DealState} from 'src/member/screens/deal-analyzer/deal';
import {signout} from '../application/user/slice';
import {ComputedFinanceState, DealsState} from './deal';
import {defaultDeal} from './defaultDeal';
import {handleScenarioActions, updateScenario} from './utils';

const initialState: DealsState = {
  deals: [],
  loading: true,
  selectedDeal: defaultDeal,
  isSaving: false,
  selectedDealComputedFinancials: {},
  selectedDealEstimates: {
    capRate: 0,
    monthlyCashflow: 0,
    netOperatingIncome: 0,
  },
  offlineQueue: [],
};

const slice = createSlice({
  name: 'deal',
  initialState,
  reducers: {
    removeDealSuccess: (state, action: PayloadAction<string>) => {
      const index = state.deals.findIndex(deal => deal.id === action.payload);
      state.deals.splice(index, 1);
    },
    loadUserDeals: (state, _: PayloadAction<Partial<QueryOptions>>) => {
      state.loading = true;
    },
    loadUserDealsSuccess: (state, action: PayloadAction<Deal[]>) => {
      state.deals = action.payload;
      state.loading = false;
    },
    loadUserDealsError: (state, _: PayloadAction<Error>) => {
      state.loading = false;
    },
    saveDealSuccess: (state, action: PayloadAction<Deal>) => {
      state.selectedDeal = defaultDeal;
      const editIndex = state.deals?.findIndex(
        deal => deal.id === action.payload.id,
      );
      if (editIndex > -1) {
        state.deals[editIndex] = action.payload;
      } else {
        state.deals.push(action.payload);
      }
    },
    updateDealLocalData: (
      state,
      action: PayloadAction<Partial<DealState['local']>>,
    ) => {
      state.selectedDeal.local = {
        ...state.selectedDeal.local,
        ...action.payload,
      };
    },
    updateSelectedAddress: (
      state,
      action: PayloadAction<DealState['local']['address']>,
    ) => {
      state.selectedDeal.local.address = action.payload;
    },
    changeDealProperties: (state, action: PayloadAction<DealState>) => {
      state.selectedDeal = {
        ...state.selectedDeal,
        ...action.payload,
      };
    },
    changeDealName: (state, action: PayloadAction<string>) => {
      state.selectedDeal.name = action.payload;
    },
    setDeal: (state, action: PayloadAction<DealState | undefined>) => {
      state.selectedDeal = action.payload ?? defaultDeal;
    },
    updateFinancing: (
      state,
      action: PayloadAction<IndividualPurchaseState>,
    ) => {
      updateScenario(state.selectedDeal.financing, scenario => {
        scenario.value = {
          ...scenario.value,
          ...action.payload,
        };
      });
    },
    changeFinancing: (state, action: PayloadAction<Mortgage[]>) => {
      updateScenario(state.selectedDeal.financing, scenario => {
        scenario.value.loans = action.payload;
      });
    },

    removeLoan: (state, action: PayloadAction<boolean>) => {
      //payload here indicates whether or not to remove refinance
      updateScenario(state.selectedDeal.financing, scenario => {
        if (!action.payload) {
          scenario.value.loans = [];
          return;
        }
        scenario.value.loans.forEach(loan => {
          delete loan.refinance;
        });
      });
    },
    changePurchasePrice: (state, action: PayloadAction<number>) => {
      updateScenario(state.selectedDeal.financing, scenario => {
        scenario.value.price = action.payload;
      });
    },
    changePredictions: (state, action: PayloadAction<Prediction>) => {
      updateScenario(state.selectedDeal.predictions, scenario => {
        scenario.value = {
          ...scenario.value,
          ...action.payload,
        };
      });
    },
    changeIncome: (state, action: PayloadAction<Income[]>) => {
      updateScenario(state.selectedDeal.cashflow, scenario => {
        scenario.value.income = action.payload;
      });
    },
    changeExpenses: (state, action: PayloadAction<Expense[]>) => {
      updateScenario(state.selectedDeal.cashflow, scenario => {
        scenario.value.expenses = action.payload;
      });
    },
    upsertCashflow: (state, {payload}: PayloadAction<CashflowEntryBase>) => {
      updateScenario(state.selectedDeal.cashflow, scenario => {
        const list: CashflowEntryBase[] =
          payload.type === 'expense'
            ? scenario.value.expenses
            : scenario.value.income;
        const index = list.findIndex(item => item.tag === payload.tag);
        if (index > -1) {
          list.splice(index, 1);
        }
        list.push(payload);
      });
    },
    changePropertyDetails: (state, action: PayloadAction<PropertyDetails>) => {
      state.selectedDeal.property.details = {
        ...state.selectedDeal.property.details,
        ...action.payload,
      };
    },
    changeProperty: (state, action: PayloadAction<PropertyState>) => {
      state.selectedDeal.property = {
        ...state.selectedDeal.property,
        ...action.payload,
      };
    },
    changeRehab: (state, action: PayloadAction<RehabStateInstance>) => {
      updateScenario(state.selectedDeal.rehab, scenario => {
        scenario.value = {
          ...scenario.value,
          ...action.payload,
        };
      });
    },
    resetAnalysis: state => {
      state.selectedDeal = defaultDeal;
      state.selectedDealEstimates = defaultDeal.estimate;
    },
    computedFinancingUpdate: (
      state,
      action: PayloadAction<ComputedFinanceState>,
    ) => {
      state.selectedDealComputedFinancials = action.payload;
    },
    setCapRate: (state, action: PayloadAction<number>) => {
      state.selectedDealEstimates.capRate = action.payload;
    },
    setMonthlyCashflow: (state, action: PayloadAction<number>) => {
      state.selectedDealEstimates.monthlyCashflow = action.payload;
    },
    setMonthlyNetOperatingIncome: (state, action: PayloadAction<number>) => {
      state.selectedDealEstimates.netOperatingIncome = action.payload * 12;
      state.selectedDealEstimates.monthlyNetOperatingIncome = action.payload;
    },
    setMonthlyIncome: (state, action: PayloadAction<number>) => {
      state.selectedDealEstimates.grossIncome = action.payload * 12;
      state.selectedDealEstimates.grossMonthlyIncome = action.payload;
    },
    setMonthlyDebtService: (state, action: PayloadAction<number>) => {
      state.selectedDealEstimates.monthlyDebtService = action.payload;
      state.selectedDealEstimates.debtService = action.payload * 12;
    },
    getOfflineDeals: (state, action: PayloadAction<DealState[]>) => {
      state.offlineQueue = action.payload;
    },
    offlineDealRemoved: (state, action: PayloadAction<DealState[]>) => {
      state.offlineQueue = action.payload;
    },
    offlineDealSaved: (state, action: PayloadAction<DealState[]>) => {
      state.offlineQueue = action.payload;
    },
  },
  extraReducers: builder => {
    //Clear deals on signout
    builder.addCase(signout, state => {
      state.deals = [];
    });
    //Handle various scenario action such as changing scenarios etc.
    builder.addDefaultCase((state, action) => {
      const matches = action.type.match(
        /_SCENARIO_(financing|rehab|predictions|cashflow)/i,
      );
      if (matches) {
        const [, which] = matches;
        const category: ScenariosCategory = which.toLowerCase() as any;
        handleScenarioActions(category, state.selectedDeal[category], action);
      }
    });
  },
});

export const {
  removeDealSuccess,
  loadUserDeals,
  loadUserDealsSuccess,
  loadUserDealsError,
  saveDealSuccess,
  updateDealLocalData,
  updateSelectedAddress,
  changeDealProperties,
  changeDealName,
  setDeal,
  updateFinancing,
  changeFinancing,
  removeLoan,
  changePurchasePrice,
  changePredictions,
  changeIncome,
  changeExpenses,
  upsertCashflow,
  changePropertyDetails,
  changeProperty,
  changeRehab,
  resetAnalysis,
  computedFinancingUpdate,
  setCapRate,
  setMonthlyCashflow,
  setMonthlyNetOperatingIncome,
  setMonthlyIncome,
  setMonthlyDebtService,
  offlineDealRemoved,
  offlineDealSaved,
  getOfflineDeals,
} = slice.actions;
export default slice.reducer;
