import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {logLoginAction} from './actions';
import {FirebaseAnalyticsTypes} from '@react-native-firebase/analytics';

export const useLogLogin = () => {
  const dispatch = useDispatch();

  return useCallback(
    (loginEvent: FirebaseAnalyticsTypes.LoginEventParameters) => {
      dispatch(logLoginAction(loginEvent));
    },
    [dispatch],
  );
};
