import React, {useCallback, useState} from 'react';
import {Box, TextInput} from '@houseque/components';

interface CashflowOptionProps {
  selected?: boolean;
  option: CashflowOptions<any>;
  value?: CashflowEntryBase;
  onChange: (option: CashflowOptions<any>, value?: CashflowEntryBase) => void;
}

export const CashflowOption = (props: CashflowOptionProps) => {
  const {option, onChange, value = {amount: undefined}} = props;
  const [type] = useState<'percent' | 'currency'>(
    option.type === 'percent' ? 'percent' : 'currency',
  );
  const onChangeText = useCallback(
    text => {
      const actual: CashflowEntryBase = {
        tag: option.tag,
        amount: text!,
        amountType: option.type || 'fixed',
      };
      onChange(option, (!!text && actual) || undefined);
    },
    [option],
  );

  return (
    <Box
      width={'50%'}
      flexDirection={'column'}
      justifyContent={'space-between'}>
      <TextInput
        type={type}
        label={option.name}
        explanation={option.explanation}
        keyboardType={'decimal-pad'}
        value={!!value.amount ? value.amount + '' : undefined}
        onChangeText={onChangeText}
      />
    </Box>
  );
};
