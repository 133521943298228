import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {groupsErrorAction} from './actions';
import {removeDealFromGroup} from './groupsService';
import {Group} from './types';

export const useRemoveDealFromGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    async (group: Group, dealId: string) => {
      try {
        return await removeDealFromGroup(dealId, group.id);
      } catch (err) {
        dispatch(groupsErrorAction(err));
        throw err;
      }
    },
    [removeDealFromGroup],
  );
};
