import {createAction} from '@reduxjs/toolkit';
import {Deal} from '@houseque/types';
import {Group} from './types';

export const addMultipleDealsToGroupAction = createAction<{
  deals: Deal[];
  groupId: string;
}>('ADD_DEALS_TO_GROUP');

export const createGroupAction = createAction<Group>('CREATE_GROUP');
export const joinGroupAction = createAction<Group>('JOIN_GROUP');
export const deleteGroupAction = createAction<string>('DELETE_GROUP');
export const leaveGroupAction = createAction<Group>('LEAVE_GROUP');
export const groupsErrorAction = createAction<Error>('GROUPS_ERROR');

export const searchGroupsAction = createAction<string>('SEARCH_GROUPS');
