import React from 'react';
import {Deal} from '@houseque/types';
import {View} from 'react-native';
import {ReportLine} from './ReportLine';
import {SectionHeader} from './SectionHeader';
import {
  useLoanPayment,
  usePurchaseState,
} from '@houseque/core/deals/selectors/financing';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';
import {useFullLoanAmount} from '@houseque/core/deals/selectors/financing/useFullLoanAmount';

interface PurchaseDetailsProps {
  deal?: Deal;
}

export const PurchaseDetails = ({deal}: PurchaseDetailsProps) => {
  const purchase = usePurchaseState();
  const rehab = useRehab();
  const loanAmount = useFullLoanAmount();
  const payment = useLoanPayment();

  const {loans = []} = purchase;
  const [loan] = loans;

  return (
    <View>
      <SectionHeader>Puchase Details</SectionHeader>
      <ReportLine
        label={'Purchase Price'}
        value={purchase.price}
        type={'currency'}
      />
      <ReportLine
        label={'Closing Costs'}
        value={purchase.closingCosts}
        type={'currency'}
      />
      <ReportLine
        label={'Rehab Costs'}
        value={rehab.rehabCosts}
        type={'currency'}
      />
      {(loan && (
        <>
          <ReportLine
            label={'Down Payment'}
            value={loan.downPayment * purchase.price}
            type={'currency'}
          />
          <ReportLine
            label={'Full Loan Amount'}
            value={loanAmount}
            type={'currency'}
          />
          <ReportLine
            label={'Loan Payment'}
            value={payment}
            type={'currency'}
          />
          <ReportLine
            label={'Interest Rate'}
            value={loan.rate}
            type={'percent'}
          />
          <ReportLine label={'Term'} value={loan.term} type={'decimal'} />
        </>
      )) ||
        null}
    </View>
  );
};
