import React from 'react';
import {SafeAreaView} from 'react-native';
import {Text} from './Text';
import {useNetInfo} from '@react-native-community/netinfo';
import {Icon} from './Icon';
import {Box} from './Box';

export interface OnlineOnlyProps {
  OfflineComponent?: JSX.Element;
  children?: any;
}

export const OnlineOnly = ({
  OfflineComponent = <DefaultOfflineBanner />,
  children,
}: OnlineOnlyProps) => {
  const status = useNetInfo();
  if (status.isInternetReachable) {
    return children;
  }
  return (
    <>
      {OfflineComponent}
      {children}
    </>
  );
};

const DefaultOfflineBanner = () => {
  return (
    <SafeAreaView>
      <Box flexDirection={'row'} alignItems={'center'}>
        <Icon color={'grey1'} icon={'wifi-off'} />
        <Text variant={'callout'} color={'grey1'}>
          You're offline, this feature requires internet.
        </Text>
      </Box>
    </SafeAreaView>
  );
};
