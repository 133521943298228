import React, {useEffect, useState, useMemo} from 'react';
import {ActivityIndicator, ScrollView} from 'react-native';

import {usePropertyDetails} from './usePropertyDetails';
import {GoogleStreetView, Text, Box, TextNumeric} from '../../../components';
import {useNavigation} from '@react-navigation/core';
import {List, Button} from 'react-native-paper';
import {useUpsertExpense} from '../deal-analyzer/cashflow';
import {DealState} from '../deal-analyzer/deal';
import {ZillowLogo} from './ZillowLogo';
import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {useUpdateProperty} from '@houseque/core/deals/actions';

export const PropertyDetails = ({route: {params}}) => {
  const deal = useCurrentDeal();
  const [data, setData] = useState(undefined);

  const propertyDetails = usePropertyDetails(params?.parcelId + '');
  useEffect(() => {
    if (propertyDetails.data?.parcelDetails) {
      const property = propertyDetails.data?.parcelDetails.reduce(
        (prev, curr) => {
          if (curr.taxYear > prev.taxYear ?? 0) {
            return curr;
          }
          return prev;
        },
        {taxYear: null},
      );
      setData(property);
    }
  }, [propertyDetails.loading, propertyDetails.data]);

  const {lat, long} = deal.property.location;

  return (
    <Box flex={1} paddingBottom={'xl'}>
      <GoogleStreetView lat={lat} long={long} />
      <ScrollView contentContainerStyle={{flexGrow: 1}}>
        <ParcelDetails
          deal={deal}
          loading={propertyDetails.loading}
          error={propertyDetails.error}
          parcel={data}
        />
        <ZillowLogo />
      </ScrollView>
    </Box>
  );
};

interface ParcelDetailsProps {
  parcel?: Parcel;
  loading: boolean;
  error: any;
  deal?: DealState;
}

const ParcelDetails = (props: ParcelDetailsProps) => {
  const upsertExpense = useUpsertExpense();
  const updateProperty = useUpdateProperty();

  const [building] = props.parcel?.building ?? [];
  const numBathrooms = useMemo(() => {
    if (!building) return undefined;
    const quarter = (building?.quarterBaths ?? 0) * 0.25;
    const half = (building?.halfBaths ?? 0) * 0.5;
    const threeQuarter = (building?.threeQuarterBaths ?? 0) * 0.75;
    const fullBaths = building?.fullBaths ?? 0;
    return building.baths ?? quarter + half + threeQuarter + fullBaths;
  }, [building]);

  const squareFeet = useMemo(() => {
    const {areas} = props.parcel ?? {};
    if (!areas) return undefined;
    const area = areas.find(a => {
      return a.type === 'Adjusted Building Area';
    });
    return area?.areaSquareFeet;
  }, [props.parcel]);

  const taxes = useMemo(() => {
    const {scenarios = [], currentScenarioIndex} = props.deal?.cashflow ?? {};
    const cashflow = scenarios[currentScenarioIndex]?.value;
    const taxExpense = cashflow?.expenses?.find(cflw => cflw.tag === 'taxes');
    return taxExpense?.amount * 12;
  }, [props.deal?.cashflow]);

  if (props.loading) {
    return <ActivityIndicator />;
  }
  if (props.error) {
    return <Text>We were unable to load the data</Text>;
  }

  return (
    <Box flex={1}>
      <Text variant={'headline'} padding={'m'}>
        {props.deal?.property?.address}
      </Text>
      <Detail
        title={'Square Feet'}
        value={squareFeet}
        previousValue={props.deal?.property?.sqFt}
        onImport={sqFt => updateProperty({sqFt})}
      />
      <Detail
        type={'currency'}
        title={`Annual Taxes (${props.parcel?.taxYear})`}
        value={+props.parcel?.taxAmount}
        previousValue={taxes}
        onImport={value => upsertExpense(+value, 'taxes', 'annual')}
      />
      <Detail title={'Bedrooms'} value={building?.bedrooms} />
      <Detail title={'Bathrooms'} value={numBathrooms} />
      <Detail title={'Units'} value={building?.noOfUnits} />
      <Detail title={'Stories'} value={building?.totalStories} />
    </Box>
  );
};

interface DetailsProps {
  onImport?: (value: any) => void;
  previousValue?: any;
  title: string;
  type?: 'currency' | 'percent' | 'decimal';
  value?: any;
}

const Detail = ({
  title,
  value,
  onImport,
  type = 'decimal',
  previousValue,
}: {title: string; value?: any} & DetailsProps) => {
  const right = useMemo(() => {
    if (!onImport) return undefined;
    if (previousValue == null || value !== previousValue)
      return () => <Button onPress={() => onImport(value)}>Import</Button>;
    return () => <List.Icon icon={'check'} />;
  }, [onImport, previousValue, value]);
  if ((value ?? false) === false) {
    return null;
  }
  return (
    <List.Item
      title={title}
      description={() => <TextNumeric type={type} value={value} />}
      right={right}
    />
  );
};

const formatCurrency = (num: string) => {
  const formatter = Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
  });
  if (num) {
    return formatter.format(+num);
  }
  return num;
};
