import {createAction} from '@reduxjs/toolkit';

import {FirebaseAnalyticsTypes} from '@react-native-firebase/analytics';
import {LogEvent} from './types';

export const logScreenView = createAction<{
  screen_name: string;
  screen_class: string;
}>('LOG_CURRENT_SCREEN');
export const logLoginAction = createAction<FirebaseAnalyticsTypes.LoginEventParameters>(
  'LOG_LOGIN',
);
export const logShareAction = createAction<FirebaseAnalyticsTypes.ShareEventParameters>(
  'LOG_SHARE',
);
export const logEventAction = createAction<LogEvent>('LOG_EVENT');
