import {useSelector} from 'react-redux';
import {useCallback} from 'react';
import {HouseQueAppState} from '../../../houseque';
import {selectCurrentScenario} from '../../scenarios';

export const useRehab = () => {
  const rehab = useSelector(
    useCallback((app: HouseQueAppState) => {
      return selectCurrentScenario(app.deals.selectedDeal.rehab);
    }, []),
  );
  return rehab;
};
