import storage from '@react-native-firebase/storage';
import {useCallback} from 'react';

export const uploadFile = async (filePath: string, targetPath: string) => {
  const uploader = storage().ref(targetPath);
  await uploader.putFile(filePath);
  const url = await uploader.getDownloadURL();
  return url;
};

export const useFileUpload = () => {
  return useCallback(async (filePath: string, targetPath: string) => {
    return await uploadFile(filePath, targetPath);
  }, []);
};
