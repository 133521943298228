import {DealState} from '../deal';
import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';

const defaultDeals: DealState[] = [];

export const useUserDeals = () => {
  const {deals = defaultDeals, loading} = useSelector(
    (state: HouseQueAppState) => {
      return state.deals;
    },
  );
  return {deals, loading};
};
