import {useNavigation} from '@react-navigation/core';
import React, {useCallback, useEffect, useState} from 'react';
import {Alert, SafeAreaView} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {ActivityIndicator, Searchbar} from 'react-native-paper';
import {Box, DismissKeyboardView, Link, Text} from '../../../components';
import {useCurrentUser} from '@houseque/core/application/user';
import {GroupTile} from './GroupTile';
import {GroupState} from './slice';
import {Group} from './types';
import {useJoinGroup} from './useJoinGroup';
import {useSearchGroups} from './useSearchGroups';
import {useSearchResults} from './useSearchResults';

const MINIMUM_CHARACTERS_FOR_SEARCH = 2;

export const GroupSearch = () => {
  const [searchCriteria, setSearchCriteria] = useState('');

  const navigation = useNavigation();
  const searchGroups = useSearchGroups();
  useEffect(() => {
    if (searchCriteria.length >= MINIMUM_CHARACTERS_FOR_SEARCH) {
      searchGroups(searchCriteria);
    }
  }, [searchCriteria, searchGroups]);

  const searchResults = useSearchResults();

  navigation.setOptions({
    title: 'Seach Groups',
  });

  return (
    <DismissKeyboardView>
      <SafeAreaView style={{flex: 1}}>
        <Box margin={'m'}>
          <Searchbar
            value={searchCriteria}
            onChangeText={setSearchCriteria}
            placeholder={'Search for a group by name'}
          />
        </Box>
        {((searchCriteria?.length >= MINIMUM_CHARACTERS_FOR_SEARCH ||
          searchResults.searchResults) && (
          <SearchResultsDisplay
            searchStatus={searchResults}
            searchCriteria={searchCriteria}
          />
        )) || (
          <Text margin={'m'}>
            Type the name of a group and we will look for it
          </Text>
        )}
      </SafeAreaView>
    </DismissKeyboardView>
  );
};

const SearchResultsDisplay = ({
  searchStatus,
  searchCriteria,
}: {
  searchStatus: GroupState;
  searchCriteria: string;
}) => {
  const navigation = useNavigation();
  const onJoinGroup = useCallback((group: Group) => {
    navigation.goBack();
  }, []);
  const user = useCurrentUser();
  const onJoinGroupError = useCallback((error: any) => {
    Alert.alert(
      'Unable to Join...',
      'Looks like something went wrong on our end, our engineers are taking a look now.',
    );
  }, []);

  if (searchStatus.searching) {
    return (
      <Box flex={1}>
        <ActivityIndicator />
      </Box>
    );
  }
  if (searchStatus.searchError) {
    return (
      <Text margin={'m'} variant={'caption1'} color={'grey1'}>
        We encountered an error, we are investigating!
      </Text>
    );
  }
  return (
    <FlatList
      keyboardShouldPersistTaps={'never'}
      ListEmptyComponent={
        <Text marginHorizontal={'m'}>No results for "{searchCriteria}"</Text>
      }
      data={
        searchStatus?.searchResults ??
        [].filter((group: Group) => !group.members?.[user.uid])
      }
      renderItem={data => {
        return (
          <GroupTile group={data.item}>
            <Box paddingLeft={'s'}>
              <JoinGroupButton
                group={data.item}
                onJoin={onJoinGroup}
                onError={onJoinGroupError}
              />
            </Box>
          </GroupTile>
        );
      }}
    />
  );
};

const JoinGroupButton = ({
  group,
  onJoin,
  onError,
}: {
  group: Group;
  onJoin: (group: Group) => void;
  onError: (error: any) => void;
}) => {
  const [joining, setJoining] = useState(false);
  const joinGroup = useJoinGroup();

  const onPress = useCallback(async () => {
    try {
      setJoining(true);
      await joinGroup(group);
      onJoin(group);
    } catch (err) {
      onError(err);
    }
    setJoining(false);
  }, [joinGroup, setJoining]);

  if (joining) {
    return (
      <Text color={'grey3'} variant={'button'}>
        Joining...
      </Text>
    );
  }
  return <Link title={'Join Group'} onPress={onPress} />;
};
