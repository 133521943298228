import AsyncStorage from '../../storage';
import {DealState} from '../deal';
import {generateKey} from '../../shared';

const offlineKey = 'hq-offline-deals';
export const offlineIdPrefix = 'offline-';

export const getCurrentOfflineDeals = async () => {
  const deals: DealState[] = JSON.parse(
    (await AsyncStorage.getItem(offlineKey)) ?? '[]',
  );
  return deals;
};

export const saveChanges = async (deals: DealState[]) => {
  await AsyncStorage.setItem(offlineKey, JSON.stringify(deals));
};

/**
 * Saves the deal, and returns the current state to prevent duplicating logic in reducer.
 * @param deal Deal to save for offline
 */
export const upsertOfflineDeal = async (deal: DealState) => {
  if (!deal.id) {
    deal.id = `${offlineIdPrefix}${generateKey()}`;
  }
  const deals = await getCurrentOfflineDeals();
  const toSave = [...deals.filter(current => current.id !== deal.id), deal];
  await saveChanges(toSave);
  return toSave;
};

/**
 * Removes the deal, and returns the current state to prevent duplicating logic in reducer.
 * @param deal Deal to remove
 */
export const deleteOfflineDeal = async (deal: DealState) => {
  const currentDeals = await getCurrentOfflineDeals();
  const toSave = currentDeals.filter(current => current.id !== deal.id);
  await saveChanges(toSave);
  return toSave;
};
