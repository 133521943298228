import {useEffect, useState} from 'react';
import {loadGroup} from './groupsService';
import {Group} from './types';

export const useGroup = (groupId: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [group, setGroup] = useState<Group>(undefined);

  useEffect(() => {
    setError(undefined);
    setLoading(true);
    loadGroup(groupId)
      .then(group => {
        setGroup(group);
      })
      .catch(err => {
        setError(err);
      })
      .then(() => {
        setLoading(false);
      });
  }, [groupId]);
  return {loading, error, data: group};
};
