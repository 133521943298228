import {useLazyQuery, useQuery, gql} from '@apollo/client';

const LoadGroupMembers = gql`
  query loadUsers($userIds: [ID]) {
    loadUsers(userIds: $userIds) {
      photoURL
      displayName
    }
  }
`;

export interface LoadUsersQueryResult {
  loadUsers: {displayName: string; photoURL: string}[];
}

export const useLoadGroupMembers = () => {
  return useLazyQuery<LoadUsersQueryResult>(LoadGroupMembers, {
    fetchPolicy: 'cache-and-network',
  });
};
