import React, {useCallback, useEffect, useMemo} from 'react';
import Image from 'react-native-fast-image';
import {FlatList, StyleSheet, TouchableHighlight, View} from 'react-native';

import {Text} from '../../components';
import {useStagedPhotos} from './useStagedPhotos';
import {useRemoveStagedPhoto} from './useRemoveStagedPhoto';

const styles = StyleSheet.create({
  savedImage: {
    alignSelf: 'stretch',
    flex: 1,
    width: undefined,
    maxWidth: 200,
    minWidth: 100,
    height: 100,
    marginRight: 10,
  },
  pressableImage: {alignSelf: 'stretch', flex: 1},
});

interface StagedPhotosProps {
  onChangeStagedPhotos?: (photos: any[]) => void;
  containerStyle?: any;
  maxPhotos?: number;
}

export const StagedPhotos = ({
  maxPhotos = 5,
  onChangeStagedPhotos,
}: StagedPhotosProps) => {
  const stagedPhotos = useStagedPhotos();
  const remainingPhotos = useMemo(() => {
    return maxPhotos - stagedPhotos.length;
  }, [maxPhotos, stagedPhotos.length]);
  const removeStagedPhoto = useRemoveStagedPhoto();

  useEffect(() => {
    onChangeStagedPhotos?.(stagedPhotos);
  }, [stagedPhotos]);

  const renderStaged = useCallback(
    ({item: {path, height, width}, index}) => {
      return (
        <Image
          resizeMode={'contain'}
          style={styles.savedImage}
          source={{uri: path}}>
          <TouchableHighlight
            style={styles.pressableImage}
            underlayColor={'rgba(256,256,256,.3)'}
            onLongPress={() => removeStagedPhoto(index)}>
            <View />
          </TouchableHighlight>
        </Image>
      );
    },
    [styles.savedImage, styles.pressableImage, removeStagedPhoto],
  );

  return (
    <FlatList
      horizontal
      data={stagedPhotos}
      ListEmptyComponent={useMemo(
        () => (
          <Text variant={'caption1'} color={'grey2'}>
            No photos staged, add up to {remainingPhotos} more.
          </Text>
        ),
        [remainingPhotos],
      )}
      renderItem={renderStaged}
    />
  );
};
