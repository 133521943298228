import {firebase} from '@react-native-firebase/storage';
import {useCallback} from 'react';

export const useRemovePropertyPhoto = () => {
  return useCallback((url: string) => {
    const [encodedPath] = url.split('/').pop()?.split('?') ?? [];
    const path = decodeURIComponent(encodedPath);
    const ref = firebase.storage().ref(path);
    return ref.delete();
  }, []);
};
