import React, {useCallback, useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {Box, TextInput} from '../../../../components';
import {
  useCashflow,
  useUpsertExpense,
} from '../../../../core/deals/selectors/cashflow';
import {Loans} from './components/Loans';
import {
  useClosingCosts,
  useUpdateFinancing,
  usePurchaseState,
} from '../../../../core/deals/selectors/financing';
import {useUpdatePrice} from '../../../../core/deals/actions/useUpdatePrice';

const styles = StyleSheet.create({
  input: {
    flex: 1,
  },
});

const expensesToExtract: {[index in ExpenseTag]?: boolean} = {
  insurance: true,
  taxes: true,
};

export const PurchaseSection = () => {
  const {expenses} = useCashflow();
  const [taxes, setTaxes] = useState('');
  const [insurance, setInsurance] = useState('');
  const updateFinancing = useUpdateFinancing();

  const closingCosts = useClosingCosts();
  const purchase = usePurchaseState();
  const updateClosingCosts = useCallback(
    closingCosts => {
      updateFinancing({
        closingCosts,
      });
    },
    [updateFinancing],
  );

  const upsertExpense = useUpsertExpense();
  const updatePrice = useUpdatePrice();
  const onPriceChange = updatePrice;

  //TODO - Move these next 3 elsewhere, logic is a bit fuzzy - maybe cashflow?
  useEffect(() => {
    const extractions = expenses.reduce((accumulator: any, expense) => {
      if (expensesToExtract[expense.tag]) {
        accumulator[expense.tag] = expense.amount;
      }
      return accumulator;
    }, {});
    extractions.taxes && setTaxes(extractions.taxes * 12 + '');
    extractions.insurance && setInsurance(extractions.insurance * 12 + '');
  }, [expenses]);

  const updateTaxes = useCallback(
    tax => {
      upsertExpense(tax, 'taxes', 'annual');
      setTaxes(tax);
    },
    [setTaxes],
  );

  const updateInsurance = useCallback(
    insurance => {
      upsertExpense(insurance, 'insurance', 'annual');
      setInsurance(insurance);
    },
    [setInsurance],
  );
  return (
    <>
      <TextInput
        type={'currency'}
        label={'Purchase Price'}
        value={purchase.price! as any}
        keyboardType={'decimal-pad'}
        onChangeText={onPriceChange}
      />
      <TextInput
        type={'currency'}
        explanation={'closingcosts'}
        value={closingCosts.amount as any}
        keyboardType={'decimal-pad'}
        label={'Closing Costs'}
        onChangeText={updateClosingCosts}
      />
      <TextInput
        containerStyle={styles.input}
        type={'currency'}
        value={taxes}
        keyboardType={'decimal-pad'}
        label={'Annual Taxes'}
        onChangeText={updateTaxes}
      />
      <TextInput
        containerStyle={styles.input}
        type={'currency'}
        value={insurance}
        keyboardType={'decimal-pad'}
        label={'Annual Insurance'}
        onChangeText={updateInsurance}
      />
      <Box paddingTop={'m'}>
        <Loans />
      </Box>
      <Box paddingTop={'m'} />
    </>
  );
};
