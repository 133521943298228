/**
 *
 * @param interestRate
 * @param totalPayments
 * @param loanAmount
 * @param paymentFrequency
 */
export const calculatePayment = (
  interestRate: number,
  totalPayments: number,
  loanAmount: number,
  interestOnly = false,
  paymentFrequency = 1,
) => {
  const periodInterest = interestRate / (12 * paymentFrequency);
  if (interestOnly) {
    return periodInterest * loanAmount;
  }
  const discountNumerator = (1 + periodInterest) ** totalPayments - 1;
  const discountDenominator =
    periodInterest * (periodInterest + 1) ** totalPayments;
  const discountFactor = discountNumerator / discountDenominator;
  return loanAmount / discountFactor;
};
