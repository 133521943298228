import {useSelector} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {HouseQueAppState} from '../../../houseque';
import {selectCurrentScenario} from '../../scenarios';

type CashflowLabels = {
  [index in IncomeTag | ExpenseTag]: string;
};

const cashflowLabels: CashflowLabels = {
  rent: 'Rent',
  repairs: 'Maintenance',
  management: 'Property Management',
  pmi: 'Private Mortgage Insurance',
  other: 'Other',
  insurance: 'Property Insurance',
  taxes: 'Property Taxes',
  capex: 'Capital Expenditures',
  vacancy: 'Vacancy',
  electric: 'Electric',
  hoa: 'Home Owners Association',
  sanitation: 'Sanitation',
  water: 'Utilities',
};

/**
 * @returns {IndividualCashflowState}
 */
export const useCashflow = () => {
  const cashflowState = useSelector(
    useCallback(
      (store: HouseQueAppState) => store.deals.selectedDeal.cashflow,
      [],
    ),
  );
  return useMemo(() => {
    const currentCashflow = selectCurrentScenario(cashflowState);
    return {
      ...currentCashflow,
      income: currentCashflow.income.map(inc => {
        return {
          ...inc,
          name: cashflowLabels[inc.tag],
        };
      }),
      expenses: currentCashflow.expenses.map(exp => {
        return {
          ...exp,
          name: cashflowLabels[exp.tag],
        };
      }),
    };
  }, [cashflowState]);
};
