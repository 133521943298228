import {useCallback} from 'react';
import {DealState} from '../deal';
import {useDispatch} from 'react-redux';
import {saveOfflineDeal} from './actions';

export const useSaveOfflineDeal = () => {
  const dispatch = useDispatch();
  return useCallback(
    (deal: DealState) => {
      const action = saveOfflineDeal(deal);
      dispatch(action);
    },
    [dispatch],
  );
};
