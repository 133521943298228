import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import {StyleSheet, Alert, ViewToken, FlatList, Platform} from 'react-native';
import {Divider} from 'react-native-paper';
import {
  Text,
  Box,
  Fab,
  KeyboardAvoidingView,
  UserConditional,
} from '@houseque/components';
import {DealAnalyzerPagination} from './components/DealAnalyzerPagination';
import {PropertySection} from './property';
import {PurchaseSection} from './financing';
import {RehabForm} from './rehab';
import {CashflowSection} from './cashflow';
import {EstimatesSection} from './estimates';
import {SaveDeal} from './components/SaveDeal';
import {useAnalyzeExistingDeal} from '@houseque/core/deals/actions/useAnalyzeExistingDeal';
import {useStyle} from '@houseque/core/theme';
import {Card} from 'react-native-elements';
import {RouteProp} from '@react-navigation/core';
import {useDispatch} from 'react-redux';
import {ScenariosCategory} from '@houseque/types';
import {StackParams} from '../../nav';
import {NativeStackNavigationProp} from 'react-native-screens/native-stack';
import {TutorialText, TutorialHighlight} from '@houseque/core/tutorial';
import {TutorialControls, TutorialTextOutlet} from './components';
import {useLaunchCameraRole} from '@houseque/core/photos';
import {useSafeArea} from 'react-native-safe-area-context';
import {useStageLocalPhotos} from '@houseque/core/photos/useStageLocalPhotos';
import {CheckParcelAvailability} from '../property-details';
import {Scenarios} from '@houseque/core/scenarios';
import {resetAnalysis} from '@houseque/core/deals';

const isWeb = Platform.OS === 'web';

interface ViewableChangeEvent {
  viewableItems: ViewToken[];
  changed: ViewToken[];
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
});

const ItemSeparatorComponent = () => (
  <Box marginVertical={'xl'} paddingRight={'m'}>
    <Divider />
  </Box>
);

interface SectionInput {
  title: string;
  icon?: string;
  tutorial?: {
    explainScenarios?: boolean;
    stepId: string;
  };
  scenario?: ScenariosCategory;
  text: string;
  component: () => JSX.Element;
  subtitle?: () => JSX.Element;
}

const inputs: SectionInput[] = [
  {
    title: 'Property',
    component: PropertySection,
    text: 'Property',
    icon: 'place',
  },
  {
    title: 'Financing',
    tutorial: {
      explainScenarios: true,
      stepId: 'purchase',
    },
    component: PurchaseSection,
    icon: 'account-balance',
    text: 'Financing',
    scenario: 'financing',
  },
  {
    title: 'Operating Cashflow',
    tutorial: {
      stepId: 'cashflow',
    },
    component: CashflowSection,
    text: 'Cashflow',
    scenario: 'cashflow',
  },
  {
    title: 'Rehab',
    tutorial: {
      stepId: 'rehab',
    },
    component: RehabForm,
    text: 'Rehab',
    scenario: 'rehab',
  },
];

type DealAnalyzerScreenProps = NativeStackNavigationProp<StackParams, 'deal'>;

interface DealAnalyzerProps {
  navigation: DealAnalyzerScreenProps;
  route: RouteProp<StackParams, 'deal'>;
}

export const DealAnalyzer = ({
  navigation,
  route: {params},
}: DealAnalyzerProps) => {
  let deal = params?.deal;
  //TODO try to remove this?
  if (typeof deal === 'string' && deal) {
    deal = JSON.parse(deal);
  }

  const safeArea = useSafeArea();
  const dispatch = useDispatch();
  const scrollRef = useRef<FlatList<any>>(undefined);
  const [activeIndex, setActiveIndex] = useState(0);
  const setCurrentDeal = useAnalyzeExistingDeal();
  const stageLocalPhotos = useStageLocalPhotos();

  //Set the current deal, maybe fix this?
  useMemo(() => {
    if (deal) {
      setCurrentDeal(deal);
    } else {
      dispatch(resetAnalysis());
    }
  }, []);

  const onSave = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSaveError = useCallback(() => {
    Alert.alert(
      'Unable to save',
      `We aren't able to save the deal, please try again.`,
    );
  }, []);
  useEffect(() => {
    stageLocalPhotos(deal?.local?.photos ?? []);
  }, [deal, stageLocalPhotos]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <UserConditional
          condition={user => {
            const isMyDealOrNoUser =
              user.uid === (deal?.createdBy?.id ?? user.uid);
            return isMyDealOrNoUser;
          }}
          TrueComponent={
            <TutorialHighlight emphasisScale={1.2} stepId={'save'}>
              <SaveDeal onSave={onSave} onError={onSaveError} />
            </TutorialHighlight>
          }
        />
      ),
    });
  }, [onSave, navigation, deal]);

  const stylesWithTheme = useStyle(
    ({edge: {medium, large}, colors: {light, grey5, primary}}) => ({
      tutorialOutlet: {
        paddingVertical: 10,
        backgroundColor: primary,
      },
      primaryContainer: {
        backgroundColor: grey5,
      },
      screen: {
        marginLeft: medium,
      },
      scrollArea: {
        paddingTop: medium, //Remove android
      },
      header: {
        margin: 0,
        paddingTop: medium,
        backgroundColor: light,
        paddingHorizontal: medium,
      },
      footer: {
        paddingTop: medium,
      },
    }),
  );
  const renderItem = useCallback(
    ({item, index}: {item: SectionInput; index: number}) => {
      const Component = item.component;
      const SubTitle = item.subtitle;
      const ScenarioWrapper = item.tutorial?.explainScenarios
        ? TutorialHighlight
        : React.Fragment;
      const scenarioProps = item.tutorial?.explainScenarios
        ? {stepId: 'scenarios', skip: !item.tutorial?.explainScenarios}
        : {};
      return (
        <Box paddingTop={'m'}>
          <Text variant={'title1'}>{item.title}</Text>
          {(SubTitle && <SubTitle />) || null}
          {item.scenario && (
            <ScenarioWrapper {...scenarioProps}>
              <Scenarios
                category={item.scenario}
                title={`${item.title} Scenario`}
                explanation={'scenarios'}
              />
            </ScenarioWrapper>
          )}
          <Box marginRight={'m'}>
            <TutorialText
              stepId={item?.tutorial?.stepId}
              onEnter={() => {
                scrollRef.current.scrollToIndex({
                  index,
                });
              }}>
              <Component />
            </TutorialText>
          </Box>
        </Box>
      );
    },
    [],
  );
  const onPressPagination = useCallback(index => {
    scrollRef.current.scrollToIndex({
      index,
    });
  }, []);
  const launchCameraRoll = useLaunchCameraRole();
  const keyExtractor = useCallback(item => item.title, []);
  const onViewableItemsChanged = useCallback(
    ({viewableItems, changed}: ViewableChangeEvent) => {
      const [inView] = viewableItems;
      if (inView) {
        setActiveIndex(inView.index!);
      }
    },
    [],
  );
  return (
    <Box flex={1}>
      <KeyboardAvoidingView
        keyboardVerticalOffset={-10}
        style={styles.flex}
        contentContainerStyle={styles.flex}
        behavior={'position'}>
        <TutorialHighlight stepId={'estimatepane'} emphasisScale={1.05}>
          <Box paddingHorizontal={'s'} style={{backgroundColor: 'transparent'}}>
            <EstimatesSection />
          </Box>
        </TutorialHighlight>
        <TutorialTextOutlet />

        <FlatList
          initialNumToRender={0}
          maxToRenderPerBatch={1}
          updateCellsBatchingPeriod={60}
          keyboardDismissMode={'none'}
          keyboardShouldPersistTaps={'handled'}
          ref={ref => (scrollRef.current = ref as any)}
          data={inputs}
          onScrollToIndexFailed={() => {
            console.warn('whoops');
          }}
          contentContainerStyle={[
            stylesWithTheme.scrollArea,
            stylesWithTheme.screen,
          ]}
          ListFooterComponent={<Box marginVertical={'xl'} />}
          viewabilityConfig={{viewAreaCoveragePercentThreshold: 50}}
          onViewableItemsChanged={onViewableItemsChanged}
          ItemSeparatorComponent={ItemSeparatorComponent as any}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
        />

        <Box
          pointerEvents={'box-none'}
          position={'absolute'}
          bottom={70 + safeArea.bottom}
          left={0}
          right={0}>
          {(!isWeb && (
            <Fab
              containerStyle={{position: 'relative', alignSelf: 'flex-end'}}
              onPress={launchCameraRoll}
              avoidKeyboard={false}
              icon={'camera'}
            />
          )) ||
            null}
          <TutorialControls />
        </Box>
        <Card
          containerStyle={{
            padding: 0,
            margin: 0,
            paddingBottom: safeArea.bottom,
          }}>
          <Box>
            <DealAnalyzerPagination
              options={inputs}
              activeSlide={activeIndex}
              onPress={onPressPagination}
            />
          </Box>
        </Card>
        <CheckParcelAvailability />
      </KeyboardAvoidingView>
    </Box>
  );
};
