import {useNetInfo} from '@react-native-community/netinfo';
import {useCallback} from 'react';

export const useOfflineFallback = <T>(primary: T, backup: T) => {
  const status = useNetInfo();
  return useCallback(
    status.isInternetReachable ? (primary as any) : (backup as any),
    [status.isInternetReachable],
  );
};
