import {Deal, Prediction} from '@houseque/types';
import moment from 'moment';
import {useEstimates} from '@houseque/core/deals/selectors/useEstimates';
import {useCallback} from 'react';
import {useProjectionsOverTime, Projection} from '../useOverTimeProjections';
import {shareLink} from '../shareLink';
import {useYears} from '../useYears';
import {styles} from './styles';
import {Fields, FieldDef, Section, SectionNew, format} from './Common';
import {ProjectionTable} from './Projections';
import {useCurrentDeal} from '@houseque/core/deals/selectors';
import {useCapRate} from '../../deal-analyzer/estimates/component';
import {useFullLoanAmount} from '@houseque/core/deals/selectors/financing/useFullLoanAmount';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';
import {usePredictions} from '@houseque/core/deals/selectors/usePredictions';
import {
  useLoanPayment,
  useOutOfPocket,
  usePurchaseState,
} from '@houseque/core/deals/selectors/financing';
import {useCashflow} from '@houseque/core/deals/selectors/cashflow';

const loanExpenseTypes: {[index in ExpenseTag]?: boolean} = {
  pmi: true,
};

const ReportHeader = (deal: Deal) => {
  const header = `
        <h1 class="logo">House<span class="suffix">Que</span></h1>
        <h5>${deal.name}  &#183;  ${
    deal.property.address
  }  &#183;  ${moment().format('MMMM DD, YYYY')}</h5>`;
  return header;
};

const EstimateSection = (estimates: [string, string][]) => {
  return `<div class="row border-top padding-10 border-bottom space-between">
        ${estimates
          .map(([key, value]) => {
            return `<div class="container column center"><small>${key}</small><b>${value}</b></div>`;
          })
          .join('')}
</div>`;
};

const PurchaseDetails = (fields: Fields[]) => {
  return ` <h3>Purchase Details</h3>
            ${Section(fields, ['width-33'])}
    `;
};

const CashflowDetails = (expenses: FieldDef[], income: FieldDef[]) => {
  return `
    <div class="row start" style="margin-top:15px">
        <div class="flex-1">
            <h3 class="no-top-margin">Income</h5>
            ${SectionNew(income)}
        </div>
        <div class="flex-tenth"></div>
        <div class="flex-1">
            <h3 class="no-top-margin">Expenses</h5>
            ${SectionNew(expenses)}
        </div>
    </div>`;
};

const ReportLink = (deal: Deal) => {
  const href = shareLink(deal, true);
  return `<a href="${href}">View on HouseQue</a>`;
};

const AddressLink = (deal: Deal) => {
  if (!deal.property.location) {
    return '';
  }
  const link = `https://maps.google.com/?q=${deal.property.address}`;
  return `<a href="${link}">View on Google Maps</a>`;
};

const Footer = () => {
  return `
        <footer style="padding: 25 10 10 10" class="row space-between">
            <p>HouseQue Solutions LLC</p>
            <a href="https://www.houseque.com">www.houseque.com</a>
            <a href="https://www.youtube.com/channel/UChRzqlye7AKKI_vXaxN6GYw">Youtube</a>
        </footer>
    `;
};

const reportHtml = (...body: string[]) => {
  return `<html>
    <head>
        ${styles}
    </head>
    <body>
        ${body.join('')}
    </body>
    ${Footer()}
    <footer class="padding-10">
        <em>Report generated ${moment().format('MM/DD/YYYY hh:mm:ssA')}</em>
    </footer>
</html>`;
};

export const useGenerateHtml = () => {
  const deal = useCurrentDeal();
  const estimates = useEstimates();
  const outOfPocket = useOutOfPocket();
  const capRate = useCapRate();
  const purchase = usePurchaseState();
  const payment = useLoanPayment();
  const loanAmount = useFullLoanAmount();
  const rehab = useRehab();
  const cashflow = useCashflow();
  const projectedReturns = useProjectionsOverTime();
  const years = useYears();
  const assumptions = usePredictions();

  return useCallback(() => {
    const rent = cashflow.income.reduce((sum, inc) => {
      if (inc.tag === 'rent') {
        sum += inc.amount;
      }
      return sum;
    }, 0);

    const header = ReportHeader(deal);
    const estimate = EstimateSection([
      ['NOI', format(estimates.netOperatingIncome, 'currency')],
      [
        'Cash on Cash',
        format((estimates.monthlyCashflow * 12) / outOfPocket, 'percent'),
      ],
      ['Cap Rate', format(capRate, 'percent')],
      ['Cash Up Front', format(outOfPocket, 'currency')],
    ]);

    const purchaseFieldsAddtl: Fields[][] = (purchase.loans ?? []).map(loan => {
      return [
        ['Down Payment', loan.downPayment * purchase.price, 'currency'],
        ['Full Loan Amount', loanAmount, 'currency'],
        ['Loan Payment', payment, 'currency'],
        ['Interest Rate', loan.rate, 'percent'],
        ['Term', loan.term],
      ] as any;
    });

    const purchaseDetails = PurchaseDetails([
      ['Purchase Price', purchase.price, 'currency'],
      ['Closing Costs', purchase.closingCosts, 'currency'],
      ['Rehab Costs', rehab.rehabCosts, 'currency'],
      ['Monthly Cashflow', estimates.monthlyCashflow, 'currency'],
      ...(purchaseFieldsAddtl[0] || []),
    ]);

    // Generate expenses
    const operatingExpenses: FieldDef[] = [];
    const loanExpenses: FieldDef[] = [];

    cashflow.expenses.forEach(expense => {
      const mapped: FieldDef = {
        label: expense.name,
        value:
          expense.amountType === 'percent'
            ? expense.amount * rent
            : expense.amount,
        type: 'currency',
      };
      if (loanExpenseTypes[expense.tag]) {
        loanExpenses.push(mapped);
      } else {
        operatingExpenses.push(mapped);
      }
    });
    if (payment) {
      loanExpenses.push({
        value: payment,
        label: 'Loan Payment',
        type: 'currency',
      });
    }
    const totalExpenses = [...operatingExpenses, ...loanExpenses].reduce(
      (sum, field) => {
        sum += +field.value;
        return sum;
      },
      0,
    );

    const mappedExpenses: FieldDef[] = [
      {label: 'Operating', value: '', classes: 'bold primary-text'},
      ...operatingExpenses,
      {label: 'Loan', value: '', classes: 'bold primary-text'},
      ...loanExpenses,
      {
        label: 'Total',
        value: totalExpenses,
        classes: 'bold',
        type: 'currency',
      },
    ];

    const mappedIncome = cashflow.income.map<FieldDef>(income => {
      return {label: income.name, value: income.amount, type: 'currency'};
    });
    const totalIncome = mappedIncome.reduce((sum, field) => {
      sum += +field.value;
      return sum;
    }, 0);
    mappedIncome.push({
      label: 'Total',
      value: totalIncome,
      type: 'currency',
      classes: 'bold',
    });

    const cashflowSection = CashflowDetails(mappedExpenses, mappedIncome);

    const projectionSection = ProjectionTable(
      projectedReturns.filter((pr, index) => years[index]),
      assumptions,
    );

    return reportHtml(
      header,
      `<div class="row" style="padding-bottom:20px;">
      ${ReportLink(deal)}<div style="padding:0 10">&#183;</div> ${AddressLink(
        deal,
      )}
  </div>`,
      estimate,
      purchaseDetails,
      cashflowSection,
      projectionSection,
    );
    // const estimates
  }, [
    deal,
    estimates,
    outOfPocket,
    capRate,
    cashflow,
    loanAmount,
    payment,
    rehab,
    projectedReturns,
    years,
    assumptions,
  ]);
};
