import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {groupsErrorAction} from './actions';
import {joinGroup} from './groupsService';
import {Group} from './types';

export const useJoinGroup = () => {
  const dispatch = useDispatch();
  return useCallback(async (group: Group) => {
    try {
      await joinGroup(group);
    } catch (err) {
      dispatch(groupsErrorAction(err));
      throw err;
    }
  }, []);
};
