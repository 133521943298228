import {DealState} from '../../member/screens/deal-analyzer/deal';

export const monthlyNetOperatingExpenses = (deal: DealState) => {
  const {
    cashflow: {scenarios, currentScenarioIndex},
  } = deal;
  const cashflow = scenarios[currentScenarioIndex]?.value;
  const {expenses = [], income = []} = cashflow ?? {};
  const rent = income.find(inc => inc.tag === 'rent')?.amount ?? 0;
  let netOperatingExpenses = 0;
  for (const expense of expenses) {
    if (expense.amountType === 'percent') {
      netOperatingExpenses += expense.amount * rent;
    } else {
      netOperatingExpenses += expense.amount;
    }
  }
  return netOperatingExpenses;
};
