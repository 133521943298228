import {useMemo} from 'react';
import {
  generateAmortization,
  generateVariableAmortization,
} from '@houseque/core/calculations';

//TODO allow for variable.
export const useComputedAmortization = (
  amount: number,
  rate: number,
  totalPayments = 0,
  payment: number,
) => {
  return useMemo(() => {
    return generateAmortization({amount, rate, totalPayments, payment});
  }, [amount, rate, totalPayments, payment]);
};

export const useAmortizationGenerator = (rateType = 'frm') => {
  switch (rateType) {
    case 'arm':
      return generateVariableAmortization;
    default:
      return generateAmortization;
  }
};
