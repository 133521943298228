import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import {userReducer} from '@houseque/core/application/user';
import explanationReducer from '@houseque/core/explanations';
import photosReducer from '@houseque/core/photos';
import startPaidMembership from './screens/start-paid-membership/slice';
import groupsSlice from './screens/groups/slice';
import notificationsReducer from '../core/pushnotifications/slice';
import dealsReducer from '@houseque/core/deals';
import scenariosReducer from '@houseque/core/scenarios/slice';

import rootSaga from './sagas';

const sagas = createSagaMiddleware();

export const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}).concat(sagas),
  //TODO trim this down
  reducer: {
    user: userReducer,
    explanation: explanationReducer,
    photos: photosReducer,
    scenarios: scenariosReducer,
    paidMembership: startPaidMembership,
    groups: groupsSlice.reducer,
    notifications: notificationsReducer,
    deals: dealsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

sagas.run(rootSaga);
