import {gql} from '@apollo/client';
import {DealDetailsFragment} from './DealDetailsFragment';

export const SaveUserDeal = gql`
  mutation SaveDeal($deal: DealInput!) {
    saveDeal(deal: $deal) {
      ...DealDetails
    }
  }
  ${DealDetailsFragment}
`;
