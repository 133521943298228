import {spawn} from 'redux-saga/effects';
import {applicationSagas} from '@houseque/core/application/sagas';
import networkSaga from '@houseque/core/network/sagas';
import offlineDealsSaga from '@houseque/core/deals/sagas/offline';
import dealsSaga from '@houseque/core/deals/sagas/deals';
import financingSaga from '@houseque/core/deals/sagas/finance';
import estimatesSaga from '@houseque/core/deals/sagas/estimates';
import photosSaga from '@houseque/core/photos/sagas';
// import invitationSagas from '@houseque/core/invitations/sagas';
import groupSagas from './screens/groups/sagas';

export default function* () {
  const sagas = [
    networkSaga,
    offlineDealsSaga,
    dealsSaga,
    financingSaga,
    estimatesSaga,
    photosSaga,
    // invitationSagas,
    groupSagas,
    ...applicationSagas,
  ];
  for (const saga of sagas) {
    yield spawn(saga);
  }
}
