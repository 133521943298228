import {Projection} from '../useOverTimeProjections';
import {Prediction} from '@houseque/types';
import {format, FieldType} from './Common';

const TableHeader = (label: string) => {
  return `<td class="bold" style="color:#333">${label}</td>`;
};

const TableCell = (value: any, type: FieldType | undefined = undefined) => {
  return `<td>${format(value, type)}</td>`;
};

export const ProjectionTable = (
  projections: Projection[],
  prediction: Prediction = {},
) => {
  return `<h3>Projections</h3>
          <table class="full-width">
          <tbody style="font-size: .8em;">
              <tr>
              ${TableHeader('Year')}
                  ${projections
                    .map(proj => {
                      return TableCell(proj.period);
                    })
                    .join('')}
              </tr>
              <tr>
              ${TableHeader('Loan Balance')}
                  ${projections
                    .map(proj => {
                      return TableCell(proj.currentBalance, 'currency');
                    })
                    .join('')}
              </tr>
              <tr>
              ${TableHeader('Property Value')}
                  ${projections
                    .map(proj => {
                      return TableCell(proj.propertyValue, 'currency');
                    })
                    .join('')}
              </tr>
              <tr>
              ${TableHeader('Profits if Sold')}
                  ${projections
                    .map(proj => {
                      return TableCell(proj.profits, 'currency');
                    })
                    .join('')}
              </tr>
              <tr>
              ${TableHeader('Cashflow to Date')}
                ${projections
                  .map(proj => {
                    return TableCell(proj.cashflow, 'currency');
                  })
                  .join('')}
              </tr>
          </tbody>
          </table>
          <p style="padding: 10 0 0 10;">Projections based on the following annual growth rates<br/> 
          <b>Property Value: ${format(
            prediction.periodicPropertyAppraisal,
            'percent',
          )}  &#183;  Income: ${format(
    prediction.periodicRentVariance,
    'percent',
  )}  &#183;  Expenses: ${format(
    prediction.periodicExpenseAppreciation,
    'percent',
  )}</b></p>
      `;
};
