import auth from '@react-native-firebase/auth';

export const login = async (email: string, password: string) => {
  const response = await auth().signInWithEmailAndPassword(email, password);
  return response;
};

/**
 *
 * @param {string} email
 * @param {string} password
 * @param {string} displayName
 */
export const registerUser = async (
  email: string,
  password: string,
  displayName: string,
) => {
  const response = await auth().createUserWithEmailAndPassword(email, password);

  await response.user.updateProfile({displayName});
  return response;
};

export const forgotPassword = async (email: string) => {
  await auth().sendPasswordResetEmail(email);
};
