import {TextNumeric, Text} from '../../../../../components';
import {Alert} from 'react-native';
import React, {useCallback} from 'react';
import {Mortgage} from '@houseque/types';
import {useRemoveLoan} from '../../../../../core/deals/selectors/financing';
import {List} from 'react-native-paper';
import {useFullLoanAmount} from '../../../../../core/deals/selectors/financing/useFullLoanAmount';

interface LoanItemProps {
  refinance?: boolean;
  loan?: Mortgage;
  /**
   * The text in the add loan button, when a loan isn't present
   */
  newLoanText?: string;
  onPress: () => void;
}

//Add refinance....
export const LoanItem = ({loan, onPress, refinance}: LoanItemProps) => {
  const removeLoan = useRemoveLoan();
  const loanAmount = useFullLoanAmount(refinance);
  const onRemoveLoan = useCallback(() => {
    Alert.alert('Are you sure?', `We can't bring this data back`, [
      {
        text: 'Confirm',
        style: 'destructive',
        onPress: () => removeLoan(refinance ?? false),
      },
      {
        text: 'Cancel',
        style: 'cancel',
        // onPress: () => {},
      },
    ]);
  }, [refinance, removeLoan]);
  if (!loan) {
    return null;
  }
  return (
    <List.Item
      style={{marginHorizontal: -15}}
      onLongPress={onRemoveLoan}
      left={() => (
        <List.Icon
          style={{padding: 0, margin: 0, alignSelf: 'center'}}
          icon={'bank'}
        />
      )}
      onPress={onPress}
      title={<TextNumeric type={'currency'} value={loanAmount} />}
      description={
        <Text>
          {loan.term} years @ <TextNumeric value={loan.rate} type={'percent'} />
        </Text>
      }
      right={() => <List.Icon icon={'chevron-right'} />}
    />
  );
};
