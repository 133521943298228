import {configureStore} from '@reduxjs/toolkit';
import {applicationSagas} from '@houseque/core/application/sagas';
import createSagaMiddleware from 'redux-saga';
import authSlice from './authentication/slice';
import authSaga from './authentication/sagas';
import {spawn} from 'redux-saga/effects';

const sagas = createSagaMiddleware();

export const store = configureStore({
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagas),
  reducer: {
    authentication: authSlice.reducer,
  },
});

export type NonMemberRootState = ReturnType<typeof store.getState>;

sagas.run(function* () {
  const sagas = [authSaga, ...applicationSagas];
  for (const saga of sagas) {
    yield spawn(saga);
  }
});
