import {useComputedFinancing} from '../useComputedFinancing';

/**
 * This effectively grabs the total amount loaned.  A few complexities here involve
 * whether or not you're borrowing against ARV or not, and closing costs associated with the specific loan.
 *
 * Ex. If you're doing a standard loan, and want to include closing costs in the loan, this will handle that.
 * @param forRefinance Whether or not to pull this for refinance or not
 */
export const useFullLoanAmount = (forRefinance = false) => {
  const {loans} = useComputedFinancing();
  if (loans.length) {
    let index = 0;
    if (forRefinance) {
      index = 1;
    }
    return loans[index]?.loanAmount ?? 0;
  }
  return 0;
};
