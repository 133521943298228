import {gql} from '@apollo/client';

export const FindParcels = gql`
  query FindParcels(
    $address: String
    $city: String
    $state: String
    $postalcode: String
    $county: String
    $lat: String
    $long: String
  ) {
    findParcels(
      address: $address
      city: $city
      state: $state
      postalcode: $postalcode
      county: $county
      lat: $lat
      long: $long
    ) {
      id
      parcelID
    }
  }
`;
