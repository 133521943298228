import React from 'react';
import {useNavigation, useRoute} from '@react-navigation/core';
import {Refinance} from '@houseque/types';
import {FinancingForm} from './FinancingForm';
import {useChangeFinancing} from '@houseque/core/deals/selectors/financing/useChangeFinancing';

//TODO move this to somewhere central to handle local state change implications
const refinanceDefaults: Refinance = {
  downPayment: 0.2,
  rate: 0.0425,
  term: 30,
  closingCosts: 0,
  rateType: 'frm',
  armType: null,
  paymentFrequency: 1,
  interestOnly: false,
  financeClosingCosts: false,
  refinanceAfter: 6,
};

export const RefinanceForm = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const changeFinancing = useChangeFinancing();
  let mortgage = route.params['mortgage'] || refinanceDefaults;
  if (typeof mortgage === 'string') {
    try {
      mortgage = JSON.parse(mortgage);
    } catch (err) {
      console.warn(`Unable to parse ${mortgage}`);
      mortgage = refinanceDefaults;
    }
  }
  const refinance = mortgage.refinance || refinanceDefaults;

  return (
    <FinancingForm
      loan={refinance}
      allowDelayedLoan={true}
      refinanceOnly={true}
      onSubmit={values => {
        changeFinancing([
          {
            ...mortgage,
            refinance: values,
          },
        ]);
        navigation.goBack();
      }}
    />
  );
};
