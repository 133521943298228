import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';

/**
 * Encapsulating interactions with reading the state, and actions in this section makes changes easier.
 */
export const usePurchaseState = () => {
  const currentState = useSelector(
    useCallback((store: HouseQueAppState) => {
      const {currentScenarioIndex} = store.deals.selectedDeal.financing;
      return store.deals.selectedDeal.financing.scenarios[currentScenarioIndex]
        .value;
    }, []),
  );

  return useMemo(() => {
    return currentState;
  }, [currentState]);
};
