import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {EstimatePane} from './EstimatePane';
import {HouseQueAppState} from '../../../../../houseque';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';

export const RehabCashflow = () => {
  const [label] = useState('Rehab cashflow');
  const [value, setValue] = useState(0);

  const monthlyCashflow = useSelector(
    (store: HouseQueAppState) =>
      store.deals.selectedDealEstimates.monthlyCashflow,
  );
  const rehab = useRehab();
  useEffect(() => {
    setValue((monthlyCashflow ?? 0) * (rehab.duration ?? 0));
  }, [monthlyCashflow, rehab.duration]);

  return (
    <EstimatePane
      explanation={'cashflow'}
      value={value}
      valueType={'currency'}
      label={label}
    />
  );
};
