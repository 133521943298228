import {Deal} from '@houseque/types';
import {useEffect, useState} from 'react';
import {loadGroupDeals} from './groupsService';

export const useGroupDeals = (groupId: string) => {
  const [deals, setDeals] = useState<Deal[]>(undefined);
  useEffect(() => {
    return loadGroupDeals(groupId, setDeals);
  }, [groupId]);
  return deals;
};
