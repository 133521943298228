import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changeRehab} from '../slice';

export const useUpdateRehab = () => {
  const dispatch = useDispatch();
  const updateRehab = useCallback(
    rehab => {
      dispatch(changeRehab(rehab));
    },
    [dispatch],
  );
  return updateRehab;
};
