import React, {useState, useEffect, useCallback} from 'react';
import {Box, Text, TextNumeric} from '../../../../components';
import {useNavigation} from '@react-navigation/core';
import {useRent} from '../../../../core/deals/selectors/cashflow';
import {Card, Chip, IconButton, TouchableRipple} from 'react-native-paper';
import {ScrollView} from 'react-native-gesture-handler';

type CashflowNameMapType = {[index in ExpenseTag | IncomeTag]: string};

const nameMap: CashflowNameMapType = {
  pmi: 'PMI',
  rent: 'Rent',
  management: 'Property Management',
  sanitation: 'Sanitation',
  electric: 'Electric',
  water: 'Water',
  hoa: 'HOA',
  repairs: 'Maintenance',
  capex: 'CapEx',
  other: 'Other',
  taxes: 'Property Tax',
  vacancy: 'Vacancy',
  insurance: 'Insurance',
};

interface CashflowSummaryProps {
  cashflow: CashflowEntryBase[];
  title: string;
  which: CashflowType;
}

export const CashflowSummary = (props: CashflowSummaryProps) => {
  const {cashflow, which} = props;
  const [total, setTotal] = useState(0);
  const [text] = useState(`Tap to add ${which}`);
  const [data, setData] = useState<string[]>([]);
  const {navigate} = useNavigation();
  const rent = useRent();

  useEffect(() => {
    const summary = cashflow.map(
      item => nameMap[item.tag as IncomeTag | ExpenseTag],
    );
    setData(summary);
  }, [cashflow]);

  //Calculates the total for display
  useEffect(() => {
    const cashflowTotal = cashflow.reduce((sum, item) => {
      const amount =
        item.amountType === 'percent' ? item.amount * rent : item.amount;
      return amount + sum;
    }, 0);
    setTotal(cashflowTotal);
  }, [cashflow, rent]);

  const onPress = useCallback(() => {
    navigate('Cashflow', {
      options: {
        type: which,
        title: props.title,
      },
    });
  }, [navigate, props.title, which]);

  return (
    // <TouchableRipple onPress={onPress}>
    <Card>
      <TouchableRipple onPress={onPress}>
        <Card.Title
          title={<Text variant={'callout'}>{props.title}</Text>}
          subtitle={
            <TextNumeric
              color={'grey1'}
              variant={'body'}
              type={'currency'}
              value={total}
            />
          }
          right={() => <IconButton color={'#888'} icon={'chevron-right'} />}
        />
      </TouchableRipple>
      <Card.Content>
        <ScrollView horizontal scrollEnabled={true}>
          {data.map(tag => {
            return (
              <Box key={tag} paddingRight={'s'}>
                <Chip style={{padding: 0}} mode={'outlined'}>
                  {tag}
                </Chip>
              </Box>
            );
          })}
        </ScrollView>
      </Card.Content>
    </Card>
  );
};
