import {take, put, actionChannel, takeLatest, call} from 'redux-saga/effects';
import {NETWORK_ONLINE, NETWORK_OFFLINE} from '../../network';
import {saveOfflineDeal} from '../actions';
import {
  saveDeal as saveDealAction,
  saveDealError,
  removeDeal,
} from '../actions/actions';
import {saveDeal} from '../services/saveDealService';
import {exceptionCaughtAction} from '@houseque/core/application/exceptions';
import {logEventAction} from '@houseque/core/application/analytics/actions';
import {loadUserDeals} from '../services/loadUserDeals';
import {removeUserDeal} from '../services/removeUserDeal';
import {Deal} from '@houseque/types';
import {
  loadUserDeals as loadUserDealsAction,
  loadUserDealsSuccess,
  saveDealSuccess,
  removeDealSuccess,
  loadUserDealsError,
} from '../slice';

//Move to the store
let isOnline = true;

function* handleUnexpectedException(error) {
  // Send to crashlytics
  yield put(exceptionCaughtAction(error));
}

function* handleSaveDealException(action) {
  yield handleUnexpectedException(action.payload);
}

function* handleLoadUserDeals(action) {
  try {
    const deals: Deal[] = yield call(loadUserDeals, action.payload);
    yield put(loadUserDealsSuccess(deals));
  } catch (error) {
    yield put(loadUserDealsError(error));
    yield handleUnexpectedException({payload: error});
  }
}

function* handleRemoveDeal({
  payload: {dealId, onSuccess, onError},
}: ReturnType<typeof removeDeal>) {
  try {
    yield call(removeUserDeal, dealId);
    yield put(removeDealSuccess(dealId));
    onSuccess(dealId);
  } catch (err) {
    yield handleUnexpectedException(err);
    onError(err);
  }
}

function* onSaveDeal(action: ReturnType<typeof saveOfflineDeal>) {
  if (isOnline) {
    try {
      const saved = yield call(saveDeal, action.payload);
      yield put(saveDealSuccess(saved));
    } catch (err) {
      yield handleUnexpectedException(err);
    }
  } else {
    yield put(saveOfflineDeal(action.payload));
  }

  yield put(
    logEventAction({
      name: 'save_property',
      params: {
        online_status: isOnline + '', // eslint-disable-line @typescript-eslint/camelcase
      },
    }),
  );
}

export default function* dealsSaga() {
  const chan = yield actionChannel(saveDealAction.type);
  yield takeLatest(chan, onSaveDeal);
  yield takeLatest(saveDealError.type, handleSaveDealException);
  yield takeLatest(loadUserDealsAction.type, handleLoadUserDeals);
  yield takeLatest(removeDeal.type, handleRemoveDeal);
  //TODO use selecter instead
  while (true) {
    const action = yield take([NETWORK_OFFLINE, NETWORK_ONLINE]);
    isOnline = action.type === NETWORK_ONLINE;
  }
}
