import React from 'react';
import {View, StyleSheet} from 'react-native';
import {TextNumericProps, Text, TextNumeric} from '@houseque/components';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderBottomColor: '#ddd',
    backgroundColor: '#fff',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});

interface ReportLineProps extends TextNumericProps {
  label: string;
}

export const ReportLine = ({label, value, type}: ReportLineProps) => {
  return (
    <View style={styles.container}>
      <Text>{label}</Text>
      <TextNumeric value={value} type={type} />
    </View>
  );
};
