import {useCashflow} from '@houseque/core/deals/selectors/cashflow';
import {useLoanPayment} from '@houseque/core/deals/selectors/financing';
import React, {useState, useEffect} from 'react';
import {ReportLine} from './ReportLine';
import {SectionHeader} from './SectionHeader';

export const CashflowDetails = () => {
  const cashflow = useCashflow();
  const payment = useLoanPayment();
  const [rent, setRent] = useState(0);

  useEffect(() => {
    const totalRent = cashflow.income.reduce((total, income) => {
      if (income.tag === 'rent') {
        total += income.amount;
      }
      return total;
    }, 0);
    setRent(totalRent);
  }, [cashflow.income]);

  return (
    <>
      <SectionHeader>Cash Flow</SectionHeader>
      <SectionHeader subheader>Income</SectionHeader>
      {cashflow.income.map((income, i) => {
        return (
          <ReportLine
            key={i}
            label={income.name}
            value={income.amount}
            type={income.amountType === 'percent' ? 'percent' : 'currency'}
          />
        );
      })}
      <SectionHeader subheader>Expenses</SectionHeader>
      {cashflow.expenses.map((expense, i) => {
        return (
          <ReportLine
            key={i}
            label={expense.name}
            value={
              expense.amountType === 'percent'
                ? expense.amount * rent
                : expense.amount
            }
            type={'currency'}
          />
        );
      })}
      <ReportLine label={'Loan Payment'} value={payment} type={'currency'} />
    </>
  );
};
