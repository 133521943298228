import {call, put, takeEvery, take} from 'redux-saga/effects';
import {NETWORK_ONLINE} from '../../../core/network';
import {
  saveOfflineDeal,
  removeOfflineDeal,
  saveDeal,
  saveDealError,
} from '../actions';

import {
  upsertOfflineDeal,
  deleteOfflineDeal,
  getCurrentOfflineDeals,
  offlineIdPrefix,
} from '../services/offlineDealsService';
import {saveDealSuccess, offlineDealRemoved, offlineDealSaved} from '../slice';

function* onNetworkOnline() {
  const deals = yield call(getCurrentOfflineDeals);
  for (const deal of deals) {
    let toSaveId = undefined;
    const {id, ...toSave} = deal;
    if (!id?.startsWith(offlineIdPrefix)) {
      toSaveId = id;
    }
    yield put(saveDeal({...toSave, id: toSaveId}));
    const result = yield take([saveDealError.type, saveDealSuccess.type]);
    if (saveDealSuccess.match(result)) {
      yield put(removeOfflineDeal(deal));
    } else {
      console.warn(`Unable to save offline deals ${result.payload}`);
    }
  }
}

function* onRemoveOfflineDeal(action) {
  const payload = yield call(deleteOfflineDeal, action.payload);
  yield put(offlineDealRemoved(payload));
}

function* onSaveOfflineDeal(action) {
  const payload = yield call(upsertOfflineDeal, action.payload);
  yield put(offlineDealSaved(payload));
}

export default function* offlineDealsSaga() {
  yield takeEvery(saveOfflineDeal.type, onSaveOfflineDeal);
  yield takeEvery(removeOfflineDeal.type, onRemoveOfflineDeal);
  yield takeEvery(NETWORK_ONLINE, onNetworkOnline);
}
