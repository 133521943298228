import {Deal} from '@houseque/types';
import firebase from '@react-native-firebase/app';
import '@react-native-firebase/firestore';
import {Group} from './types';

const firestore = {
  get collection() {
    return firebase.firestore().collection(`groups`);
  },
};

/**
 * Can be used for any modifications of groups
 * @param group
 */
export const saveGroup = async (group: Group) => {
  const {uid, displayName: name} = firebase.auth().currentUser ?? {};
  const {
    id,
    displayName,
    createdTimestamp = firebase.firestore.FieldValue.serverTimestamp(),
    members = {
      [uid]: true,
    },
    createdBy = {
      uid,
      name,
    },
    ...groupStructured
  } = group;
  const collection = firestore.collection.doc(group.id ?? '');
  const result = await collection.set({
    createdTimestamp,
    createdBy,
    members,
    displayName,
    ...groupStructured,
    name: displayName.toLowerCase(),
  });
};

export const leaveGroup = async (group: Group) => {
  const userId = firebase.auth().currentUser?.uid;
  delete group.members[userId];
  await saveGroup(group);
};

export const joinGroup = async (group: Group) => {
  const userId = firebase.auth().currentUser?.uid;
  group.members[userId] = true;
  await saveGroup(group);
};

export const deleteGroup = async (group: Group) => {
  await firestore.collection.doc(group.id).delete();
};

/**
 * Can be used for any modifications of groups
 * @param group
 */
export const createGroup = async (group: Group) => {
  const currentUser = firebase.auth().currentUser;
  const {
    id,
    displayName,
    createdTimestamp = firebase.firestore.FieldValue.serverTimestamp(),
    members = {
      [currentUser?.uid]: true,
    },
    createdBy = {
      name: currentUser?.displayName,
      id: currentUser?.uid,
    },
    ...groupStructured
  } = group;
  const collection = firestore.collection;
  const result = await collection.add({
    createdTimestamp,
    createdBy,
    members,
    displayName,
    ...groupStructured,
    name: displayName.toLowerCase(),
  });
  return result.id;
};

/**
 * Adds a group to the deal.
 * @param dealId
 * @param groupId
 */
export const addMultipleDealsToGroup = async (
  deals: Deal[],
  groupId: string,
) => {
  const batch = firebase.firestore().batch();
  const groupRef = firestore.collection.doc(groupId).collection('deals');
  deals?.forEach(deal => {
    batch.set(groupRef.doc(deal.id), {
      ...deal,
      sharedTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  });
  await batch.commit();
};

export const removeDealFromGroup = async (dealId: string, groupId: string) => {
  await firestore.collection
    .doc(groupId)
    .collection('deals')
    .doc(dealId)
    .delete();
};

export const loadGroup = async (groupId: string): Promise<Group> => {
  const doc = await firestore.collection.doc(groupId).get();
  return {
    id: doc.id,
    ...(doc.data() as Group),
  };
};

/**
 * Loads group in which the user is a member
 */
export const loadMyGroups = (onChange: (groups: Group[]) => void) => {
  const userId = firebase.auth().currentUser?.uid;
  const unsubscribe = firestore.collection
    .where(`members.${userId}`, '==', true)
    .onSnapshot(snapshot => {
      const groups = snapshot.docs.map(snapshot => {
        const group: Group = {
          id: snapshot.id,
          ...(snapshot.data() as Group),
        };
        return group;
      });
      onChange(groups);
    });
  return unsubscribe;
};

/**
 * Loads group in which the user is a member
 */
export const loadGroupDeals = (
  groupId: string,
  onChange: (deals: Deal[]) => void,
) => {
  const unsubscribe = firestore.collection
    .doc(groupId)
    .collection('deals')
    .onSnapshot(snapshot => {
      const deals = snapshot?.docs.map(snapshot => {
        const deal: Deal = {
          id: snapshot.id,
          ...(snapshot.data() as Deal),
        };
        return deal;
      });
      onChange(deals);
    });
  return unsubscribe;
};

/**
 * Pretty hackish, might convert search to dynamo or something, but can add that later
 * make this lowercase friendly.
 * @param searchCriteria
 */
export const searchGroups = async (searchCriteria: string) => {
  const result = await firestore.collection
    .where('access', '==', 'public')
    .orderBy('name', 'asc')
    .startAt(searchCriteria?.toLowerCase())
    .endAt(searchCriteria?.toLowerCase() + '~')
    .get();
  return result.docs.map(doc => ({id: doc.id, ...doc.data()}));
};
