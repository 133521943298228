import React, {useCallback, useMemo} from 'react';
import {Deal} from '@houseque/types';
import moment from 'moment';
import {
  SectionList,
  SectionListProps,
  TextStyle,
  StyleProp,
  Animated,
  StyleSheet,
} from 'react-native';
import {Text} from '../../../components';
import {SavedAnalysisListItem} from './SavedAnalysisListItem';
import {Divider} from 'react-native-paper';

const AnimatedSectionList = Animated.createAnimatedComponent(SectionList);

export type GroupBy = 'none' | 'date' | 'location';

interface DealsProps extends Partial<SectionListProps<Deal>> {
  titleStyle?: StyleProp<TextStyle>;
  groupBy?: GroupBy;
  deals?: Deal[];
}

const groupDeals = (
  deals: Deal[],
  groupBy: GroupBy,
): {[index: string]: Deal[]} => {
  if (groupBy === 'none') {
    return {'': deals};
  }
  return deals.reduce((grouped, deal, index) => {
    let key = '';
    switch (groupBy) {
      case 'date':
        //Create key for the month
        key = moment(+deal.timestamp ?? Date.now()).format('MMMM YYYY');
        break;
      case 'location':
        const {city, state} = deal.property?.location ?? {};
        if (!city || !state) {
          key = 'No location';
        } else {
          key = `${city} ${state}`;
        }
        break;
    }
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(deal);
    return grouped;
  }, {});
};

const styles = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
  },
});

export const Deals = ({
  deals = [],
  groupBy = 'date',
  titleStyle,
  contentContainerStyle,
  ...sectionListProps
}: DealsProps) => {
  const sections = useMemo(() => {
    const groupedDeals = groupDeals(deals, groupBy);
    return Object.entries(groupedDeals).map(([title, data]) => {
      return {
        title,
        data,
      };
    });
  }, [deals, groupBy]);

  const renderSectionHeader = useCallback(({section}) => {
    return (
      <Text color={'grey1'} variant={'subhead'} style={titleStyle}>
        {section.title}
      </Text>
    );
  }, []);

  const renderItem = useCallback(({item}) => {
    return <SavedAnalysisListItem analysis={item} />;
  }, []);

  return (
    <AnimatedSectionList
      stickySectionHeadersEnabled={false}
      {...sectionListProps}
      sections={sections}
      ItemSeparatorComponent={Divider}
      initialNumToRender={11}
      contentContainerStyle={[contentContainerStyle, styles.contentContainer]}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
    />
  );
};
