import {FullTheme, Colors} from 'react-native-elements';
import {fonts} from './fonts';

const normalFont = 'AvenirNext-Regular';
const semiBoldFont = 'AvenirNext-DemiBold';

export type Sizing = {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
};

const colors: HouseQueColors = {
  primary: '#344568',
  secondary: '#77c5d9',
  success: '#00C781',
  warning: '#FFAA15',
  anchorblue: '#2089dc',
  error: '#FF4040',
  disabled: '#ccc',
  grey0: '#393e42',
  grey1: '#43484d',
  grey2: '#5e6977',
  grey3: '#86939e',
  grey4: '#e3e3e3',
  grey5: '#f2f2f2',
  light: '#ffffff',
  dark: '#000000',
  white: '#ffffff',
  black: '#000000',
};

export type HouseQueColors = Partial<Colors> & {
  light: string;
  dark: string;
  white: string;
  black: string;
  anchorblue: string;
};

export type HousequeTheme = Partial<FullTheme> & {
  name: string;
  font: {
    family: {
      normal: string;
      bold: string;
    };
    normal: string;
    bold: string;
    size: Sizing;
  };
  edge: Sizing;
  colors: HouseQueColors;
};

export const primary: HousequeTheme = {
  name: 'primary',
  edge: {
    xlarge: 25,
    large: 20,
    medium: 16,
    small: 10,
    xsmall: 5,
  },
  font: {
    family: {
      normal: fonts.regular,
      bold: fonts.demiBold,
    },
    normal: normalFont,
    bold: semiBoldFont,
    size: {
      xsmall: 10,
      small: 12,
      medium: 16,
      large: 20,
      xlarge: 24,
    },
  },
  Button: {
    raised: false,
    buttonStyle: {
      padding: 15,
      justifyContent: 'space-between',
    },
    titleStyle: {
      fontFamily: semiBoldFont,
    },
    iconRight: true,
  },
  ButtonGroup: {
    containerStyle: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  Card: {
    containerStyle: {
      margin: 0,
    },
  },
  Text: {
    style: {
      fontFamily: normalFont,
      fontSize: 16,
    },
  },
  Input: {
    containerStyle: {
      paddingHorizontal: 0,
      marginVertical: 10,
    },
    labelStyle: {
      color: colors.grey3,
      fontWeight: undefined,
      fontFamily: semiBoldFont,
    },
    placeholderTextColor: colors.grey3,
    inputContainerStyle: {
      borderBottomColor: colors.grey4,
    },
  },
  ListItem: {
    underlayColor: colors.light,
    activeOpacity: 0.5,
  },
  Slider: {
    thumbTintColor: colors.secondary,
  },
  SocialIcon: {
    style: {
      borderRadius: 3,
      marginHorizontal: 0,
      marginBottom: 0,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 15,
    },
    raised: false,
    button: true,
  },
  colors,
  // grey1: '',
  // divider: ''

  // readonly primary: string;
  // readonly secondary: string;
  // readonly grey0: string;
  // readonly grey1: string;
  // readonly grey2: string;
  // readonly grey3: string;
  // readonly grey4: string;
  // readonly grey5: string;
  // readonly greyOutline: string;
  // readonly searchBg: string;
  // readonly success: string;
  // readonly warning: string;
  // readonly error: string;
  // readonly disabled: string;
  // readonly divider: string;
};
