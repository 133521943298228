import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Text, Box} from '../../../../components';
import {TouchableRipple} from 'react-native-paper';
import {LayoutChangeEvent, Animated, Easing} from 'react-native';

const AnimatedBox = Animated.createAnimatedComponent(Box);

interface PaginationProps {
  activeIndex?: number;
  onChangeIndex: (index: number) => void;
  items: {text: string}[];
}

export const Pagination = (props: PaginationProps) => {
  const [ignore, setIgnore] = useState(false);
  const [activeIndex, setActiveIndex] = useState(props.activeIndex ?? 0);
  const [width, setWidth] = useState(0);
  const offset = useRef(new Animated.Value(props.activeIndex ?? 0));
  const currentAnimation = useRef<Animated.CompositeAnimation>(undefined);
  const onPress = useCallback(
    (index: number) => {
      setIgnore(true);
      props.onChangeIndex(index);
      setActiveIndex(index);
    },
    [props.onChangeIndex, setActiveIndex],
  );

  useEffect(() => {
    if (props.activeIndex != null && !ignore) {
      setActiveIndex(props.activeIndex);
    }
  }, [props.activeIndex, ignore]);

  useEffect(() => {
    const previous = currentAnimation.current;
    previous?.stop();
    currentAnimation.current = Animated.timing(offset.current, {
      useNativeDriver: true,
      duration: 400,
      easing: Easing.out(Easing.ease),
      toValue: (activeIndex * width) / props.items.length,
    });
    currentAnimation.current.start(() => {
      setIgnore(false);
    });
  }, [activeIndex, offset, width, props.items.length, setIgnore]);

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const {width} = event.nativeEvent.layout;
      setWidth(width);
    },
    [setWidth],
  );

  return (
    <Box padding={'s'}>
      <Box
        onLayout={onLayout}
        flexDirection={'row'}
        justifyContent={'space-evenly'}>
        {props.items.map((item, index) => {
          return (
            <PaginationButton
              key={item.text}
              active={index === activeIndex}
              index={index}
              text={item.text}
              onPress={onPress}
            />
          );
        })}
      </Box>
      <AnimatedBox
        style={{transform: [{translateX: offset.current}]}}
        width={width / props.items.length}
        alignItems={'stretch'}>
        <Box
          flex={1}
          borderBottomColor={'primary'}
          borderBottomWidth={2}
          marginHorizontal={'s'}
        />
      </AnimatedBox>
    </Box>
  );
};

interface PaginationButtonProps {
  active?: boolean;
  text: string;
  index: number;
  onPress: (index: number) => void;
}

const PaginationButton = (props: PaginationButtonProps) => {
  const onPress = useCallback(() => {
    props.onPress(props.index);
  }, [props.index, props.onPress]);
  return (
    <TouchableRipple
      style={{
        alignItems: 'center',
        flex: 1,
      }}
      onPress={onPress}>
      <Text
        variant={'body'}
        fontWeight={'600'}
        color={props.active ? 'dark' : 'grey3'}
        paddingBottom={'m'}
        paddingTop={'s'}>
        {props.text}
      </Text>
    </TouchableRipple>
  );
};
