import {useComputedFinancing} from '../useComputedFinancing';

export const useOutOfPocket = (isRefinance = false) => {
  const {loans, outOfPocket} = useComputedFinancing();
  if (loans?.length) {
    let index = 0;
    if (isRefinance) {
      index = 1;
    }
    return loans[index]?.outOfPocket ?? 0;
  }
  return outOfPocket;
};
