import {DealState} from '../../member/screens/deal-analyzer/deal';
import {computeFinanceState} from '../../core/deals/calculations/calculations';
import {ComputedFinanceState} from '../../core/deals/deal';
import {grossMonthlyIncome} from './grossMonthlyIncome';
import {monthlyNetOperatingExpenses} from './monthlyNetOperatingExpenses';

export class DealCalculator {
  private finance: ComputedFinanceState;
  constructor(private deal: DealState) {
    this.finance = computeFinanceState(this.financing, this.rehab);
  }

  get rehab() {
    const {
      rehab: {scenarios, currentScenarioIndex},
    } = this.deal;
    return scenarios[currentScenarioIndex].value;
  }

  get financing() {
    const {
      financing: {scenarios, currentScenarioIndex},
    } = this.deal;
    return scenarios[currentScenarioIndex].value;
  }

  get grossMonthlyIncome() {
    return grossMonthlyIncome(this.deal);
  }

  get grossMonthlyExpenses() {
    return monthlyNetOperatingExpenses(this.deal);
  }

  get netOperatingIncome() {
    return this.grossMonthlyIncome - this.grossMonthlyExpenses;
  }

  get monthlyCashflow() {
    return this.netOperatingIncome - this.debtService;
  }

  get cashOnCash() {
    const annualCashflow = this.monthlyCashflow * 12;
    const cashOnCash = annualCashflow / this.outOfPocket;
    return cashOnCash || 0;
  }

  get outOfPocket() {
    if (this.finance.loans?.length) {
      return this.finance.loans[0].outOfPocket;
    }
    return this.finance.outOfPocket;
  }

  get debtService() {
    const {loans = []} = this.finance ?? {};
    const [loan] = loans;
    return loan?.payment ?? 0;
  }

  get flipCosts() {
    const losses = (this.rehab.rehabCosts ?? 0) + (this.financing.price ?? 0);
    const rehabCashflow = (this.rehab.duration ?? 0) * this.monthlyCashflow;
    return losses - rehabCashflow + this.finance.closingCosts.amount;
  }

  get flipProfits() {
    const proceedsAfterAgentFees = 0.94;
    const profit = (this.rehab.arv ?? 0) * proceedsAfterAgentFees;
    return profit - this.flipCosts;
  }

  get returnOnInvestment() {
    return this.flipProfits / this.outOfPocket;
  }
}
