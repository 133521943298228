import {useState, useEffect} from 'react';
import {compound} from '@houseque/core/calculations';
import {usePredictions} from '../deal-analyzer/predictions';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';
import {useActualCashflowStream} from '@houseque/core/deals/selectors/cashflow/useActualCashflowStream';
import {
  useActualAmortization,
  useOutOfPocket,
  usePurchaseState,
} from '@houseque/core/deals/selectors/financing';

const DEFAULT_PERIOD_YEARS = 20;
const DEFAULT_PERIOD_MONTHS = DEFAULT_PERIOD_YEARS * 12;

export interface Projection {
  cashflow: number;
  propertyValue: number;
  currentBalance: number;
  period: number;
  payment: number;
  interest: number;
  profits: number;
}

export const useProjectionsOverTime = () => {
  const [projectionData, setProjectionData] = useState<Projection[]>([]);
  const purchase = usePurchaseState();
  const rehab = useRehab();
  const outOfPocket = useOutOfPocket();
  const amortization = useActualAmortization();
  const actualCashflow = useActualCashflowStream(
    amortization.length ?? DEFAULT_PERIOD_MONTHS,
  );
  const projections = usePredictions();

  useEffect(() => {
    let sum = 0;
    const cashflow: number[] = [];
    const projectedAm = amortization
      .filter((amEntry, index) => {
        const entry = actualCashflow[index];
        const {payment = 0} = amEntry;
        sum += entry - payment;
        const isNewYear = !((index + 1) % 12);
        if (isNewYear) {
          cashflow.push(sum);
        }
        return isNewYear;
      })
      .map((amEntry, index) => {
        const propertyValue = compound(
          rehab.arv || purchase.price,
          index + 1,
          projections.periodicPropertyAppraisal,
        );
        return {
          cashflow: cashflow[index] ?? 0,
          propertyValue,
          currentBalance: amEntry.currentBalance,
          period: index + 1,
          payment: amEntry.payment,
          interest: amEntry.interest,
          profits:
            cashflow[index] +
            propertyValue -
            amEntry.currentBalance -
            outOfPocket -
            //Cost of selling
            propertyValue * projections.eventualSellingCosts,
        };
      });
    setProjectionData(projectedAm);
  }, [amortization, actualCashflow, outOfPocket, projections]);

  return projectionData;
};
