import {useCallback} from 'react';
import {useOpenLink} from './useOpenLink';

const FEEDBACK_EMAIL = 'hello@houseque.com';
const SUBJECT = 'Sending feedback on the House Que App';

export const useSubmitFeedback = () => {
  const openLink = useOpenLink();
  return useCallback(async () => {
    await openLink({
      schema: 'mailto',
      address: FEEDBACK_EMAIL,
      params: {
        subject: SUBJECT,
      },
    });
  }, [openLink]);
};
