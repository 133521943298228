import React from 'react';
import {TutorialTextOutlet as TTO} from 'react-native-onboard-tutorial';
import {useTheme} from '../../../../core/theme';

export const TutorialTextOutlet = () => {
  const theme = useTheme();
  return (
    <TTO
      containerStyle={{
        paddingVertical: 10,
        backgroundColor: theme.colors.primary,
      }}
      textProps={{
        style: {
          color: theme.colors.light,
          textAlign: 'center',
          fontWeight: '600',
        },
      }}
    />
  );
};
