import React, {useState, useCallback} from 'react';
import {TextInput, Switch, Box} from '../../../../components';
import {useFormik} from 'formik';
import {useRehab} from '../../../../core/deals/selectors/useRehab';
import {useUpdateRehab} from '../../../../core/deals/actions/useUpdateRehab';

export const RehabForm = () => {
  //Maybe move this to property section?
  const rehab = useRehab();
  const updateRehab = useUpdateRehab();
  const [requiresRehab, setRequiresRehab] = useState(true);
  const {values, setFieldValue} = useFormik({
    initialValues: rehab,
    onSubmit: () => {
      //eslint silence!
    },
  });
  const handleChange = useCallback(
    (fieldName: keyof RehabStateInstance) => {
      return (value: any) => {
        setFieldValue(fieldName, value);
        updateRehab({
          [fieldName]: value || 0,
        });
      };
    },
    [setFieldValue, updateRehab],
  );
  return (
    <Box paddingBottom={'xxl'}>
      <Switch
        label={'Rehab needed?'}
        value={requiresRehab}
        onValueChange={setRequiresRehab}
      />
      {requiresRehab && (
        <>
          <TextInput
            type={'currency'}
            value={rehab.arv!}
            keyboardType={'number-pad'}
            onChangeText={handleChange('arv')}
            label={'After Repair Value'}
          />
          <TextInput
            type={'currency'}
            value={values.rehabCosts}
            label={'Rehab Costs'}
            keyboardType={'number-pad'}
            onChangeText={handleChange('rehabCosts')}
          />
          <TextInput
            value={values.duration}
            type={'numeric'}
            keyboardType={'number-pad'}
            label={'Rehab duration (months)'}
            onChangeText={handleChange('duration')}
          />
        </>
      )}
    </Box>
  );
};
