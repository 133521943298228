import {MutationHookOptions} from '@apollo/client';
import {SaveUserDeal} from '../queries';
import {DealState} from '../deal';
import {getClient} from '../../apollo';
import {uploadDealPhotos} from './uploadDealPhotos';

/**
 * Cache update only necessary if not using local state.
 */
const options: MutationHookOptions<any> = {
  update: (cache, {data: {saveDeal}}) => {
    cache.modify({
      fields: {
        myDeals(existing = []) {
          return [...existing, saveDeal];
        },
      },
    });
  },
};

export const mutateDeal = async ({
  unsavedChanges,
  timestamp,
  estimate,
  local,
  ...deal
}: DealState) => {
  const apolloClient = await getClient();
  const result = await apolloClient.mutate({
    mutation: SaveUserDeal,
    update: options.update,
    variables: {deal},
  });
  return result?.data?.saveDeal;
};

export const saveDeal = async (deal: DealState) => {
  const result = await mutateDeal(deal as DealState);
  const updatedDeal: DealState = {
    ...(deal as DealState),
    id: result.id,
  };
  await uploadDealPhotos(updatedDeal);
  return updatedDeal;
};
