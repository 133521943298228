import React from 'react';
import {Image, ImageStyle, StyleProp} from 'react-native';
import {Box, Text} from '@houseque/components';

const zillow = require('./zillow-logo.png'); // eslint-disable-line @typescript-eslint/no-var-requires

interface ZillowLogoProps {
  style?: StyleProp<ImageStyle>;
}

export const ZillowLogo = (props: ZillowLogoProps) => {
  return (
    <Box
      paddingHorizontal={'m'}
      flexDirection={'row'}
      alignItems={'center'}
      height={20}>
      <Text variant={'caption1'}>Provided by</Text>
      <Image
        resizeMode={'contain'}
        style={[
          {
            left: -40,
            alignSelf: 'stretch',
            flex: 1,
            maxWidth: 200,
            width: undefined,
            height: 20,
          },
          props.style,
        ]}
        source={zillow}
      />
    </Box>
  );
};
