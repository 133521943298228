import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changePurchasePrice} from '../slice';

export const useUpdatePrice = () => {
  const dispatch = useDispatch();
  const updatePrice = useCallback(
    price => {
      dispatch(changePurchasePrice(price));
    },
    [dispatch],
  );
  return updatePrice;
};
