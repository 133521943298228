//@ts-nocheck FAB is annoying from rnp
import React, {useEffect, useRef} from 'react';
import {HQIconProps} from './Icon';
import {HouseQueColors} from '../core/theme/houseque.theme';
import Animation, {Easing} from 'react-native-reanimated';
import {useKeyboardHeight} from '../core';
import {StyleSheet, StyleProp, ViewStyle} from 'react-native';
import {FAB, useTheme} from 'react-native-paper';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 10,
    bottom: 10,
  },
});

type Colors = keyof HouseQueColors;

type RNPaperFabProps = Pick<
  React.ComponentProps<typeof FAB>,
  'onPress' | 'icon' | 'label' | 'disabled'
>;

type FabProps =
  | RNPaperFabProps & {
      name?: string;
      color?: Colors;
      keyboardOffset?: number;
      avoidKeyboard?: boolean;
      containerStyle?: StyleProp<ViewStyle>;
      style?: StyleProp<ViewStyle>;
    };

export const Fab = ({
  name = 'check',
  color = 'primary',
  icon,
  containerStyle,
  style = {},
  keyboardOffset = 0,
  avoidKeyboard = true,
  ...props
}: FabProps) => {
  const theme = useTheme();
  const keyboardHeight = useKeyboardHeight();
  const animatedValue = useRef(new Animation.Value(keyboardHeight));
  useEffect(() => {
    if (!avoidKeyboard) return;
    Animation.timing(animatedValue.current, {
      toValue: -1 * Math.max(keyboardHeight - keyboardOffset, 0),
      duration: 100,
      easing: Easing.out(Easing.ease),
    }).start();
  }, [keyboardHeight, keyboardOffset, avoidKeyboard]);
  return (
    <Animation.View
      style={[
        styles.container,
        containerStyle,
        {
          transform: [{translateY: animatedValue.current}],
        },
      ]}>
      <FAB
        icon={icon || name}
        style={{backgroundColor: theme.colors.primary}}
        color={theme.colors.background}
        onPress={props.onPress}
      />
    </Animation.View>
  );
};
