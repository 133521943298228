import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type PhotosState = {
  staged: import('react-native-image-crop-picker').Image[];
};

const initialState: PhotosState = {
  staged: [],
};

const slice = createSlice({
  name: 'localphotos',
  initialState,
  reducers: {
    stageLocalPhotos: (state, action: PayloadAction<PhotosState['staged']>) => {
      state.staged = action.payload;
    },
    removeStagedPhoto: (state, action: PayloadAction<number>) => {
      state.staged.splice(action.payload, 1);
    },
  },
});

export const {stageLocalPhotos, removeStagedPhoto} = slice.actions;
export default slice.reducer;
