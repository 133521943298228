import React, {useState, useCallback, useEffect} from 'react';
import {ScrollView, StyleSheet} from 'react-native';
import {useDispatch} from 'react-redux';
import {Text, Link, Box} from '../../components';
import {useShowExplanation} from '../../core/explanations';
import {Scenario} from './Scenario';
import {HouseQueAppState} from '../../houseque';
import {ScenariosCategory} from '@houseque/types';
import {TouchableRipple} from 'react-native-paper';
import {categoricalActions} from './utils';
import {editScenarioCategory} from './slice';
import {useCategoryScenarioState} from './useCategoryScenarios';
import {Explanations} from '../explanations/types';

const DEFAULT_TITLE = 'Scenarios';

interface ScenariosProps {
  title?: string;
  category: ScenariosCategory;
  explanation?: Explanations | null;
  /**
   * Add this is category lowercase does not match the reducer key
   */
  which?: keyof HouseQueAppState;
}

const capitalize = (title = '') => {
  return `${title.charAt(0).toUpperCase()}${title.slice(1)}`;
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

export const Scenarios = ({
  title = DEFAULT_TITLE,
  explanation = null,
  category,
  which,
}: ScenariosProps) => {
  const [actions] = useState(categoricalActions[category]);
  const [multipleScenarios, setMultipleScenarios] = useState(false);
  const dispatch = useDispatch();

  const onAddScenario = useCallback(
    payload => {
      dispatch(actions.addScenario(payload));
    },
    [dispatch, actions],
  );

  const onChangeScenario = useCallback(
    payload => {
      dispatch(actions.changeCurrentScenario(payload));
    },
    [dispatch, actions],
  );

  const editCategory = useCallback(() => {
    const action = editScenarioCategory(category);
    dispatch(action);
  }, [category]);

  const state = useCategoryScenarioState(
    (which || category.toLowerCase()) as any,
  );
  useEffect(() => {
    const thereAreMultipleScenarios = state.scenarios.length > 1;
    setMultipleScenarios(thereAreMultipleScenarios);
  }, [state.scenarios.length]);
  const showExplanation = useShowExplanation(explanation!);

  if (!multipleScenarios) {
    return (
      <Box
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingRight={'m'}
        paddingVertical={'m'}>
        <Link title={'Add Scenario'} onPress={onAddScenario} />
        <Link
          title={'What are scenarios?'}
          color={'grey2'}
          onPress={showExplanation}
        />
      </Box>
    );
  }
  return (
    <Box overflow={'visible'} marginRight={'m'}>
      <Box
        paddingVertical={'s'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}>
        <TouchableRipple onPress={showExplanation}>
          <Text variant={'button'} paddingRight={'m'} color={'grey1'}>
            {DEFAULT_TITLE}
          </Text>
        </TouchableRipple>
        <Box flexDirection={'row'}>
          <Link onPress={onAddScenario} title={'Add'} />
          <Link paddingLeft={'m'} onPress={editCategory} title={'Edit'} />
        </Box>
      </Box>
      <Box paddingBottom={'m'} marginRight={'-l'}>
        {multipleScenarios && (
          <ScrollView horizontal contentContainerStyle={[styles.container]}>
            {state.scenarios.map((scenario, index) => {
              const isSelected = index === state.currentScenarioIndex;
              return (
                <Scenario
                  key={index}
                  style={{marginRight: 5}}
                  title={capitalize(
                    scenario.name || `${category} Scenario ${index + 1}`,
                  )}
                  onPress={() => onChangeScenario(index)}
                  selected={isSelected}
                />
              );
            })}
          </ScrollView>
        )}
      </Box>
    </Box>
  );
};
