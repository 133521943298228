import {forgotPassword} from './services';
import {useState, useCallback} from 'react';

const errMatch = /auth/;

interface AuthActionState {
  message?: string;
  state?: 'error' | 'success' | 'loading';
}

export const useResetPassword = (): [
  (email: string) => Promise<any>,
  AuthActionState,
] => {
  const [state, setState] = useState<AuthActionState>({});

  const resetPasswordAction = useCallback(async email => {
    try {
      setState({
        state: 'loading',
      });
      await forgotPassword(email);
      setState({
        message: `Password reset successfully sent to ${email}.  Follow the instructions in the email, then proceed to login!`,
        state: 'success',
      });
    } catch (err) {
      let message = err.message;
      if (!err.code.match(errMatch)) {
        message = 'An error occurred, please try again.';
      }
      setState({
        message,
        state: 'error',
      });
    }
  }, []);
  return [resetPasswordAction, state];
};
