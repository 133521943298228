import {useFormik} from 'formik';
import React, {useCallback, useState} from 'react';
import {InvestmentTarget} from '../../preferences/types';
import {
  TextInput,
  Text,
  Fab,
  Box,
  KeyboardAvoidingView,
  ModalClose,
} from '../../../components';
import {useInvestmentTargets} from '../../preferences/useInvestmentTargets';
import {useSaveInvestmentTargets} from '../../preferences/useSaveInvestmentTargets';
import {useNavigation} from '@react-navigation/core';
import {ScrollView} from 'react-native-gesture-handler';

export const UpdateInvestmentTargets = () => {
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const {data: targets = {}, error} = useInvestmentTargets();
  const save = useSaveInvestmentTargets();
  const navigation = useNavigation();
  const saveInvestmentTargets = useCallback(
    async data => {
      setSaving(true);
      setErrors(undefined);
      try {
        await save(data);
        navigation.goBack();
      } catch (err) {
        setErrors(err);
      }
      setSaving(false);
    },
    [save, setSaving],
  );

  const {values, setFieldValue, submitForm} = useFormik({
    enableReinitialize: true,
    initialValues: targets,
    onSubmit: data => {
      saveInvestmentTargets(data);
    },
  });

  const updateField = useCallback((field: InvestmentTarget) => {
    return text => setFieldValue(field, {value: text});
  }, []);

  return (
    <Box flex={1} margin={'m'}>
      <Box
        marginVertical={'m'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Text variant={'largeTitle'}>Investment Targets</Text>
        <ModalClose />
      </Box>
      <ScrollView contentContainerStyle={{flex: 1}}>
        <KeyboardAvoidingView
          style={{flex: 1}}
          contentContainerStyle={{flex: 1}}
          behavior={'position'}>
          <Box flex={0.5} />
          <TextInput
            label={'Desired Cash on Cash'}
            onChangeText={updateField('cashoncash')}
            type={'percent'}
            keyboardType={'decimal-pad'}
            value={values?.cashoncash?.value}
          />
          <Box paddingTop={'s'} />
          <TextInput
            label={'Desired Cap Rate'}
            onChangeText={updateField('caprate')}
            type={'percent'}
            keyboardType={'decimal-pad'}
            value={values?.caprate?.value}
          />
          <Box paddingTop={'s'} />
          <TextInput
            label={'Max Cash Spend'}
            onChangeText={updateField('outofpocket')}
            type={'currency'}
            keyboardType={'number-pad'}
            value={values?.outofpocket?.value}
          />
          <Box flex={0.5} />
          {(errors && !saving && (
            <Text color={'error'}>We're unable to save your preferences.</Text>
          )) ||
            null}
        </KeyboardAvoidingView>
      </ScrollView>
      <Fab icon={'check'} disabled={saving} onPress={submitForm} />
    </Box>
  );
};
