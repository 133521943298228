import React from 'react';
import {useOfflineDeals} from '@houseque/core/deals/selectors/useOfflineDeals';
import {Text} from '@houseque/components';

export const OfflineDeals = () => {
  const pendingDeals = useOfflineDeals();
  if (!pendingDeals.length) return null;
  return (
    <Text>
      You have {pendingDeals.length} deals that will sync when online.
    </Text>
  );
};
