import {HouseQueAppState} from '@houseque/core/types';
import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {useSelector} from 'react-redux';

export const useCurrentUser = (): Partial<FirebaseAuthTypes.User> => {
  const user = useSelector((store: HouseQueAppState) => {
    //This prevents the user from being undefined in the app
    return store.user.user ?? {};
  });
  return user;
};
