import React, {useMemo} from 'react';
import {useCurrentUser, useLogout} from '@houseque/core/application/user';
import {useSubmitFeedback} from '../../../core/hooks';
import {Text, Box} from '../../../components';
import {useNavigation} from '@react-navigation/core';
import {UserProfile} from '@houseque/types';
import {SafeAreaView} from 'react-native-safe-area-context';
import {IconButton, List, Divider} from 'react-native-paper';

export const SettingsScreen = () => {
  const user = useCurrentUser() as UserProfile;
  const submitFeedback = useSubmitFeedback();
  const logout = useLogout();
  const navigation = useNavigation();
  const chevron = useMemo(
    () => () => <List.Icon icon={'chevron-right'} color={'#aaa'} />,
    [],
  );
  return (
    <SafeAreaView style={{flex: 1}}>
      <Box flex={1}>
        <Box
          marginTop={'m'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          marginHorizontal={'m'}>
          <Text variant={'largeTitle'}>Options</Text>
          <IconButton color={'#777'} icon={'logout'} onPress={logout} />
        </Box>
        <Box backgroundColor={'white'} marginTop={'m'}>
          <List.Item
            title={'Update Profile'}
            description={`Signed in as ${user.displayName ?? ''}`}
            onPress={() => navigation.navigate('UpdateProfile')}
            right={chevron}
          />
          <Divider />
          <List.Item
            title={'Adjust Presets'}
            description={'Quickly fill values in your analysis'}
            onPress={() => navigation.navigate('Presets')}
            right={chevron}
          />
          <Divider />
          <List.Item
            title={'Add Investment Targets'}
            description={'Set Cash on Cash, or other targets'}
            onPress={() => navigation.navigate('InvestmentTargets')}
            right={chevron}
          />
          <Divider />
          <List.Item
            title={'Contact Us'}
            onPress={submitFeedback}
            right={() => <List.Icon icon={'email'} color={'#aaa'} />}
          />
          {/* <List.Item
            title={'Unlock Pro'}
            onPress={() => navigation.navigate('StartPaidMembership')}
          /> */}
        </Box>
      </Box>
    </SafeAreaView>
  );
};
