import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {updateDealLocalData} from '../slice';
import {LocalDealState} from '../deal';

export const useUpdateDealLocalData = () => {
  const dispatch = useDispatch();
  return useCallback(
    (localData: Partial<LocalDealState>) => {
      dispatch(updateDealLocalData(localData));
    },
    [dispatch],
  );
};
