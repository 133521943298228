import React, {useCallback} from 'react';
import {useNavigation} from '@react-navigation/core';
import {useFormik} from 'formik';

import {Button, Text, Box, TextInput, Link} from '@houseque/components';
import {useAuthenticationState} from '../authentication';
import {useSubmitFeedback} from '@houseque/core';
import {useDispatch} from 'react-redux';
import {useLinkAccount} from '../authentication';
import authSlice from '../authentication/slice';

export const LinkAccount = () => {
  const authState = useAuthenticationState();
  const navigation = useNavigation();
  const submitFeedback = useSubmitFeedback();
  const dispatch = useDispatch();
  const linkAccount = useLinkAccount();

  const goBack = useCallback(() => {
    dispatch(authSlice.actions.authLoginLinkComplete());
    navigation.goBack();
  }, [dispatch, navigation]);

  const {values, setFieldValue, submitForm} = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: formValues => {
      linkAccount(formValues.password);
    },
  });

  const setFormValue = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  return (
    <Box flex={1} margin={'l'}>
      <Box flex={0.2} />
      <Box justifyContent={'center'}>
        <Text variant={'title1'}>
          We found an account with the email already.
        </Text>
        <Link paddingTop={'s'} onPress={goBack} title={'Go back'} />
      </Box>
      <Box flex={1}>
        <Box paddingVertical={'m'}>
          <Text>Please enter the password for</Text>
          <Text paddingBottom={'m'} paddingTop={'xs'} variant={'headline'}>
            {authState.associateAccount?.email}
          </Text>
          <TextInput
            textContentType={'password'}
            onChangeText={setFormValue('password')}
            secureTextEntry
            label={'Password'}
          />
        </Box>
        <Button
          loading={authState.inFlight}
          disabled={!values.password}
          onPress={submitForm}
          title={'Link Account'}
        />
        <Text>{authState.errorMessage}</Text>
        <Link
          title={'Forgot Password?'}
          onPress={() =>
            navigation.navigate('ForgotPassword', {
              email: authState.associateAccount?.email,
            })
          }
        />
        <Link
          paddingTop={'s'}
          onPress={submitFeedback}
          title={'Report an issue'}
        />
      </Box>
      <Box flex={0.5}></Box>
    </Box>
  );
};
