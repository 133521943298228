import 'react-native-gesture-handler';
import React from 'react';
import {AppRegistry} from 'react-native';
import './firebase';
import App from './App';

const AppWeb = () => {
  return (
    <>
      <style type="text/css">{`
		@font-face {
		font-family: 'MaterialCommunityIcons';
		src: url(${require('react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')}) format('truetype');
		}
	`}</style>
      <App />
    </>
  );
};

AppRegistry.registerComponent('App', () => AppWeb);

AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
