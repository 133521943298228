export const fonts = {
  regular: 'AvenirNext-Regular',
  demiBold: 'AvenirNext-DemiBold',
};

export const sizes = {
  standard: 16,
  heroText: 30,
  small: 12,
};
