import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {Button as RNPButton} from 'react-native-paper';
import theme from '../core/theme/restyle';

type ButtonProps = React.ComponentProps<typeof RNPButton>;

interface HQButtonProps {
  title: string;
  rounded?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const Button = ({
  title,
  ...props
}: HQButtonProps & Partial<ButtonProps>) => {
  return (
    <RNPButton
      mode={'contained'}
      uppercase={false}
      theme={{roundness: 3}}
      labelStyle={theme.textVariants.button as any}
      contentStyle={{height: 56, justifyContent: 'flex-start'}}
      {...props}>
      {title}
    </RNPButton>
  );
};
