import React, {useMemo} from 'react';
import {Card} from 'react-native-paper';
import {Text} from '../../../components';
import {Group} from './types';
import {getCreatedDate} from './utils';

interface GroupTileProps {
  group?: Group;
  user?: Partial<import('@react-native-firebase/auth').FirebaseAuthTypes.User>;
  onPress?: () => void;
  children?: any;
}

export const GroupTile = ({
  onPress = () => {
    // Maybe this lint rule should be turned off
  },
  group,
  children,
  user,
}: GroupTileProps) => {
  const createdDate = useMemo(() => {
    return getCreatedDate(group);
  }, []);
  const memberText = useMemo(() => {
    const numMembers = Object.keys(group?.members).length;
    let memberText = `${numMembers} members`;
    if (numMembers === 1) {
      memberText = `1 member`;
    }
    const name =
      group.createdBy?.id === user?.uid
        ? 'me'
        : group.createdBy?.name ?? 'user';
    return `${memberText} • Created by ${name} on ${createdDate}`;
  }, [group?.members, createdDate]);
  return (
    <Card onPress={onPress} style={{flex: 1}}>
      <Card.Title title={group?.displayName} subtitle={memberText} />
      {group.description && (
        <Card.Content>
          <Text color={'grey2'} variant={'caption2'}>
            {group?.description}
          </Text>
        </Card.Content>
      )}
      <Card.Actions>{children}</Card.Actions>
    </Card>
  );
};
