import React from 'react';
import {useCashflow} from '@houseque/core/deals/selectors/cashflow';
import {CashflowSummary} from './CashflowSummary';
import {useLoanPayment} from '@houseque/core/deals/selectors/financing';
import {Text, TextNumeric} from '@houseque/components';

export const CashflowSection = () => {
  const {income, expenses} = useCashflow();
  const loanPayment = useLoanPayment();

  return (
    <>
      <CashflowSummary
        title={'Operating Revenue'}
        cashflow={income}
        which={'income'}
      />
      <CashflowSummary
        title={'Operating Expenses'}
        cashflow={expenses}
        which={'expense'}
      />
      <Text variant={'body'} paddingTop={'m'}>
        {'Loan Payment '}
        <TextNumeric
          variant={'body'}
          color={'grey1'}
          value={loanPayment}
          type={'currency'}
        />
      </Text>
    </>
  );
};
