import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';
import {useMemo} from 'react';

export const usePropertyDetails = () => {
  const propertyDetails = useSelector(
    (store: HouseQueAppState) => store.deals.selectedDeal.property,
  );
  return useMemo(() => {
    return {
      ...propertyDetails,
      ...(propertyDetails.details ?? {}),
    };
  }, [propertyDetails]);
};
