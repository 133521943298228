import {useSelector, useDispatch} from 'react-redux';
import {HouseQueAppState} from '../../../houseque';
import {useEffect} from 'react';
import {getOfflineDeals} from '../actions';

export const useOfflineDeals = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOfflineDeals());
  }, [dispatch]);
  return useSelector((app: HouseQueAppState) => {
    return app.deals.offlineQueue;
  });
};
