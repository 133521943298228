import {gql} from '@apollo/client';
import {DealDetailsFragment} from './DealDetailsFragment';

export const LoadUserDeals = gql`
  {
    myDeals {
      ...DealDetails
    }
  }
  ${DealDetailsFragment}
`;
