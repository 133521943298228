import {authenticationLink} from './authenticationLink';
import {cache} from './cache';
import {errorLink} from './errors';
import {ApolloClient, createHttpLink, ApolloLink} from '@apollo/client';
import {AsyncStorageWrapper, persistCache} from 'apollo3-cache-persist';
import AsyncStorage from '../storage';
import {RetryLink} from '@apollo/client/link/retry';
import Config from 'react-native-config';

let apolloClient: ApolloClient<unknown>;

export const getClient = async () => {
  const storageKey = `houseque-apollo3-storage`;
  if (apolloClient) {
    return apolloClient;
  }

  const retryLink = new RetryLink({
    delay: {
      jitter: true,
    },
  });

  const link = ApolloLink.from([
    errorLink,
    retryLink,
    authenticationLink,
    createHttpLink({uri: Config.RN_GRAPHQL_API}),
  ]);

  await persistCache({
    cache,
    storage: new AsyncStorageWrapper(AsyncStorage),
    trigger: 'write',
    key: storageKey,
  });
  apolloClient = new ApolloClient({
    link,
    cache,
  });
  return apolloClient;
};
