import {Deal} from '@houseque/types';
import {RouteProp, useNavigation} from '@react-navigation/core';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {ActivityIndicator, Avatar, List} from 'react-native-paper';
import {SafeAreaView} from 'react-native-safe-area-context';
import {NativeStackNavigationProp} from 'react-native-screens/native-stack';
import {Box, HeaderLink, Link, Switch, Text} from '../../../components';
import {useCurrentUser} from '@houseque/core/application/user';
import {useSubscribeToGroup} from '../../../core/pushnotifications/useSubscribeToGroup';
import {useTags} from '../../../core/pushnotifications/useTags';
import {useUnsubscribeToGroup} from '../../../core/pushnotifications/useUnsubscribeToGroup';
import {StackParams} from '../../nav';
import {DealState} from '../deal-analyzer/deal';
import {DealListItem} from '../home';
import {DealsLoading} from '../home/DealsLoading';
import {useGoToDeal} from '../home/useGoToDeal';
import {Group} from './types';
import {useDeleteGroup} from './useDeleteGroup';
import {useGroup} from './useGroup';
import {useGroupDeals} from './useGroupDeals';
import {useLeaveGroup} from './useLeaveGroup';
import {useLoadGroupMembers} from './useLoadGroupMembers';
import {useRemoveDealFromGroup} from './useRemoveDealFromGroup';
import {formatDate, getCreatedDate} from './utils';
import {useSubmitFeedback} from '@houseque/core';

type DealAnalyzerScreenProps = NativeStackNavigationProp<
  StackParams,
  'group-details'
>;

interface DealAnalyzerProps {
  navigation: DealAnalyzerScreenProps;
  route: RouteProp<StackParams, 'group-details'>;
}

const useGroupRole = (group?: Group) => {
  const user = useCurrentUser();
  return useMemo(() => {
    if (!group) return undefined;
    if (group?.createdBy?.id === user?.uid) {
      return 'admin';
    }
    if (user?.uid in (group?.members ?? {})) {
      return 'member';
    }
    return 'visitor';
  }, [group]);
};

export const GroupDetailScreen = ({navigation, route}: DealAnalyzerProps) => {
  const {data, loading, error} = useGroup(route.params?.groupId);
  const [disableHeaderLink, setDisableHeaderLink] = useState(false);
  const goToDeal = useGoToDeal();
  const handleDealNavigation = useCallback(
    (deals: DealState[]) => {
      const dealId = decodeURIComponent(route.params?.dealId ?? '');
      if (dealId) {
        const target = deals?.find(deal => {
          return deal.id === dealId;
        });
        if (target) {
          goToDeal(target);
        }
      }
    },
    [goToDeal, route.params?.dealId],
  );
  const leaveGroup = useLeaveGroup();
  const deleteGroup = useDeleteGroup();
  const role = useGroupRole(data);
  const submitFeedback = useSubmitFeedback();
  const headerRight = useMemo(() => {
    if (!role) return () => null;
    let text = 'Leave Group';
    let action = leaveGroup;

    if (role === 'admin') {
      text = 'Delete Group';
      action = deleteGroup;
    }
    const onPress = async () => {
      try {
        setDisableHeaderLink(true);
        const result = await action(data);
        if ((result as any) !== 'cancelled') {
          navigation.goBack();
        }
      } catch (err) {
        //TODO - let user know?
        console.warn(err);
      }
      setDisableHeaderLink(false);
    };
    return () => (
      <HeaderLink title={text} onPress={onPress} disabled={disableHeaderLink} />
    );
  }, [data, role, setDisableHeaderLink, disableHeaderLink, navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerLargeTitle: true,
      title: loading ? 'Fetching...' : data?.displayName,
      headerRight,
    });
  }, [headerRight, loading, data?.displayName]);

  if (loading) {
    return <DealsLoading />;
  }
  if (error) {
    <Box margin={'m'}>
      <Text>This is unexpected...</Text>
      <Text variant={'caption2'}>We recorded the error, </Text>
    </Box>;
  }
  return (
    <SafeAreaView style={{flex: 1, paddingTop: 0}}>
      <Box paddingHorizontal={'m'} paddingTop={'m'}>
        <GroupNotifications group={data} />
      </Box>
      <GroupMembers group={data} />
      <GroupDeals onLoad={handleDealNavigation} group={data} />
      <Box margin={'m'}>
        <Text variant={'subhead'}>{data.description}</Text>
        <Box
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Box flexDirection={'row'}>
            <Text variant={'caption1'}>Created by {data.createdBy?.name}</Text>
            <Text variant={'caption1'}> on {getCreatedDate(data)}</Text>
          </Box>
          <Link onPress={submitFeedback} title={'Send Feedback'} />
        </Box>
      </Box>
    </SafeAreaView>
  );
};

const GroupMembers = ({group}: {group: Group}) => {
  const members = group.members;
  const [loadUsers, queryResult] = useLoadGroupMembers();

  const fetchMembers = useCallback(() => {
    if (queryResult.loading) return;
    loadUsers({
      variables: {
        userIds: Object.keys(members),
      },
    });
  }, [loadUsers, members, queryResult.loading]);

  return (
    <List.Accordion
      onPress={fetchMembers}
      title={`View ${Object.keys(members).length} members`}>
      {(queryResult.loading && <ActivityIndicator />) ||
        queryResult?.data?.loadUsers.map((usr, i) => {
          return (
            <List.Item
              style={{justifyContent: 'center', alignItems: 'center'}}
              key={`${i}-${usr.displayName}`}
              left={() => {
                const size = 30;
                if (usr.photoURL) {
                  return (
                    <Avatar.Image source={{uri: usr.photoURL}} size={size} />
                  );
                } else {
                  const initials = (usr.displayName ?? 'Private User')
                    .split(' ')
                    .reduce((current, word, index) => {
                      if (index < 2) {
                        return current + word[0];
                      }
                      return current;
                    }, '');
                  return <Avatar.Text size={size} label={initials} />;
                }
              }}
              title={usr.displayName ?? 'Private User'}
            />
          );
        })}
    </List.Accordion>
  );
};

interface GroupDealsProps {
  group: Group;
  onLoad?: (deals: Deal[]) => void;
}

const GroupNotifications = ({group}: {group: Group}) => {
  const subscribeToGroup = useSubscribeToGroup();
  const unsubscribeToGroup = useUnsubscribeToGroup();
  const tags = useTags();
  const isSubscribed = useMemo(() => {
    if (!tags) {
      return false;
    }
    return !!tags[`group-${group.id}`];
  }, [tags, group]);
  const onValueChange = useCallback(() => {
    let action = subscribeToGroup;
    if (isSubscribed) {
      action = unsubscribeToGroup;
    }
    action(group);
  }, [subscribeToGroup, isSubscribed]);
  return (
    <Switch
      label={'Subscribe to notifications'}
      value={isSubscribed}
      onValueChange={onValueChange}
      disabled={!tags}
    />
  );
};

const GroupDeals = ({group, onLoad}: GroupDealsProps) => {
  const deals = useGroupDeals(group.id);
  const currentUser = useCurrentUser();
  const [removing, setRemoving] = useState(false);
  const removeDeal = useRemoveDealFromGroup();
  const navigation = useNavigation();
  const role = useGroupRole(group);
  useEffect(() => {
    if (deals) {
      onLoad(deals);
    }
  }, [deals]);
  const onRemove = useCallback(
    async (deal: Deal) => {
      setRemoving(true);
      try {
        await removeDeal(group, deal.id);
      } catch (err) {
        console.log(err);
        Alert.alert(
          'Unable to remove group',
          `We're looking into it, please try again a little later, or contact support!`,
        );
      }
      setRemoving(false);
    },
    [removeDeal, group],
  );

  const navigateToAddProperties = useCallback(() => {
    const existing = deals?.reduce((total, deal) => {
      const dealGuid = deal.id?.split('#').pop();
      total[dealGuid] = true;
      return total;
    }, {});
    navigation.navigate('add-properties', {
      currentProperties: existing ?? {},
      group,
    });
  }, [deals, group, navigation]);

  if (deals === undefined) {
    return <DealsLoading />;
  }
  return (
    <>
      {(role === 'admin' && (
        <Link
          marginLeft={'m'}
          paddingBottom={'m'}
          color={'link'}
          title={'Add Property'}
          onPress={navigateToAddProperties}
        />
      )) ||
        null}

      <FlatList
        contentContainerStyle={{flexGrow: 1}}
        style={{flex: 1}}
        data={deals}
        keyExtractor={item => item.id}
        ListEmptyComponent={
          <Text paddingHorizontal={'m'}>No deals shared with the group.</Text>
        }
        renderItem={({item}) => {
          const isMyDeal = item.createdBy?.id === currentUser.uid;
          return (
            <DealListItem
              disableSwipe={!isMyDeal}
              onDelete={onRemove}
              deleting={removing}
              deleteText={'Remove'}
              deal={item as DealState}
              additionalContent={
                <Text color={'grey3'} paddingTop={'m'}>
                  Shared on {formatDate(item.sharedTimestamp)}
                </Text>
              }
            />
          );
        }}
      />
    </>
  );
};
