import {useEffect, useState} from 'react';

const incomeOptions: CashflowOptions<IncomeTag>[] = [
  {
    name: 'Rent',
    tag: 'rent',
  },
  {
    name: 'Other',
    tag: 'other',
  },
];

const expenseOptions: CashflowOptions<ExpenseTag>[] = [
  {
    name: 'Utilities',
    tag: 'water',
  },
  {
    name: 'HOA',
    tag: 'hoa',
    explanation: 'hoa',
  },
  {
    name: 'Property Mgmt',
    tag: 'management',
    type: 'percent',
    explanation: 'propertymanagement',
  },
  {
    name: 'Electric',
    tag: 'electric',
  },
  {
    name: 'Vacancy',
    tag: 'vacancy',
    type: 'percent',
    // value: 0.05
  },
  {
    name: 'Cap Ex',
    tag: 'capex',
    type: 'percent',
    explanation: 'capex',
    // value: 0.1
  },
  {
    name: 'Maint. Costs',
    tag: 'repairs',
    type: 'percent',
    // value: 0.05
  },
  {
    name: 'Sanitation',
    tag: 'sanitation',
  },
  {
    name: 'PMI',
    tag: 'pmi',
    explanation: 'pmi',
  },
  {
    name: 'Taxes',
    tag: 'taxes',
  },
  {
    name: 'Home Insurance',
    tag: 'insurance',
  },
  {
    name: 'Other',
    tag: 'other',
  },
];

export const useOptions = (type: 'income' | 'expense') => {
  const [options, setOptions] = useState<CashflowOptions<any>[]>([]);
  useEffect(() => {
    setOptions(type === 'expense' ? expenseOptions : incomeOptions);
  }, [type]);
  return options;
};
