import {useKeyboardListener} from './useKeyboardListener';
import {useEffect, useState} from 'react';
import {Platform} from 'react-native';

export const useKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const {
    startCoordinates = {screenY: undefined},
    endCoordinates = {height: undefined, screenY: undefined},
  } = useKeyboardListener();
  /*eslint-disable */

  if (Platform.OS === 'android') {
    useEffect(() => {
      console.log(endCoordinates);
      if (endCoordinates.height !== undefined) {
        setKeyboardHeight(endCoordinates.height);
      }
    }, [endCoordinates.height]);
  }
  if (Platform.OS === 'ios') {
    useEffect(() => {
      if (
        startCoordinates.screenY === undefined ||
        endCoordinates.screenY === undefined
      )
        return;
      const target = startCoordinates.screenY - endCoordinates.screenY;
      if (target === 0) return;
      setKeyboardHeight(Math.max(target, 0));
    }, [startCoordinates.screenY, endCoordinates.screenY]);
  }
  /*eslint-enable */

  return keyboardHeight;
};
