import {useEffect, useState} from 'react';

import {loadMyGroups} from './groupsService';
import {Group} from './types';

export const useCurrentUserGroups = () => {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    return loadMyGroups(groups => {
      setGroups(groups);
      setLoading(false);
    });
  }, []);
  return {groups, loading};
};
