import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changePredictions} from '../slice';

export const useUpdatePredictions = () => {
  const dispatch = useDispatch();
  const updatePredictions = useCallback(
    financingDetails => {
      dispatch(changePredictions(financingDetails));
    },
    [dispatch],
  );
  return updatePredictions;
};
