import {
  View,
  StyleSheet,
  Switch as RNSwitch,
  SwitchProps,
  StyleProp,
  ViewStyle,
} from 'react-native';
import React from 'react';
import {Label} from './Label';
import {useTheme, useStyle} from '../core/theme';
import {Explanations} from '@houseque/core/explanations/types';

interface HQSwitchProps extends SwitchProps {
  label?: string;
  explanation?: Explanations;
  containerStyle?: StyleProp<ViewStyle>;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const Switch = ({label, explanation, ...props}: HQSwitchProps) => {
  const theme = useTheme();
  const themedStyles = useStyle(({edge: {small}}) => ({
    container: {
      paddingBottom: small,
    },
  }));
  return (
    <View style={[themedStyles.container, styles.row, props.containerStyle]}>
      <Label explanation={explanation}>{label}</Label>
      <RNSwitch
        trackColor={{true: theme.colors!.secondary!, false: ''}}
        {...props}
      />
    </View>
  );
};
