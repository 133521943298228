import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Group} from '../../member/screens/groups/types';
import {subscribeToGroup} from './slice';

export const useSubscribeToGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    (group: Group) => {
      dispatch(subscribeToGroup(`group-${group.id}`));
    },
    [dispatch],
  );
};
