import {ScenarioState} from '@houseque/types';
import {Scenario} from '@houseque/types';
import {ScenariosCategory} from '@houseque/types';

export const selectCurrentScenario = <T>(state: ScenarioState<T>) => {
  return state.scenarios[state.currentScenarioIndex].value;
};

type CategoricalActions = {
  [index in ScenariosCategory]?: {
    addScenario: (payload: any) => {type: string; payload: any};
    renameScenario: (payload: any) => {type: string; payload: any};
    changeCurrentScenario: (payload: any) => {type: string; payload: any};
    removeScenario: (payload: any) => {type: string; payload: any};
  };
};

const categories: ScenariosCategory[] = [
  'cashflow',
  'financing',
  'predictions',
  'rehab',
];

const createScenarioActions = (name = '') => {
  const ADD = `ADD_SCENARIO_${name.toUpperCase()}`;
  const RENAME = `RENAME_SCENARIO_${name.toUpperCase()}`;
  const CHANGE = `CHANGE_CURRENT_SCENARIO_${name.toUpperCase()}`;
  const REMOVE = `REMOVE_SCENARIO_${name.toUpperCase()}`;

  function addScenario(payload: Scenario<unknown>) {
    return {
      type: ADD,
      payload,
    };
  }

  function renameScenario(payload: Scenario<unknown>) {
    return {
      type: RENAME,
      payload,
    };
  }

  /**
   * Removes the scenario at the given index
   * @param payload Index of the scenario
   * @returns
   */
  function removeScenario(payload: number) {
    return {
      type: REMOVE,
      payload,
    };
  }

  function changeCurrentScenario(payload: number) {
    return {
      type: CHANGE,
      payload,
    };
  }

  return {
    addScenario,
    renameScenario,
    changeCurrentScenario,
    removeScenario,
  };
};

export const categoricalActions: CategoricalActions = categories.reduce(
  (mapped, category) => {
    mapped[category] = createScenarioActions(category);
    return mapped;
  },
  {},
);
