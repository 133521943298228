import {useUpdateRehab} from '@houseque/core/deals/actions';
import {useRehab} from '@houseque/core/deals/selectors/useRehab';
import React, {useCallback} from 'react';
import {Switch, TextInput} from '../../../components';

interface BorrowAgainstArvProps {
  borrowingAgainstArv: boolean;
  onValueChange: (value: boolean) => void;
}

export const BorrowAgainstArv = ({
  borrowingAgainstArv,
  onValueChange,
}: BorrowAgainstArvProps) => {
  const rehab = useRehab();
  const changeRehab = useUpdateRehab();

  const onChangeText = useCallback(
    arv => {
      changeRehab({
        arv,
      });
    },
    [changeRehab],
  );

  return (
    <>
      <Switch
        label={'Borrow against ARV?'}
        value={borrowingAgainstArv}
        onValueChange={onValueChange}
      />
      {(borrowingAgainstArv && (
        <TextInput
          label={'ARV'}
          explanation={'arv'}
          value={rehab.arv ?? undefined}
          onChangeText={onChangeText}
          type={'currency'}
        />
      )) ||
        null}
    </>
  );
};
