import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {removeLoan} from '../../slice';

export const useRemoveLoan = () => {
  const dispatch = useDispatch();
  const onRemoveLoan = useCallback(
    (removeRefinance: boolean) => {
      dispatch(removeLoan(removeRefinance));
    },
    [dispatch],
  );
  return onRemoveLoan;
};
