import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import authSlice from './slice';

export const useLogin = () => {
  const dispatch = useDispatch();
  return useCallback(
    (email: string, password: string) => {
      dispatch(authSlice.actions.authLogin({email, password}));
    },
    [dispatch],
  );
};
