import React, {useState, useCallback} from 'react';
import {TouchableHighlight} from 'react-native-gesture-handler';
import {Icon} from './Icon';
import {Text} from './Text';
import {ActivityIndicator, StyleProp, ViewStyle} from 'react-native';

interface ErrorWithRetryProps {
  text: string;
  onRetry: () => any;
  containerStyle?: StyleProp<ViewStyle>;
}

export const ErrorWithRetry = ({
  text,
  onRetry,
  containerStyle,
}: ErrorWithRetryProps) => {
  const [retrying, setRetrying] = useState(false);
  const onPress = useCallback(async () => {
    try {
      setRetrying(true);
      await onRetry();
    } catch (err) {
    } finally {
      setRetrying(false);
    }
  }, [onRetry]);
  if (retrying) {
    return <ActivityIndicator />;
  }
  return (
    <TouchableHighlight
      style={containerStyle}
      onPress={onPress}
      disabled={retrying}>
      <>
        <Icon color={'grey3'} icon={'refresh'} />
        <Text color={'grey1'}>{text}</Text>
      </>
    </TouchableHighlight>
  );
};
