import React from 'react';
import {TextProps} from 'react-native-elements';
import {Text} from '@houseque/components';
import {useStyle} from '@houseque/core/theme';

interface SectionHeaderProps extends TextProps {
  children: any;
  subheader?: boolean;
}

export const SectionHeader = ({
  children,
  subheader,
  ...props
}: SectionHeaderProps) => {
  const styles = useStyle(({colors, font, edge}) => {
    return {
      header: {
        padding: edge.small,
        fontWeight: '400',
        fontSize: font.size.xlarge,
        color: colors.grey0,
      },
      subheader: {
        fontSize: font.size.large,
        color: colors.grey1,
      },
    };
  });
  return (
    <Text style={[styles.header, subheader && styles.subheader]} {...props}>
      {children}
    </Text>
  );
};
