import {ARMType, AmortizationPayment} from '@houseque/types';
import {calculatePayment} from './loanPayment';

const DEFAULT_MODIFIER = (item: AmortizationPayment) => item;
const ASSUMED_YEAR_OVER_YEAR_INCREASE = 0.0005;

export interface AmortizationGeneratorOptions {
  amount: number;
  rate: number;
  totalPayments: number;
  payment?: number;
  armType?: ARMType;
  modifier?: <T extends AmortizationPayment>(
    amortizationEntry: AmortizationPayment,
  ) => T | AmortizationPayment;
}

export const generateVariableAmortization = ({
  amount,
  rate,
  totalPayments,
  armType,
  modifier = DEFAULT_MODIFIER,
}: AmortizationGeneratorOptions) => {
  let currentBalance = amount;
  const numPeriods = Math.floor(totalPayments / 12);
  const [initialPeriod, changeInterval] = armType!.split('/');
  const initialPayment = calculatePayment(rate, totalPayments, amount);
  let currentPayment = initialPayment;
  let periodInterest = rate / 12;
  let currentPeriod = 0;
  //Here we are makiing assumptions on the changes of the Fed Rate over the years, make this more extensible later
  const adjustments = new Array(numPeriods).fill(1).map((value, index) => {
    if (index >= +initialPeriod) {
      const currentPeriod = Math.floor(index / +changeInterval);
      return ASSUMED_YEAR_OVER_YEAR_INCREASE * currentPeriod;
    }
    //Keep flat for first for intervals
    return 0;
  });
  //   const variations =
  const amortization = new Array(totalPayments).fill(0).map((_, index) => {
    const period = Math.floor(index / 12);
    const adjustment = adjustments[period];
    const isLastPayment = currentPayment > currentBalance;
    const hasPeriodChanged = period !== currentPeriod;
    if (adjustment && hasPeriodChanged) {
      currentPeriod = period;
      periodInterest = (rate + adjustment) / 12;
      currentPayment = calculatePayment(
        rate + adjustment,
        totalPayments - index,
        currentBalance,
      );
    }

    const interest = isLastPayment ? 0 : currentBalance * periodInterest;
    const principal = currentPayment - interest;
    currentBalance = Math.max(currentBalance - principal, 0);
    return modifier({
      paymentNumber: index + 1,
      period: period + 1,
      payment: +currentPayment.toFixed(2),
      principal: +principal.toFixed(2),
      interest: +interest.toFixed(2),
      currentBalance: +currentBalance.toFixed(2),
    });
  });
  return amortization;
};

/**
 *
 * @param amount
 * @param rate
 * @param totalPayments
 * @param payment
 */
export const generateAmortization = ({
  amount,
  rate,
  totalPayments,
  payment,
  modifier = DEFAULT_MODIFIER,
}: AmortizationGeneratorOptions) => {
  let currentBalance = amount;
  const periodInterest = rate / 12;
  // const payment = calculatePayment(rate, totalPayments, amount);
  const amortization = new Array(totalPayments)
    .fill(payment)
    .map((payment, index) => {
      const period = Math.floor(index / 12);
      const willPayoff = payment > currentBalance;
      const interest = willPayoff ? 0 : currentBalance * periodInterest;
      const principal = payment - interest;
      currentBalance = Math.max(currentBalance - principal, 0);
      return modifier({
        principal,
        interest,
        currentBalance,
        payment,
        paymentNumber: index + 1,
        period: period + 1,
      });
    });
  return amortization;
};
