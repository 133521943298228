import React, {useState, useEffect} from 'react';
import {Avatar, AvatarProps} from 'react-native-elements';
import {ActivityIndicator, ImageURISource} from 'react-native';
import {UserInitials} from './UserInitials';
import {UserProfile} from '@houseque/types';
import FastImage from 'react-native-fast-image';

interface UserAvatarProps extends AvatarProps {
  user?: UserProfile;
}

export const UserAvatar = ({source, user, ...props}: UserAvatarProps) => {
  const [uriSource, setUriSource] = useState(source);
  const userPhoto = user?.photoURL;
  useEffect(() => {
    if (userPhoto && !source) {
      setUriSource({uri: userPhoto});
    }
  }, [userPhoto, source]);

  const [Component] = useState(
    (source as ImageURISource)?.uri ? (
      <ActivityIndicator />
    ) : (
      <UserInitials displayName={user?.displayName} />
    ),
  );
  return (
    <Avatar
      rounded
      imageProps={{
        PlaceholderContent: Component,
      }}
      ImageComponent={FastImage as any}
      size={'large'}
      source={{
        ...(uriSource as ImageURISource),
      }}
      {...props}
    />
  );
};
