import {useCallback} from 'react';
import {changeFinancing} from '../../slice';
import {useDispatch} from 'react-redux';
import {Mortgage} from '@houseque/types';

export const useChangeFinancing = () => {
  const dispatch = useDispatch();
  return useCallback(
    (loans: Mortgage[]) => {
      dispatch(changeFinancing(loans));
    },
    [dispatch],
  );
};
