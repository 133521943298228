import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Group} from './types';

export interface GroupState {
  searching?: boolean;
  searchResults?: Group[];
  searchError?: any;
}

const initialState: GroupState = {
  searching: false,
};

const slice = createSlice({
  initialState,
  name: 'group',
  reducers: {
    searchGroupsBeginning: state => {
      state.searching = true;
    },
    searchGroupsError: (state, action: PayloadAction<Error>) => {
      state.searchError = action.payload;
      state.searching = false;
    },
    searchGroupsComplete: (state, action: PayloadAction<Group[]>) => {
      state.searchResults = action.payload;
      state.searching = false;
    },
  },
});

export default slice;
