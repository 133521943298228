import {useEffect, useState} from 'react';
import {calculatePayment} from '@houseque/core/calculations';

export const useComputedPayment = (
  interestRate: number,
  totalPayments: number,
  loanAmount: number,
  interestOnly = false,
) => {
  const [payment, setPayment] = useState<number | undefined>(undefined);
  useEffect(() => {
    setPayment(
      calculatePayment(interestRate, totalPayments, loanAmount, interestOnly),
    );
  }, [interestRate, totalPayments, loanAmount, interestOnly]);
  return payment;
};
