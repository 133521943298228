import React from 'react';
import {ViewStyle, StyleProp, TouchableOpacityProps} from 'react-native';
import {Chip} from 'react-native-paper';

interface SelectionProps extends TouchableOpacityProps {
  style?: StyleProp<ViewStyle>;
  selected: boolean;
  title: string;
  onPress: () => void;
}

export const Selection = ({
  title,
  selected,
  onPress,
  style,
  ...props
}: SelectionProps) => {
  return (
    <Chip
      selectedColor={'black'}
      style={{marginRight: 5, paddingHorizontal: 5, paddingVertical: 3}}
      mode={'outlined'}
      onPress={onPress}
      selected={selected}>
      {title}
    </Chip>
  );
};
