import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {updateFinancing} from '../slice';

export const useUpdateLoan = () => {
  const dispatch = useDispatch();
  const onUpdateFinancing = useCallback(
    financingDetails => {
      dispatch(updateFinancing(financingDetails));
    },
    [dispatch],
  );
  return onUpdateFinancing;
};
