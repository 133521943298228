import {ScenariosCategory, ScenarioState} from '@houseque/types';
import {useSelector} from 'react-redux';
import {HouseQueAppState} from '../../houseque';

export const useCategoryScenarioState = (category: ScenariosCategory) => {
  const state: ScenarioState<any> = useSelector(
    (store: HouseQueAppState) => (store.deals.selectedDeal as any)[category],
  );
  return state;
};
