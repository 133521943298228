import {useCallback} from 'react';
import {preferencesService} from './service';
import {PresetValue} from './types';
import {exceptionCaughtAction} from '@houseque/core/application/exceptions';
import {useDispatch} from 'react-redux';

export const useSavePresets = () => {
  const dispatch = useDispatch();
  return useCallback(async (presets: PresetValue[]) => {
    try {
      await preferencesService.savePresets(presets);
    } catch (err) {
      dispatch(exceptionCaughtAction(err));
      throw err;
    }
  }, []);
};
