import {useState, useMemo} from 'react';
import {Deal} from '@houseque/types';

export const useFilteredDeals = (
  deals: Deal[],
): [string, (string) => void, Deal[]] => {
  const [filterText, setFilterText] = useState('');
  return useMemo(() => {
    const filterBy = filterText.toLowerCase();
    return [
      filterText,
      setFilterText,
      [...deals] //immer doesn't seem to allow sorts?
        .sort((a, b) => +b.timestamp! - +a.timestamp!)
        .filter(deal => {
          if (filterText) {
            const nameMatch = deal.name?.toLowerCase().includes(filterBy);
            const addressMatch = deal.property?.address
              ?.toLowerCase()
              .includes(filterBy);
            return nameMatch || addressMatch;
          }
          return true;
        }),
    ];
  }, [deals, filterText]);
};
