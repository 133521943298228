import React, {useRef, useEffect} from 'react';
import {Animated} from 'react-native';
import {ListItem} from 'react-native-elements';
import {Text} from '../../../components';

const fakeDeals = new Array(4).fill('');

export const DealsLoading = () => {
  const animation = useRef(new Animated.Value(1));

  useEffect(() => {
    const sequence = Animated.sequence([
      Animated.timing(animation.current, {
        toValue: 0.4,
        useNativeDriver: true,
        duration: 600,
      }),
      Animated.timing(animation.current, {
        toValue: 1,
        useNativeDriver: true,
        duration: 600,
      }),
    ]);
    Animated.loop(sequence).start();
    return Animated.loop(sequence).stop;
  }, []);
  return (
    <Animated.View style={{opacity: animation.current}}>
      <Text variant={'subhead'} padding={'m'} color={'grey1'}>
        Loading Properties...
      </Text>
      {fakeDeals.map((_, i) => {
        return <DealLoading key={i} />;
      })}
    </Animated.View>
  );
};

const DealLoading = () => {
  return <ListItem bottomDivider chevron title={''} subtitle={'\n'} />;
};
