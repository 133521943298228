import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {EstimatePane} from './EstimatePane';
import {HouseQueAppState} from '../../../../../houseque';

interface CashflowProps {
  monthly?: boolean;
  customLabel?: string;
}

export const Cashflow = ({
  monthly = false,
  customLabel = '',
}: CashflowProps) => {
  const [label, setLabel] = useState(customLabel);
  const monthlyCashflow = useSelector(
    (store: HouseQueAppState) =>
      store.deals.selectedDealEstimates.monthlyCashflow,
  );

  useEffect(() => {
    if (!customLabel) {
      setLabel(monthly ? 'Monthly Cashflow' : 'Annual Cashflow');
    }
  }, [monthly, customLabel]);
  return (
    <EstimatePane
      explanation={'cashflow'}
      value={monthly ? monthlyCashflow! : monthlyCashflow! * 12}
      valueType={'currency'}
      label={label}
    />
  );
};
