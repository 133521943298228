import React from 'react';
import {
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
} from 'react-native';

const onIos = Platform.OS === 'ios';

interface KeyboardAvoidingViewPropsExtended extends KeyboardAvoidingViewProps {
  children: any;
}

/**
 * Android handles keyboard avoiding pretty well by default, using this to disable without having to
 * check the platform everywhere.
 * @param props
 */
export const KeyboardAvoidingView = (
  props: KeyboardAvoidingViewPropsExtended,
) => {
  return <RNKeyboardAvoidingView enabled={onIos} {...props} />;
};
