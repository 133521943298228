import {useCallback} from 'react';
import {upsertCashflow} from '../../slice';
import {useDispatch} from 'react-redux';

export const useUpsertRent = () => {
  const dispatch = useDispatch();

  return useCallback(
    (value: number) => {
      const income: Income = {
        tag: 'rent',
        amount: value,
        amountType: 'fixed',
        type: 'income',
      };
      dispatch(upsertCashflow(income));
    },
    [dispatch],
  );
};
