export const data = {
  scenarios: {
    title: 'What are Scenarios?',
    text:
      'Scenarios allow you to quickly check various configurations.\n\nScenarios are specific to each section, so updating the scenario within financing, will only effect those calculations.\n\nFor example, how would a 15 year mortgage compare to a 30 year?',
  },
  caprate: {
    title: 'Capitalization Rate',
    text:
      'Cap Rate is the annual net operating income, divided by the property purchase price.\n\n Net operating income = total income - total expenses (excluding loan payments)',
  },
  capex: {
    title: 'Capital Expenditures',
    text: `To put simply, capital expenditures are "big ticket" items in the home
		\nThe value here refers to how much rent you set aside for these expenses
		\nExamples of this include roofing, central air and heating, major plumbing etc`,
  },
  arv: {
    title: 'After Repair Value',
    text: `This is how much the property will be worth, after rehabilitation work is complete.`,
  },
  hoa: {
    title: 'Home Owners Association',
    text: `It is an organization developed for the purpose of managing and maintaining the common interest portions of a community
		\nThis is very specific to the area in which you're buying, and very common in condominiums`,
  },
  cashoncash: {
    title: 'Cash on Cash',
    text: `Cash on cash gives you an idea how the money you actually spent, is performing.  The formula is pre-tax cashflow, divided by the money you've spent out of pocket.\n\nExample: You purchase a property for $100,000, with a loan that required 20% down, and you include the closing costs in the loan, so you are out of pocket $20,000.  Let's say the loan payment is $400, and the rest of the expenses are $825 (includes property taxes, insurance, utilities, electric, etc.).  You anticipate rent being $2000 a month.  You expect to make about $4,262 a year in this case, divided by the $20,000 investment up front, you get a 21% cash on cash return`,
  },
  cashflow: {
    title: 'Cashflow',
    text:
      'Cashflow represents how money is flowing over a given period with a property.  The cashflow formula is the sum of all income, minus the sum of all expenses including the mortgage payment (also known as debt service)',
  },
  pmi: {
    title: 'Private Mortgage Insurance',
    text: `This is a type of insurance typically applied to any mortgage with a down payment of less than 20%
		\nThis can be removed (usually by request) when you have attained over 20% equity, and will automatically be removed at 22%`,
  },
  propertymanagement: {
    title: 'Property Management',
    text: `This is how much of your rent is allocated to the outsourcing of managing the property
		\nThis typically runs from 7-12%`,
  },
  closingcosts: {
    title: 'Closing Costs',
    text:
      'Costs associated with finalizing the mortgage.  Examples include attorney fees, title fees, lender fees, etc.',
  },
  arm: {
    title: 'Adjustable Rate Mortgage',
    text: `This is a type of rate that "adjusts" over the lifetime of the mortgage.  This usually corresponds with the federal rate
		\nThis generally shows in the format of 5/1 - with 5 representing the number of years fixed at the initial rate, and the 1 representing how frequently (in years) it adjusts thereafter`,
  },
  term: {
    title: 'Term',
    text: `Measured in years, this represents how long it will take to pay off the loan.
		\nMortgages are typically 30, or 15 years`,
  },
  'searchable-deal': {
    title: 'Searchable Deals',
    text: `By enabling the deal to be searchable, any House Que user can find this deal with the search feature.  
		\nNote: Be sure to select the address from the results, and ensure your email is correct for any inquiries.`,
  },
  groups: {
    title: 'Groups',
    text:
      'Groups allow simple and powerful sharing of real estate investments with others.  This could be for education, investment partners, clients, and more!  Subscribe to the group to get a heads up immediately on new opportunities!',
  },
} as const;
