import {omit} from '@houseque/core/shared';
import {Deal} from '@houseque/types';
import {QueryOptions} from '@apollo/client';
import {getClient} from '../../apollo';
import {LoadUserDeals} from '../queries';

interface DealQL {
  myDeals: Deal[];
}

export const loadUserDeals = async (
  options: Partial<QueryOptions> = {
    fetchPolicy: 'cache-first',
  },
) => {
  const apolloClient = await getClient();
  const result = await apolloClient.query<DealQL>({
    ...options,
    query: LoadUserDeals,
  });
  return result.data.myDeals?.map(deal => omit(deal, '__typename')) ?? [];
};
