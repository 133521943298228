import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {logEventAction} from './actions';

export const useLogEvent = () => {
  const dispatch = useDispatch();

  return useCallback(
    (name: string, params?: any) => {
      dispatch(
        logEventAction({
          name,
          params,
        }),
      );
    },
    [dispatch],
  );
};
