import {useSelector, shallowEqual} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {HouseQueAppState} from '../../../houseque';
import {Prediction} from '@houseque/types';
import {usePresets} from '../../../member/preferences/usePresets';
import {selectCurrentScenario} from '../../scenarios';

export const usePredictions = (): Prediction => {
  const {data: presets = []} = usePresets('assumptions');
  const prediction = useSelector<HouseQueAppState, Prediction>(
    useCallback((app: HouseQueAppState) => {
      return selectCurrentScenario(app.deals.selectedDeal.predictions);
    }, []),
    shallowEqual,
  );
  return useMemo(() => {
    return presets.reduce((total, preset) => {
      let field = undefined;
      switch (preset.subcategory) {
        case 'rent':
          field = 'periodicRentVariance';
          break;
        case 'expenses':
          field = 'periodicExpenseAppreciation';
          break;
        case 'propertyvalue':
          field = 'periodicPropertyAppraisal';
          break;
        case 'sell':
          field = 'eventualSellingCosts';
          break;
        default:
          break;
      }
      if (field) {
        total[field] = total[field] ?? preset.value;
      }
      return total;
    }, prediction);
  }, [presets, prediction]);
};
