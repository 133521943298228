import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {upsertCashflow} from '../../slice';

type Frequency = 'annual' | 'monthly';

export const useUpsertExpense = () => {
  const dispatch = useDispatch();

  return useCallback(
    (value: number, tag: ExpenseTag, frequency: Frequency = 'monthly') => {
      const expense: Expense = {
        tag,
        amount: frequency === 'monthly' ? value : value / 12,
        amountType: 'fixed',
        type: 'expense',
      };
      dispatch(upsertCashflow(expense));
    },
    [dispatch],
  );
};
