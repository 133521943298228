import React, {useState, useEffect} from 'react';
import {Text} from './Text';
import {TextProps} from 'react-native-elements';
import {useCurrentUser} from '@houseque/core/application/user';

interface UserInitialProps extends Omit<TextProps, 'children'> {
  displayName?: string;
}

export const UserInitials = ({
  displayName,
  style,
  ...props
}: UserInitialProps) => {
  const user = useCurrentUser();
  const [initials, setInitials] = useState('');
  useEffect(() => {
    const initialLetters = (displayName ?? user.displayName ?? '')
      .split(' ')
      .map(word => word && word[0].toUpperCase())
      .join('')
      .substring(0, 3);
    setInitials(initialLetters);
  }, [user.displayName, displayName]);
  return (
    <Text
      color={'light'}
      fontSize={20}
      fontWeight={'500'}
      style={[style]}
      {...props}>
      {initials}
    </Text>
  );
};
