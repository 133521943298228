import {ApolloLink, Observable, Operation} from '@apollo/client';
import {retrieveAuthToken} from '@houseque/core/application/user';

const request = async (operation: Operation) => {
  const token = await retrieveAuthToken();
  operation.setContext({
    headers: {
      authorization: token,
    },
  });
};

export const authenticationLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward!(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));
      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);
