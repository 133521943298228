import {Prediction} from '@houseque/types';
import {DealState} from './deal';

const initialPrediction: Prediction = {
  eventualSellingCosts: null,
  periodicExpenseAppreciation: null,
  periodicPropertyAppraisal: null,
  periodicRentVariance: null,
};

export const defaultDeal: DealState = {
  id: undefined,
  name: undefined,
  cashflow: {
    currentScenarioIndex: 0,
    scenarios: [
      {
        name: null,
        value: {
          income: [],
          expenses: [],
        },
      },
    ],
  },
  predictions: {
    currentScenarioIndex: 0,
    scenarios: [
      {
        name: null,
        value: initialPrediction,
      },
    ],
  },
  property: {
    address: null,
    sqFt: null,
    location: {},
  },
  financing: {
    currentScenarioIndex: 0,
    scenarios: [
      {
        name: null,
        value: {
          price: 0,
          closingCosts: 0,
          loans: [],
        },
      },
    ],
  },
  estimate: {},
  rehab: {
    currentScenarioIndex: 0,
    scenarios: [
      {
        name: null,
        value: {rehabCosts: 0, holdingCosts: 0, duration: 0, arv: 0},
      },
    ],
  },
  local: {
    unsavedChanges: false,
    photos: [],
  },
};
