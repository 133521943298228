import React, {useCallback} from 'react';
import {Box, Text} from '../../components';
import {useSelector} from 'react-redux';
import {useExplanation} from './useExplanation';
import {useDispatch} from 'react-redux';
import {dismissExplanation} from './slice';
import {HouseQueAppState} from '../../houseque';
import {Overlay} from 'react-native-elements';

export const Explanation = () => {
  const dispatch = useDispatch();
  const {visible, which} = useSelector(
    useCallback((store: HouseQueAppState) => {
      return store.explanation;
    }, []),
  );
  const exit = useCallback(() => {
    dispatch(dismissExplanation());
  }, []);
  const explanation = useExplanation(which!);

  return (
    <Overlay
      onBackdropPress={exit}
      isVisible={visible}
      hardwareAccelerated
      animated
      animationType={'fade'}>
      <Box padding={'s'} backgroundColor={'light'}>
        <Text paddingBottom={'m'} variant={'title1'} color={'primary'}>
          {explanation.title}
        </Text>
        <Text textAlign={'left'} variant={'body'}>
          {explanation.text}
        </Text>
      </Box>
    </Overlay>
  );
};
