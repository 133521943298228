import React, {useState, useEffect} from 'react';
import {StyleSheet} from 'react-native';
import {
  TextInput,
  Button,
  Text,
  Box,
  DismissKeyboardView,
} from '@houseque/components';
import {useResetPassword} from '../authentication';
import {useRoute} from '@react-navigation/native';

export const ForgotPassword = () => {
  const route = useRoute();
  const [email, setEmail] = useState((route.params as any)?.email ?? '');
  const [disableButton, setButtonDisabled] = useState(!email);
  const [resetPassword, state] = useResetPassword();

  useEffect(() => {
    let disabled = false;
    if (['loading', 'success'].indexOf(state.state!) >= 0 || !email) {
      disabled = true;
    }
    setButtonDisabled(disabled);
  }, [state, email]);

  return (
    <DismissKeyboardView>
      <Box flex={1} justifyContent={'center'} padding={'l'}>
        <TextInput
          defaultValue={email}
          textContentType={'username'}
          keyboardType={'email-address'}
          autoCorrect={false}
          autoCapitalize={'none'}
          onChangeText={setEmail}
          label={'Email address'}
        />
        <Box paddingTop={'m'}>
          {(state.message && (
            <Text paddingBottom={'m'}>{state.message}</Text>
          )) ||
            null}
          <Button
            disabled={disableButton}
            rounded
            loading={state.state === 'loading'}
            title={'Reset Password'}
            onPress={() => resetPassword(email)}
          />
        </Box>
        <Box flex={0.2} />
      </Box>
    </DismissKeyboardView>
  );
};
