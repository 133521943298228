import React from 'react';
import {useRoute} from '@react-navigation/core';
import {Link} from '@react-navigation/native';
import {useFormik} from 'formik';
import {useLogin} from '../authentication';

import {
  Button,
  Text,
  TextInput,
  Box,
  DismissKeyboardView,
} from '../../components';
import {useAuthenticationState} from '../authentication';
import {SocialLogins} from '../SocialLogins';

const DEFAULT_FORM = {email: '', password: ''};

export const LoginScreen = () => {
  const {params} = useRoute() as {params: any};
  const authState = useAuthenticationState();
  const login = useLogin();

  const pageTitle = params?.message || 'Welcome back!';

  const {isValid, isValidating, values, setFieldValue, submitForm} = useFormik({
    initialValues: {...DEFAULT_FORM, email: params?.email ?? ''},
    initialErrors: DEFAULT_FORM,
    validate: values => {
      const errors = {} as any;
      if (!values.email) {
        errors['email'] = 'Email is required';
      }
      if (!values.password) {
        errors['password'] = 'Password is required';
      }
      return errors;
    },
    onSubmit: async formValues => {
      const {email, password} = formValues;
      login(email, password);
    },
  });

  const setFormValue = (key: string) => (value: string) => {
    setFieldValue(key, value);
  };

  return (
    <DismissKeyboardView>
      <Box flex={1} padding={'l'} justifyContent={'center'}>
        {(authState.errorMessage && (
          <Box
            //@ts-ignore
            borderRadius={'standard'}
            backgroundColor={'error'}
            padding={'m'}>
            <Text color={'light'} variant={'headline'}>
              {authState.errorMessage}
            </Text>
          </Box>
        )) ||
          null}
        <Text variant={'body'} paddingBottom={'m'}>
          {pageTitle}
        </Text>
        <Box justifyContent={'space-between'}>
          <TextInput
            value={values?.email}
            textContentType={'username'}
            onChangeText={setFormValue('email')}
            keyboardType={'email-address'}
            autoCorrect={false}
            autoCapitalize={'none'}
            label={'Email'}
          />
          <Box paddingTop={'s'} />
          <TextInput
            textContentType={'password'}
            onChangeText={setFormValue('password')}
            secureTextEntry
            label={'Password'}
          />
          <Box paddingTop={'s'} />
          <Button
            loading={authState.inFlight}
            disabled={!isValid || isValidating}
            onPress={submitForm}
            title={'Login'}
          />
        </Box>
        <SocialLogins authState={authState} />
        <Box paddingVertical={'m'}>
          <Link to={'/ForgotPassword'}>
            <Text variant={'button'} color={'link'}>
              Forgot Password?
            </Text>
          </Link>
        </Box>
      </Box>
    </DismissKeyboardView>
  );
};
