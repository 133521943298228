import {useShowExplanation} from './useShowExplanation';
import {Icon} from '../../components/Icon';
import React from 'react';
import {TextInputProps} from 'react-native';
import {useStyle} from '../theme';
import {Explanations} from './types';

const DEFAULT_SIZE = 18;

interface ExplanationIconProps {
  size?: number;
  which: Explanations;
  name?: string;
}

export const ExplanationIcon = ({
  size = DEFAULT_SIZE,
  which,
  style,
  ...props
}: ExplanationIconProps & TextInputProps) => {
  const showExplanation = useShowExplanation(which);
  // const stylesWithTheme = useStyle(({edge: {xsmall}}) => ({
  //   icon: {
  //     paddingHorizontal: xsmall,
  //   },
  // }));
  return (
    <Icon
      size={size}
      icon={'help-circle'}
      color={'grey2'}
      onPress={showExplanation}
      {...props}
    />
  );
};
