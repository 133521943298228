import 'intl';
import 'intl/locale-data/jsonp/en';

import React from 'react';
import {Text} from './Text';

type HQTextProps = React.ComponentProps<typeof Text>;

export interface TextNumericProps extends HQTextProps {
  type: 'percent' | 'decimal' | 'currency';
  value: number;
}

const configs = {
  percent: {
    style: 'percent',
    minimumFractionDigits: 0,
  },
  decimal: {
    style: 'decimal',
  },
  currency: {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
};

const formatNumber = (value: any, config: Intl.NumberFormatOptions) => {
  // Create our number formatter.
  const currencyFormat = new Intl.NumberFormat('en-US', config);
  return currencyFormat.format(value);
};

export const TextNumeric = ({
  type = 'decimal',
  value,
  ...props
}: TextNumericProps) => {
  return <Text {...props}>{formatNumber(value, configs[type])}</Text>;
};
