import {takeLatest, put, delay, select} from 'redux-saga/effects';
import {IndividualPurchaseState} from '@houseque/types';
import {sourceUpdated} from '../actions';
import {HouseQueAppState} from '../../../houseque';
import {computeFinanceState} from '../calculations/calculations';
import {
  computedFinancingUpdate,
  changeFinancing,
  updateFinancing,
  setDeal,
  removeLoan,
  changePurchasePrice,
  changeRehab,
  resetAnalysis,
} from '../slice';

interface Action<T> {
  type: string;
  payload: T;
}
/**
 * Aggregates all upstream actions into a single source.  Here we generate the computed values to be used elsewhere in the application.
 * Currently calculating total loan amount, closing costs, payment, out of pocket, and amortization
 * @param action
 */
function* onSourceUpdated(action: Action<undefined>) {
  const {
    purchaseState,
    rehabState,
  }: {
    purchaseState: IndividualPurchaseState;
    rehabState: RehabStateInstance;
  } = yield select((state: HouseQueAppState) => {
    const {rehab, financing} = state.deals.selectedDeal;
    const purchaseState =
      financing.scenarios[financing.currentScenarioIndex]?.value;
    const rehabState = rehab.scenarios[rehab.currentScenarioIndex]?.value;
    return {
      purchaseState,
      rehabState,
    };
  });

  //Base state, without loans factored in
  const state = computeFinanceState(purchaseState, rehabState);
  yield put(computedFinancingUpdate(state));
}

function* triggerCalculations() {
  yield put(sourceUpdated());
}

function* throttleChange() {
  //Blew up tests
  if (process.env.NODE_ENV !== 'test') {
    yield delay(300);
  }
  yield triggerCalculations();
}

export default function* () {
  yield takeLatest(
    [
      resetAnalysis.type,
      changeFinancing.type,
      removeLoan.type,
      setDeal.type,
      //Handle scenario changes
      'CHANGE_CURRENT_SCENARIO_FINANCING',
      'CHANGE_CURRENT_SCENARIO_REHAB',
    ],
    triggerCalculations,
  );

  // These actions are triggered rapidly on keyboard entry, so debounce helps reduce calculations.
  yield takeLatest(
    [updateFinancing.type, changePurchasePrice.type, changeRehab.type],
    throttleChange,
  );
  yield takeLatest(sourceUpdated.type, onSourceUpdated);
}
