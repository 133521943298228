import {firebase} from '@firebase/app';

export type SupportedLogins = 'google' | 'facebook';

export type FetchSocialCredentials = () => Promise<void>;

const cancelationCode = /auth\/popup-closed-by-user/;

export const socialLogins: {
  [index in SupportedLogins]: FetchSocialCredentials;
} = {
  google: async () => {
    try {
      const googleAuth = new firebase.auth.GoogleAuthProvider();
      await firebase.auth().signInWithPopup(googleAuth);
    } catch (err) {
      if (err?.code.match(cancelationCode)) {
        return;
      }
      throw err;
    }
  },
  facebook: async () => {
    try {
      const facebook = new firebase.auth.FacebookAuthProvider();
      facebook.addScope('public_email');
      facebook.addScope('email');
      await firebase.auth().signInWithPopup(facebook);
    } catch (err) {
      if (err?.code.match(cancelationCode)) {
        return;
      }
      throw err;
    }
  },
};
