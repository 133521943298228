import React, {useCallback, useEffect} from 'react';
import {Text, TextInput} from '../../../../components';
import {useFormik} from 'formik';
import {usePredictions} from '../../../../core/deals/selectors/usePredictions';
import {View} from 'react-native';
import {Prediction} from '@houseque/types';
import {useUpdatePredictions} from '../../../../core/deals/selectors/useUpdatePredictions';

//vacancy variance - Add later
export const PredictionsSection = () => {
  const initialValues = usePredictions();
  const updatePredictions = useUpdatePredictions();
  const {values, setFieldValue} = useFormik({
    onSubmit: () => {
      //Not needed
    },
    initialValues: initialValues,
  });
  const handleChange = useCallback(
    (fieldName: keyof Prediction) => {
      return (value: any) => setFieldValue(fieldName, value);
    },
    [setFieldValue],
  );

  useEffect(() => {
    updatePredictions(values);
  }, [values, updatePredictions]);

  return (
    <>
      <Text variant={'largeTitle'}>Projected Rates</Text>
      <View>
        <TextInput
          value={values.periodicPropertyAppraisal as number}
          label={'Property Value'}
          keyboardType={'decimal-pad'}
          onChangeText={handleChange('periodicPropertyAppraisal')}
          type={'percent'}
        />
        <TextInput
          value={values.periodicRentVariance as number}
          label={'Rent'}
          keyboardType={'decimal-pad'}
          onChangeText={handleChange('periodicRentVariance')}
          type={'percent'}
        />
        <TextInput
          keyboardType={'decimal-pad'}
          value={values.eventualSellingCosts!}
          label={'Selling Costs'}
          onChangeText={handleChange('eventualSellingCosts')}
          type={'percent'}
        />
        <TextInput
          keyboardType={'decimal-pad'}
          value={values.periodicExpenseAppreciation as number}
          label={'Expenses'}
          onChangeText={handleChange('periodicExpenseAppreciation')}
          type={'percent'}
        />
      </View>
    </>
  );
};
