export type FieldType = 'percent' | 'currency';
export type Fields = [string, string | number, FieldType];
export type FieldDef = {
  label: string;
  value: string | number;
  type?: FieldType;
  classes?: string;
};

const currencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'usd',
});
const percentFormatter = Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

export const format = (value: any, type: FieldType) => {
  let formatted = undefined;
  switch (type) {
    case 'currency':
      formatted = currencyFormatter.format(value);
      break;
    case 'percent':
      formatted = percentFormatter.format(value);
      break;
    default:
      return value;
  }
  if (formatted?.match(/NaN/)) {
    return '-';
  }
  return formatted;
};

export const Field = ([key, value, type]: Fields) => {
  return `<div class="row space-between" style="padding: 10 5 5 5">
                  <p>${key}</p>
                  <p>${format(value, type)}</p>
              </div>`;
};

export const FieldNew = ({label, value, type, classes = ''}: FieldDef) => {
  return `<div class="row space-between ${classes}" style="padding: 10 5 5 5">
                  <p>${label}</p>
                  <p>${format(value, type)}</p>
              </div>`;
};

export const Section = (fields: Fields[], classes: string[] = []) => {
  return ` <div class="${classes.join(' ')}">
      ${fields.map(Field).join('<div class="divider"></div>')}
  </div>`;
};
export const SectionNew = (fields: FieldDef[], classes: string[] = []) => {
  return ` <div class="${classes.join(' ')}">
      ${fields.map(FieldNew).join('<div class="divider"></div>')}
  </div>`;
};
