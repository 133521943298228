import {useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {changeDealProperties} from '../slice';

export const useUpdateDealProperties = () => {
  const dispatch = useDispatch();
  return useCallback(
    dealProperties => {
      dispatch(changeDealProperties(dealProperties));
    },
    [dispatch],
  );
};
