import {useComputedFinancing} from '../useComputedFinancing';

export const useLoanPayment = (calculateOnRefi = false) => {
  const {loans} = useComputedFinancing();
  if (loans?.length) {
    //Initial loan, naming is poor here - first loan can be a refinance in reality
    let index = 0;
    if (calculateOnRefi) {
      index = 1;
    }
    return loans[index]?.payment ?? 0;
  }
  return 0;
};
